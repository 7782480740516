<template>
  <div>
    <form @submit.prevent="save">
      <ul class="flex gap-4 border-b border-gray-200 pb-2 items-center">
        <li class="w-[5%]">
          <input type="checkbox" @change="(val) => selectAll(val)" class="w-4 h-4 cursor-pointer" />
        </li>
        <li class="w-[95%]">
          {{ $t("project.projectUser.components.interview.components.generateQuestion.components.tableQuestion.questions") }}
        </li>
      </ul>

      <ul>
        <li v-for="(item, index) in mutableList" :key="item.id"
          class="flex py-3 border-b border-gray-100 h-full items-center gap-4">
          <div class="w-[5%]">
            <input type="checkbox" v-model="checkedList" :name="index" :id="index" :value="index"
              class="w-4 h-4 cursor-pointer" />
          </div>
          <div class="w-[95%] text-gray-700 text-sm">
            {{ item.question }}
          </div>
        </li>
      </ul>
      <p class="text-xs pt-4 text-rose-600">
        {{ $t("project.projectUser.components.interview.components.generateQuestion.components.tableQuestion.selectQuestions") }}
      </p>

      <div class="w-full pt-5">
        <buttonItem :load="load" text="Kaydet" buttonClass="w-full" />
      </div>
    </form>
  </div>
</template>
<script>
import buttonItem from "@/components/button.vue";
import { projectInterview } from "@/networking/urlmanager";

export default {
  name: "table-generate-question",
  props: ["List", "Detail"],
  components: {
    buttonItem,
  },
  data() {
    return {
      checkedList: [],
      load: false,
      mutableList: this.List.map((r, idx) => {
        return {
          ...r,
          id: idx,
        };
      })
    };
  },
  methods: {
    chanceList(item) {
      const findIndex = this.checkedList.findIndex(
        (r) => r.question == item.question
      );

      if (findIndex > -1) {
        this.checkedList.splice(findIndex, 1);
      } else {
        this.checkedList.push(item);
      }
    },
    selectAll(val) {
      if (val.target.checked) {
        for (let index = 0; index < this.mutableList.length; index++) {
          if (!this.checkedList.includes(index)) {
            this.checkedList.push(index);
          }
        }
      } else {
        this.checkedList = [];
      }
    },
    save() {
      this.load = true;

      let data = this.checkedList.map((r, index) => {
        let item = this.mutableList[r];
        return {
          ...item,
          orderId: (this.Detail.length || 0) + index + 1,
        };
      });

      this.axios.post(projectInterview.addBulkQuestion, {
          projectId: this.$route.query.id,
          list: JSON.stringify(data),
        })
        .then((res) => {
          this.successMessage(res.data.message);
          this.load = false;
          this.$emit("addQuestion", true);
        })
        .catch((err) => {
          this.load = false;
          this.authController(err);
        });
    },
  },
};
</script>
