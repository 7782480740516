<template>
  <div class="w-full">
    <div class="w-full">
      <customInput
        v-model="selectedItem"
        type="range"
        :min="0"
        :max="30"
        :required="true"
        :title="$t('project.components.experienceRange.title')"
      />

      <div class="w-full flex justify-between text-sm pt-3">
        <span class="pl-1">{{
          $t("project.components.experienceRange.lessThanOne")
        }}</span>

        <span class="flex gap-4">
          <input
            v-model="selectedItem[0]"
            type="number"
            class="border-t-0 w-11 border-b border-gray-200 px-1 text-center"
            style="-webkit-appearance: none; margin: 0"
            :min="0"
            :max="30"
          />
          -
          <input
            v-model="selectedItem[1]"
            type="number"
            class="border-t-0 w-11 border-b border-gray-200 px-1 text-center"
            style="-webkit-appearance: none; margin: 0"
            :min="0"
            :max="30"
          />
        </span>
        <span>{{
          $t("project.components.experienceRange.moreThanThirty")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import customInput from "@/components/customInput.vue";

export default {
  name: "checkbox-special",
  props: ["title", "value", "required"],
  components: {
    customInput,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedItem: this.value || [0, 30],
      maxAllowedValue: 30,
    };
  },

  watch: {
    selectedItem(val) {
      this.$emit("change", val);
    },
    value(val) {
      this.selectedItem = this.listFormatController(val);
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
