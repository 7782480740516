<template>
  <modal
    name="select-date"
    :height="'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
    @closed="hide()"
  >
    <!-- Modal başlık -->
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{
          $t(
            "project.projectUser.components.shortList.components.selectDateModal.modalHeaderTitle"
          )
        }}
      </h1>
      <button @click.stop="hide">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="px-10 py-5">
      <div class="p-5 w-full relative flex items-center justify-center">
        <h2 class="text-center text-blue-800 font-bold">
          {{
            $t(
              "project.projectUser.components.shortList.components.selectDateModal.modalBodyTitle"
            )
          }}
        </h2>
        <div
          class="absolute right-0 top-1/2 transform -translate-y-1/2 group flex justify-center"
          v-if="candidateSuggestions.length > 0"
          @click="goToFirstSuggestedDate"
        >
          <i class="fa-solid fa-circle-exclamation text-sm text-[#182451]"></i>
          <tooltip
            :title="formatCandidateSuggestions()"
            tipClass="z-50 !top-6 !w-[130px]"
            :top="true"
          />
        </div>
      </div>

      <div class="mx-auto w-full">
        <div v-if="!isLoading">
          <div class="flex justify-between space-x-3 mb-5">
            <!-- Ay seçimi -->
            <button type="button" @click="goToNextOrPreviousMonth(false)">
              <i class="fas fa-chevron-left"></i>
            </button>
            <div class="flex space-x-2 items-center">
              <select v-model="selectedMonth">
                <option
                  v-for="(month, index) in months"
                  :key="index"
                  :value="index"
                >
                  {{ month }}
                </option>
              </select>

              <select v-model="selectedYear">
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <!-- Yıl seçimi -->

            <button type="button" @click="goToNextOrPreviousMonth(true)">
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
          <div class="">
            <!-- Haftanın günleri -->
            <div class="grid grid-cols-7 text-center">
              <div
                class="py-2 font-bold"
                v-for="day in $t(
                  'project.projectUser.components.shortList.components.selectDateModal.daysInWeek'
                )"
                :key="day"
              >
                {{ day }}
              </div>
            </div>

            <!-- Ayın günleri -->
            <div class="grid grid-cols-2 sm:grid-cols-7 gap-2 mt-2">
              <button
                class="py-2 rounded-lg shadow-md"
                v-for="(day, index) in daysInMonth"
                :key="index"
                type="button"
                :disabled="isDisabled(day)"
                @click="selectDay(day)"
                :class="{
                  'hover:bg-blue-500 hover:text-white text-blue-800 font-semibold':
                    !isDisabled(day) && day.isSelectedMonthDay,
                  'bg-blue-500 text-white': isSelected(day),
                  'opacity-30  ': isDisabled(day) || !day.isSelectedMonthDay,
                  'text-red-600': !day.isSelectedMonthDay,
                  'ring-2 ring-blue-800/40 bg-white text-blue-800 hover:!text-white':
                    isSuggested(day),
                  'ring-2 ring-blue-800/40 !bg-blue-500  !text-white':
                    isSuggested(day) && isSelected(day),
                }"
              >
                {{ day.day }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-else-if="isLoading"
          class="top-0 left-0 w-full min-h-[300px] flex items-center justify-center bg-opacity-50 my-5"
        >
          <i
            class="fas fa-spinner fa-spin text-blue-500 text-[60px] sm:text-[65px] animate-spin"
          ></i>
        </div>

        <div class="w-full border border-gray-400 rounded my-5">
          <ul>
            <li v-if="selectedDates.length === 0" class="text-center p-1">
              {{
                $t(
                  "project.projectUser.components.shortList.components.selectDateModal.datePickerTitle"
                )
              }}
            </li>
            <li v-else class="grid grid-cols-3 p-1 border-b border-b-gray-400">
              <strong>
                {{
                  $t(
                    "project.projectUser.components.shortList.components.selectDateModal.date"
                  )
                }}</strong
              >
              <strong class="col-span-2">
                {{
                  $t(
                    "project.projectUser.components.shortList.components.selectDateModal.time"
                  )
                }}</strong
              >
            </li>
            <li
              v-for="(item, index) in selectedDates"
              :key="index"
              class="grid grid-cols-3 p-1 border-b border-gray-400 items-center"
            >
              <span>{{ formatDate(item.date) }}</span>
              <div class="flex overflow-x-auto scroll-design col-span-2">
                <button
                  v-for="(time, timeIndex) in getTimesForSelectedDate(
                    item.date
                  )"
                  :key="timeIndex"
                  class="text-black border px-1 py-1 m-1 rounded-lg shadow-lg whitespace-nowrap"
                  @click="selectTime(item.date, time)"
                  :class="{
                    '!bg-blue-500 text-white': item.times.includes(time),
                    'hover:bg-blue-500/90 hover:text-white':
                      !item.times.includes(time),
                  }"
                >
                  {{ time }}
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div class="flex justify-center my-5 w-full">
          <asyncButton
            text="Gönder"
            class="w-full"
            @click="saveDate"
            type="submit"
            :load="buttonLoading"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import moment from "moment";
import asyncButton from "@/components/button.vue";
import tooltip from "@/components/tooltip.vue";
import { technicalInterviewMicrosoft, technicalInterviewGoogle, technicalInterviewGeneral } from "@/networking/urlmanager";


export default {
  name: "select-date-modal",
  components: { asyncButton, tooltip },
  props: ["detail"],
  data() {
    return {
      selectedMonth: moment().month(),
      selectedYear: moment().year(),
      selectedDates: [],
      availableDates: [],
      months: moment.months(),
      years: Array.from({ length: 10 }, (_, i) => moment().year() + i),
      isLoading: false,
      buttonLoading: false,
      timeSlots: this.generateTimeSlots("09:00", "18:00"),
      candidateSuggestions: [],
      isModalVisible: false,
      defaultService: this.$store.state.integration.defaultService,
    };
  },
  computed: {
    computedDisabledDates() {
      const startOfMonthDate = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      }).startOf("month");
      const endOfMonthDate = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      }).endOf("month");

      const days = [];
      for (
        let m = moment(startOfMonthDate);
        m.isBefore(endOfMonthDate);
        m.add(1, "days")
      ) {
        days.push(m.toDate());
      }
      return days
        .filter((date) =>
          this.availableDates.every(
            (interval) =>
              !moment(date).isBetween(
                moment(interval.start),
                moment(interval.end),
                null,
                "[]"
              )
          )
        )
        .map((date) => ({
          start: date,
          end: date,
        }));
    },
    daysInMonth() {
      const daysInThisMonth = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      }).daysInMonth();
      const firstDayOfMonth = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: 1,
      }).day();
      const daysInPreviousMonth = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      })
        .subtract(1, "month")
        .daysInMonth();
      const days = [];
      for (let i = 0; i < firstDayOfMonth - 1; i++) {
        days.push({
          day: daysInPreviousMonth - i,
          isSelectedMonthDay: false,
          id: `${this.selectedYear}-${this.selectedMonth - 1}-${
            daysInPreviousMonth - i
          }`,
        });
      }
      days.reverse();
      for (let i = 0; i < daysInThisMonth; i++) {
        days.push({
          day: i + 1,
          isSelectedMonthDay: true,
          id: `${this.selectedYear}-${this.selectedMonth}-${i + 1}`,
        });
      }
      return days;
    },
  },
  methods: {
    async show() {
      this.$modal.show("select-date");
      document.addEventListener("visibilitychange", this.handleVisibilityChange);
      // this.$store.commit("setIntegrationGoogle", {});
      await this.authorizeMixin(() => this.fetchCalendarDates())
      this.isModalVisible = true;
    },
    hide() {
      this.$modal.hide("select-date");
      this.selectedDates = [];
      this.candidateSuggestions = [];
      this.isModalVisible = false;
      document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    },
    formatCandidateSuggestions() {
      if (this.candidateSuggestions.length === 0) {
        return "No candidate suggestions";
      }

      return (
        '<p class="mb-2">Adayın tarihleri:</p>' +
        this.candidateSuggestions
          .map((suggestion) => {
            const date = moment(suggestion.date).format("LL");
            const times = suggestion.times.join(", ");
            return `<p class="mb-2">${date}: ${times}</p>`;
          })
          .join("")
      );
    },
    formatDate(date) {
      return moment(date).format("LL");
    },
    generateTimeSlots(startTime, endTime) {
      const start = moment(startTime, "HH:mm");
      const end = moment(endTime, "HH:mm");
      const timeSlots = [];

      while (start <= end) {
        const slotStart = start.format("HH:mm");
        start.add(1, "hour");
        const slotEnd = start.format("HH:mm");
        timeSlots.push(`${slotStart} / ${slotEnd}`);
      }

      return timeSlots;
    },
    selectTime(date, time) {
      let selectedDate = this.selectedDates.find((item) => item.date === date);

      if (!selectedDate) {
        if (this.selectedDates.length < 3) {
          selectedDate = { date: date, times: [] };
          this.selectedDates.push(selectedDate);
        } else {
          this.warningMesage("En fazla 3 tarih seçebilirsiniz.");
          return;
        }
      }

      const index = selectedDate.times.findIndex(
        (selectedTime) => selectedTime === time
      );

      if (index > -1) {
        selectedDate.times.splice(index, 1);
      } else {
        const totalSelectedTimes = this.selectedDates.reduce(
          (total, date) => total + date.times.length,
          0
        );

        if (totalSelectedTimes < 3) {
          selectedDate.times.push(time);
        } else {
          this.warningMesage("Toplamda en fazla 3 saat seçebilirsiniz.");
          return;
        }
      }

      const totalSelectedTimes = this.selectedDates.reduce(
        (total, date) => total + date.times.length,
        0
      );
      if (totalSelectedTimes === 3) {
        this.selectedDates = this.selectedDates.filter(
          (date) => date.times.length > 0
        );
      }
    },
    formatSelectedDates() {
      return this.selectedDates
        .map((selectedDate) => {
          const date = moment.utc(selectedDate.date);
          const formattedTimes = selectedDate.times.map((time) => {
            const [hours, minutes] = time.split(" / ")[0].split(":");
            const newDate = date.clone();
            newDate.hours(parseInt(hours));
            newDate.minutes(parseInt(minutes));
            return newDate.format("YYYY-MM-DDTHH:mm:ss[Z]");
          });
          return formattedTimes;
        })
        .flat();
    },
    saveDate() {
      this.buttonLoading = true;

      const formattedDates = this.formatSelectedDates();

      if (formattedDates.length === 0) {
        this.buttonLoading = false;
        this.warningMesage(
          this.$t(
            "project.projectUser.components.shortList.components.selectDateModal.saveDateWarningMessage"
          )
        );
        return;
      }

      const requestBody = {
        selectedDate: formattedDates,
        applicantMail: this.detail.contactEmail,
        projectId: this.detail.projectId,
        projectName: this.$route.query.projectName,
        cvId: this.detail.cvId,
        fullname: this.detail.fullname,
        meetCount: this.detail.meetCount,
      };

      this.axios.post(technicalInterviewGeneral.selectedDate, requestBody)
        .then(() => {
          this.buttonLoading = false;
          this.$emit("refresh", true);
          this.hide();
        })
        .catch((error) => {
          console.error(error);
          this.buttonLoading = false;
          this.authController(error);
        });
    },

    isDisabled(day) {
      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      });
      const formattedDate = date.format("YYYY-MM-DD");

      const isAvailable = this.availableDates.some(
        (availableDate) => availableDate.date === formattedDate
      );

      const isSelected = this.selectedDates.some(
        (selectedDate) => selectedDate.date === formattedDate
      );

      const isInSelectedMonth = day.isSelectedMonthDay;

      const totalSelectedTimes = this.selectedDates.reduce(
        (total, date) => total + date.times.length,
        0
      );

      return (
        !isAvailable ||
        !isInSelectedMonth ||
        (totalSelectedTimes >= 3 && !isSelected)
      );
    },
    isSuggested(day) {
      if (!day.isSelectedMonthDay) {
        return false;
      }

      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      });
      const formattedDate = date.format("YYYY-MM-DD");
      return this.candidateSuggestions.some(
        (suggestedDate) =>
          moment(suggestedDate.date).format("YYYY-MM-DD") === formattedDate
      );
    },

    async fetchCalendarDates() {
      const { microsoftToken } = this.checkOutlookCredentialsMixin();
      const { googleToken, refresh_token } = this.checkGoogleCredentialsMixin();

      this.isLoading = true;
      const intervalId = setInterval(() => {
        if (this.detail.cvId) {
          clearInterval(intervalId);

          const fetchQuery = {
            1:
              technicalInterviewMicrosoft.getCalendarAll +
              "?token=" +
              microsoftToken,
            2:
              technicalInterviewGoogle.getCalendarAll +
              "?access_token=" +
              googleToken +
              "&refresh_token=" +
              refresh_token,
          };

          this.axios
            .get(fetchQuery[this.defaultService], {
              params: {
                cvId: this.detail.cvId,
                meetCount: this.detail.meetCount,
              },
            })
            .then((res) => {
              this.availableDates = [];

              res.data.data.dates.forEach((timeSlot) => {
                const dateTime = moment.utc(
                  timeSlot.dates,
                  "YYYY-MM-DDTHH:mm:ssZ"
                );

                const date = dateTime.format("YYYY-MM-DD");
                const times = [dateTime.format("HH:mm")];

                this.candidateSuggestions.push({ date, times });
                this.availableDates.push({ date, times });
              });

              res.data.data.timeSlots.forEach((timeSlot) => {
                const dateTime = moment(timeSlot);

                const date = dateTime.format("YYYY-MM-DD");
                const times = [dateTime.format("HH:mm")];

                this.availableDates.push({ date, times });
              });
            })
            .catch((err) => {
              console.log(err);
              this.warningMesage(
                this.$t(
                  "project.projectUser.components.shortList.components.selectDateModal.datesNotRecieved"
                ),
                3000
              );
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }, 1000);
    },
    selectDay(day) {
      const date = moment.utc({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      });

      if (!this.isDisabled(day)) {
        const formattedDate = date.format("YYYY-MM-DD");
        const index = this.selectedDates.findIndex(
          (selectedDate) =>
            moment(selectedDate.date).format("YYYY-MM-DD") === formattedDate
        );
        if (index > -1) {
          this.selectedDates.splice(index, 1);
        } else {
          const totalSelectedTimes = this.selectedDates.reduce(
            (total, date) => total + date.times.length,
            0
          );

          if (totalSelectedTimes < 3) {
            const availableDate = this.availableDates.find(
              (availableDate) => availableDate.date === formattedDate
            );
            if (availableDate) {
              this.selectedDates.push({
                date: availableDate.date,
                times: [],
              });
            }
          } else {
            this.warningMesage(
              this.$t(
                "project.projectUser.components.shortList.components.selectDateModal.selectDayWarningMessage"
              ),
              2000
            );
            return;
          }
        }
      }
    },
    goToAvailableMonth(afterCurrent = false) {
      if (this.availableDates.length > 0) {
        if (afterCurrent) {
          const currentDate = new Date(
            this.selectedYear,
            this.selectedMonth + 1,
            1
          );
          for (let i = 0; i < this.availableDates.length; i++) {
            const availableDate = moment(this.availableDates[i].date);
            if (availableDate >= currentDate) {
              this.selectedMonth = availableDate.getMonth();
              this.selectedYear = availableDate.getFullYear();
              break;
            }
          }
        } else {
          const firstAvailableDate = moment(this.availableDates[0].date);
          this.selectedMonth = firstAvailableDate.month();
          this.selectedYear = firstAvailableDate.year();
        }
      }
    },
    goToFirstSuggestedDate() {
      if (this.candidateSuggestions.length > 0) {
        const firstSuggestedDate = moment(this.candidateSuggestions[0].date);
        this.selectedMonth = firstSuggestedDate.month();
        this.selectedYear = firstSuggestedDate.year();
      }
    },
    goToNextOrPreviousMonth(next = false) {
      const currentDate = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      });
      let newDate;

      if (next) {
        newDate = moment(currentDate).add(1, "month");
      } else {
        newDate = moment(currentDate).subtract(1, "month");
      }

      this.selectedMonth = newDate.month();
      this.selectedYear = newDate.year();
    },
    getTimesForSelectedDate(selectedDate) {
      const times = this.availableDates
        .filter((dateObj) => dateObj.date === selectedDate)
        .map((dateObj) => dateObj.times)
        .flat();

      if (times.length === 0) {
        return [];
      }

      const earliestTime = Math.min(
        ...times.map((time) => moment(time, "HH:mm").valueOf())
      );
      let latestTime = Math.max(
        ...times.map((time) => moment(time, "HH:mm").valueOf())
      );

      if (earliestTime === latestTime) {
        latestTime = moment(earliestTime).add(1, "hours").valueOf();
      }

      const timeSlots = this.generateTimeSlots(
        moment(earliestTime).format("HH:mm"),
        moment(latestTime).format("HH:mm")
      );

      let availableTimes = times
        .map((time) => {
          const nextHour = moment(time, "HH:mm")
            .add(1, "hours")
            .format("HH:mm");
          return `${time} / ${nextHour}`;
        })
        .filter((time) => timeSlots.includes(time));

      return availableTimes.length > 0 ? availableTimes : [];
    },
    isSelected(day) {
      if (!day.isSelectedMonthDay) {
        return false;
      }

      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      });
      const formattedDate = date.format("YYYY-MM-DD");
      return (
        this.selectedDates.some(
          (selectedDate) => selectedDate.date === formattedDate
        ) && !this.isDisabled(day)
      );
    },
    handleVisibilityChange() {
      if (document.visibilityState === "visible" && !document.hasFocus() && this.availableDates?.length == 0) {
        this.authorizeMixin()
      }
    },
  },
  mounted() {
    this.goToAvailableMonth();
  },
};
</script>

<style>
.scroll-design::-webkit-scrollbar {
  height: 5px;
}

.scroll-design::-webkit-scrollbar-thumb {
  background: #182451;
  border-radius: 10px;
}

.scroll-design::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-design-y::-webkit-scrollbar {
  width: 6px;
}

.scroll-design-y::-webkit-scrollbar-thumb {
  background: #182451;
  border-radius: 10px;
}

.scroll-design-y::-webkit-scrollbar-track {
  background: transparent;
}
</style>
