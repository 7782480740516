<template>
  <div class="main">
    <div
      class="w-full mt-5 rounded text-center overflow-y-auto flex justify-between border border-gray-300"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        name="file"
        id="fileInput"
        class="hidden"
        @change="onChange"
        ref="fileLogo"
        accept=".pdf,.jpg,.jpeg,.png"
      />

      <div class="grid grid-cols-12 gap-4 h-full flex-grow">
        <label
          for="fileInput"
          class="col-span-7 sm:col-span- md:col-span-6 xl:col-span-8 flex cursor-pointer rounded justify-center text-left px-5 py-5 text-sm items-center h-44 max-md:flex-wrap max-md:px-5 max-md:py-3"
          :class="img ? '' : ' items-center h-full'"
        >
          <div class="w-full">
            <div v-if="isDragging">
              {{ $t("settings.general.components.uploadFile.dropTheFileHere") }}
            </div>
            <div v-else>
              {{
                $t(
                  "settings.general.components.uploadFile.dropTheFileHereTextOne"
                )
              }}<u>{{
                $t("settings.general.components.uploadFile.dropTheFileHereTwo")
              }}</u
              >.
            </div>
          </div>
        </label>
        <div
          class="col-span-5 sm:col-span-4 md:col-span-6 xl:col-span-4 py-3 h-full max-md:w-full flex items-center"
        >
          <div
            class="p-3 mr-2 rounded h-full w-full"
            :class="
              img ? 'border border-gray-300' : 'flex items-center justify-end'
            "
          >
            <img
              v-if="img || files"
              class="w-full mx-auto h-full object-contain"
              :src="generateURL(files) ? generateURL(files) : img"
            />
            <a
              v-else
              class="px-5 text-white bgcol py-3 rounded cursor-pointer"
              @click="$refs.fileLogo.click()"
            >
              <i class="fa-solid fa-upload"></i>
            </a>
          </div>
          <div
            class="mx-2 p-2 flex items-center rounded-full cursor-pointer bg-red-500 text-white hover:bg-red-500/60 hover:text-gray-700"
            v-if="files || img"
            @click.stop="remove"
          >
            <i class="fa-solid fa-trash"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["img"],
  data() {
    return {
      isDragging: false,
      files: null,
    };
  },
  methods: {
    onChange() {
      const selectedFile = this.$refs.fileLogo.files[0];
      if (selectedFile) {
        const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          this.warningMesage(
            $t("settings.general.components.uploadFile.warningMessage")
          );
          return;
        }
        this.files = selectedFile;
        this.$emit("fileSelected", this.files);
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.fileLogo.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove() {
      this.files = null;
      this.$refs.fileLogo.value = "";
      this.$emit("fileSelected", 2);
    },
    generateURL(file) {
      if (file) {
        let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
          URL.revokeObjectURL(fileSrc);
        }, 1000);
        return fileSrc;
      }
      return "";
    },
  },
};
</script>
