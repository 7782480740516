<template>
  <div style="height: calc(100vh - 284px)">
    <button
      class="w-full bgcol h-10 text-white flex items-center justify-center gap-2 text-sm font-normal"
    >
      Message (3)<i class="fa-solid fa-angle-down"></i>
    </button>
    <div class="w-full pt-2 px-2" style="height: calc(100% - 40px)">
      <div class="h-[82%] overflow-auto scroltype px-3 py-3">
        <div v-for="item in List" :key="item.id">
          <div v-if="item.writerType == 1" class="flex justify-start mb-3">
            <img
              src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
              class="object-cover h-8 w-8 rounded-full"
              alt=""
            />
            <div>
              <div
                class="ml-2 py-1 px-4 bg-[#0F236ACF] rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white pr-8"
              >
                <span class="text-xs">{{ item.message }}</span>
              </div>
              <p class="text-[9px] text-left mt-1 pl-2">12:00</p>
            </div>
          </div>
          <div v-if="item.writerType == 2" class="flex justify-end mb-3 w-full">
            <div>
              <div
                class="mr-2 py-1 px-4 bg-[#818ebb87] rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white pl-8"
              >
                <span class="text-xs">{{ item.message }}</span>
              </div>
              <p class="text-[9px] text-right mt-1 pr-2">12:00</p>
            </div>
            <img
              src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
              class="object-cover h-8 w-8 rounded-full"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        class="w-full flex h-[18%] items-center gap-3 border-t border-gray-300 px-6"
      >
        <button>
          <img src="@/assets/meeting/atatchment.svg" alt="" class="w-6" />
        </button>
        <input
          type="text"
          class="w-full outline-none h-full"
          placeholder="Mesaj gönder..."
        />
        <button>
          <img src="@/assets/meeting/send.svg" alt="" class="w-6" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "messageComponent",
  data() {
    return {
      List: [
        {
          writerType: 1,
          message: "What is Lorem Ipsum?",
        },
        {
          writerType: 2,
          message: "What is Lorem Ipsum?",
        },
        {
          writerType: 1,
          message: "What is Lorem Ipsum?",
        },
        {
          writerType: 2,
          message: "What is Lorem Ipsum?",
        },
      ],
    };
  },
};
</script>
