<template>
  <div>
    <div
      @mousedown.stop="isOpen == true ? (isOpen = true) : (isOpen = false)"
      :class="['sidebar w-[400px] max-sm:w-full', isOpen ? 'sidebar-open' : '']"
      class="bg-white p-4 shadow-md !z-[998]"
    >
      <div class="bg-white">
        <div
          class="flex justify-between items-center p-4 border-b border-gray-200"
        >
          <button @click="toggleSidebar()" class="flex items-center">
            <i class="fas fa-times fa-2x text-[#344CA0] text-2xl"></i>
          </button>
          <h2 class="text-xl font-bold text-[#344CA0]">
            {{ $t("cv.components.filterPanel.title") }}
          </h2>
          <button @click="deleteResumeFilter()" class="flex items-center">
            <span class="text-red-500 border-b border-red-500 text-sm mr-2">{{
              $t("cv.components.filterPanel.reset")
            }}</span>
          </button>
        </div>

        <div class="overflow-y-auto scroltype h-[85vh] mt-2 p-2 max-sm:py-5">
          <form @submit.prevent="applyFilters" class="p-2">
            <!-- Filtre alanları -->
            <div v-for="(filter, index) in filters" :key="index" class="mb-4">
              <label
                :for="filter.name"
                class="w-full text-sm font-medium mb-1 space-between flex"
              >
                <span class="block w-6/12 max-sm:px-1">
                  {{ filter.label }}
                </span>

                <p class="text-right w-6/12" v-if="filter.type == 'rßange'">
                  {{ filter.minValue + "-" + filter.maxValue }}
                </p>
              </label>

              <input
                v-if="filter.type == 'text'"
                :type="filter.type"
                :name="filter.name"
                v-model="filter.value"
                class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
                :placeholder="filter.placeholder"
              />

              <positionSelect
                v-else-if="filter.type === 'position'"
                v-model="filter.value"
              />

              <multiselect
                v-else-if="filter.type === 'multiselect'"
                v-model="filter.value"
                :options="filter.options"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('cv.components.filterPanel.placeholder')"
                class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
              ></multiselect>

              <select
                v-else-if="filter.type === 'select'"
                :name="filter.name"
                v-model="filter.value"
                class="w-full border border-[#182451] p-2 rounded text-[#b0b0b0]"
              >
                <option disabled value="" class="">
                  {{ $t("cv.components.filterPanel.select") }}
                </option>
                <option
                  v-for="(option, index) in filter.options"
                  :key="index"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>

              <!-- institution Input -->
              <institutionSelect
                v-else-if="filter.type === 'institution'"
                v-model="filter.value"
              />
              <!-- institution Input -->

              <!-- Skilss Input -->
              <skillsSelect
                v-else-if="filter.type === 'skills'"
                v-model="filter.value"
              />
              <!-- Skilss Input -->

              <!-- department Input -->
              <departmentSelect
                v-else-if="filter.type === 'department'"
                v-model="filter.value"
              />
              <!-- department Input -->

              <!-- city Input -->
              <citySelect
                v-else-if="filter.type === 'city'"
                v-model="filter.value"
              />
              <!-- city Input -->

              <div class="w-full" v-else-if="filter.type == 'range'">
                <div>
                  <VueSlider
                    :value="[filter.minValue, filter.maxValue]"
                    @change="updateExperienceRange($event, index)"
                    :min="filter.startValue"
                    :max="65"
                    :step="1"
                    width="100%"
                    :dot-size="16"
                    :process-style="{ backgroundColor: '#344CA0' }"
                    :rail-style="{ backgroundColor: '#ccc' }"
                    :dot-style="{
                      backgroundColor: '#344CA0',
                      borderColor: '#344CA0',
                    }"
                  />
                </div>
                <span
                  class="text-sm flex items-center gap-2.5 justify-end text-gray-700"
                >
                  {{ filter.label }}
                  {{ $t("cv.components.filterPanel.unspecified") }}
                  <input
                    type="checkbox"
                    :checked="filter.isNull == 2"
                    @change="
                      filter.isNull == 2
                        ? (filter.isNull = 1)
                        : (filter.isNull = 2)
                    "
                    class="w-4 h-4 cursor-pointer"
                  />
                </span>
              </div>

              <satusSelect
                v-if="filter.type == 'status'"
                v-model="filter.value"
              />
            </div>

            <!-- Filtrele butonu -->
            <buttonItem
              :load="load"
              name="Filtrele"
              buttonClass="w-full font-bold hover:bg-opacity-90 mt-3"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import axios from "axios";
import { cv } from "@/networking/urlmanager.js";
import skillsSelect from "./skills-selected.vue";
import positionSelect from "@/components/dev/positionSelected.vue";
import institutionSelect from "@/components/dev/institutionSelect.vue";
import departmentSelect from "@/components/dev/departmentSelect.vue";
import citySelect from "@/components/dev/citySelect.vue";
import satusSelect from "@/components/dev/status.vue";
import buttonItem from "@/components/button.vue";
//import tour from "@/components/tour.vue";

export default {
  components: {
    VueSlider,
    Multiselect,
    departmentSelect,
    institutionSelect,
    skillsSelect,
    positionSelect,
    citySelect,
    satusSelect,
    buttonItem,
  },
  props: ["currentPage", "chanceLoad"],
  data() {
    return {
      isOpen: false,
      load: false,

      filters: [
        {
          name: "jobTitle",
          label: this.$t("cv.components.filterPanel.position"),
          type: "position",
          value: [],
          typeId: "4",
          placeholder: "Title...",
        },
        {
          isNull: 1,
          name: "experience",
          label: this.$t("cv.components.filterPanel.experience"),
          type: "range",
          startValue: 0,
          minValue: 0,
          maxValue: 10,
          typeId: "2",
        },
        // {
        //   name: "city",
        //   label: "Şehir",
        //   type: "city",
        //   value: [],
        //   typeId:"4",
        // },
        {
          name: "educationLevel",
          label: this.$t("cv.components.filterPanel.educationLevel.title"),
          type: "select",
          value: "",
          typeId: "1",
          options: [
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.primarySchool"
              ),
              value: "0",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.highSchool"
              ),
              value: "1",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.associateDegree"
              ),
              value: "2",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.licence"
              ),
              value: "3",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.highDegree"
              ),
              value: "4",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.doctorate"
              ),
              value: "5",
            },
          ],
        },
        {
          isNull: 1,
          name: "age",
          label: this.$t("cv.components.filterPanel.yearsRange"),
          type: "range",
          startValue: 14,
          minValue: 14,
          maxValue: 28,
          typeId: "2",
        },
        {
          name: "gender",
          label: this.$t("cv.components.filterPanel.gender.title"),
          type: "select",
          value: "",
          typeId: "1",
          options: [
            {
              label: this.$t("cv.components.filterPanel.gender.man"),
              value: "1",
            },
            {
              label: this.$t("cv.components.filterPanel.gender.woman"),
              value: "2",
            },
          ],
        },
        {
          name: "militaryStatus",
          label: this.$t("cv.components.filterPanel.militaryStatus.title"),
          type: "select",
          value: "",
          typeId: "1",
          options: [
            {
              label: this.$t("cv.components.filterPanel.militaryStatus.done"),
              value: "1",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.militaryStatus.deferred"
              ),
              value: "2",
            },
            {
              label: this.$t("cv.components.filterPanel.militaryStatus.exempt"),
              value: "3",
            },
          ],
        },
        // {
        //   name: "driverLicance",
        //   label: "Sürücü Belgesi",
        //   type: "select",
        //   value: "",
        //   typeId:"1",
        //   options: [
        //     { label: "A1", value: "A1" },
        //     { label: "A2", value: "A2" },
        //     { label: "B1", value: "B1" },
        //     { label: "B", value: "B" },
        //     { label: "C1", value: "C1" },
        //     { label: "C", value: "C" },
        //     { label: "D1", value: "D1" },
        //     { label: "D", value: "D" },
        //     { label: "E", value: "E" },
        //     { label: "Yok", value: "none" },
        //   ],
        // },

        {
          name: "skills",
          label: this.$t("cv.components.filterPanel.skillsTitle"),
          type: "skills",
          value: [],
          typeId: "4",
          placeholder: "Beceriler (virgülle ayrılmış)",
        },
        {
          name: "institution",
          label: this.$t("cv.components.filterPanel.schoolTitle"),
          type: "institution",
          value: "",
          typeId: "4",
          placeholder: "",
        },
        {
          name: "department",
          label: this.$t("cv.components.filterPanel.departmentTitle"),
          type: "department",
          value: "",
          typeId: "4",
          placeholder: "",
        },
        // {
        //   name: "stateId",
        //   label: this.$t("cv.components.filterPanel.statusTitle"),
        //   type: "status",
        //   value: "",
        //   typeId: "4",
        //   placeholder: "",
        // },
      ],
      // steps: [
      //           {
      //               target: '[data-v-step="0"]',
      //               header: {
      //                   title: 'Filtreleme Menüsü',
      //               },
      //               content: 'Pozisyon, deneyim, eğitim seviyesi, yaş aralığı, cinsiyet, askerlik durumu, beceriler, okulu, bölümü ve durumu gibi bir ok alanı seçerek filtreleme yapabilirsiniz.',
      //               params: {
      //                   placement: 'bottom'
      //               },
      //           },
      //         ]
    };
  },
  methods: {
    updateExperienceRange(value, index) {
      this.filters[index].minValue = value[0];
      this.filters[index].maxValue = value[1];
    },
    toggleSidebar() {
      this.isOpen = !this.isOpen;
    },
    applyFilters() {
      this.load = true;
      this.$emit("load", true);
      const payload = {
        fList: JSON.stringify(this.filters),
        page: this.currentPage,
      };
      axios
        .post(cv.getFilterAll, payload, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.load = false;
          this.$emit("filterAll", res.data.data);
          this.$store.commit("resumeFilter", this.filters);
        })
        .catch((error) => {
          this.load = false;
          console.error(error);
        });
    },
    deleteResumeFilter() {
      this.$store.commit("deleteResumeFilter");
      this.toggleSidebar();
      this.$emit("deleteFilter", true);
      this.filters = [
        {
          name: "jobTitle",
          label: this.$t(
            "cv.components.filterPanel.deleteResumeFilter.position"
          ),
          type: "position",
          value: [],
          typeId: "4",
          placeholder: this.$t(
            "cv.components.filterPanel.deleteResumeFilter.positionPlacholder"
          ),
        },
        {
          isNull: 1,
          name: "experience",
          label: this.$t(
            "cv.components.filterPanel.deleteResumeFilter.experience"
          ),
          type: "range",
          startValue: 0,
          minValue: 0,
          maxValue: 10,
          typeId: "2",
        },
        // {
        //   name: "city",
        //   label: "Şehir",
        //   type: "city",
        //   value: [],
        //   typeId:"4",
        // },
        {
          name: "educationLevel",
          label: this.$t(
            "cv.components.filterPanel.deleteResumeFilter.educationLevel.title"
          ),
          type: "select",
          value: "",
          typeId: "1",
          options: [
            {
              label: this.$t(
                "cv.components.filterPanel.deleteResumeFilter.educationLevel.primarySchool"
              ),
              value: "0",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.deleteResumeFilter.educationLevel.highSchool"
              ),
              value: "1",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.deleteResumeFilter.educationLevel.associateDegree"
              ),
              value: "2",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.deleteResumeFilter.educationLevel.licence"
              ),
              value: "3",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.deleteResumeFilter.educationLevel.highDegree"
              ),
              value: "4",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.deleteResumeFilter.educationLevel.doctorate"
              ),
              value: "5",
            },
          ],
        },
        {
          isNull: 1,
          name: "age",
          label: this.$t(
            "cv.components.filterPanel.deleteResumeFilter.yearsRange"
          ),
          type: "range",
          startValue: 14,
          minValue: 14,
          maxValue: 28,
          typeId: "2",
        },
        {
          name: "gender",
          label: this.$t(
            "cv.components.filterPanel.deleteResumeFilter.gender.title"
          ),
          type: "select",
          value: "",
          typeId: "1",
          options: [
            {
              label: this.$t(
                "cv.components.filterPanel.deleteResumeFilter.gender.man"
              ),
              value: "1",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.deleteResumeFilter.gender.woman"
              ),
              value: "2",
            },
          ],
        },
        {
          name: "militaryStatus",
          label: this.$t(
            "cv.components.filterPanel.deleteResumeFilter.militaryStatus.title"
          ),
          type: "select",
          value: "",
          typeId: "1",
          options: [
            {
              label: this.$t(
                "cv.components.filterPanel.deleteResumeFilter.militaryStatus.done"
              ),
              value: "1",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.deleteResumeFilter.militaryStatus.deferred"
              ),
              value: "2",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.deleteResumeFilter.militaryStatus.exempt"
              ),
              value: "3",
            },
          ],
        },
        // {
        //   name: "driverLicance",
        //   label: "Sürücü Belgesi",
        //   type: "select",
        //   value: "",
        //   typeId:"1",
        //   options: [
        //     { label: "A1", value: "A1" },
        //     { label: "A2", value: "A2" },
        //     { label: "B1", value: "B1" },
        //     { label: "B", value: "B" },
        //     { label: "C1", value: "C1" },
        //     { label: "C", value: "C" },
        //     { label: "D1", value: "D1" },
        //     { label: "D", value: "D" },
        //     { label: "E", value: "E" },
        //     { label: "Yok", value: "none" },
        //   ],
        // },

        {
          name: "skills",
          label: this.$t(
            "cv.components.filterPanel.deleteResumeFilter.skillsTitle"
          ),
          type: "skills",
          value: [],
          typeId: "4",
          placeholder: this.$t(
            "cv.components.filterPanel.deleteResumeFilter.skillsPlacholder"
          ),
        },
        {
          name: "institution",
          label: this.$t(
            "cv.components.filterPanel.deleteResumeFilter.schoolTitle"
          ),
          type: "institution",
          value: "",
          typeId: "4",
          placeholder: "",
        },
        {
          name: "department",
          label: this.$t(
            "cv.components.filterPanel.deleteResumeFilter.departmentTitle"
          ),
          type: "department",
          value: "",
          typeId: "4",
          placeholder: "",
        },
        // {
        //   name: "stateId",
        //   label: this.$t("cv.components.filterPanel.deleteResumeFilter.statusTitle"),
        //   type: "status",
        //   value: "",
        //   typeId: "4",
        //   placeholder: "",
        // },
      ];
    },
  },
  created() {
    if (Array.isArray(this.$store.state.resumeFilter)) {
      if (this.$store.state.resumeFilter.length > 0) {
        setTimeout(async () => {
          this.filters = await this.$store.state.resumeFilter;
          await this.applyFilters();
        }, 50);
      }
    }
  },
};
</script>
<style>
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar-open {
  transform: translateX(0);
}

.scroltype::-webkit-scrollbar {
  width: 6px;
}

.scroltype::-webkit-scrollbar-thumb {
  background: #344ca0;
  border-radius: 10px;
}
</style>
