<template>
  <modal
    name="recommendedModal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '70%'"
    :scrollable="true"
    class="scroltype"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white z-50"
    >
      <h1 class="">{{ $t("project.detail.recommended.title") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div class="mx-3 my-3 relative">
      <div class="tableOverflow scroltypey">
        <div class="tableWidth">
          <div class="tableContainer !border-0 !px-0">
            <div class="tableHead !bg-gray-200 !textcol !static">
              <ul class="tableUl">
                <li class="w-1/12">
                  <input type="checkbox" @change="(val) => selectAll(val)" />
                </li>
                <li class="w-3/12">
                  {{ $t("project.detail.recommended.ressume") }}
                </li>
                <li class="w-3/12">
                  {{ $t("project.detail.recommended.position") }}
                </li>
                <li class="w-5/12 flex justify-end items-center">
                  <div
                    class="w-[300px] flex items-center border border-[#182451] rounded-md h-9 -mr-3 relative"
                  >
                    <input
                      v-model="search"
                      @keypress.enter="getSearch()"
                      type="text"
                      class="h-full w-full outline-none pl-3 placeholder:textcol font-light rounded-md text-black"
                      :placeholder="$t('project.detail.recommended.search')"
                    />
                    <button class="px-3 textcol absolute right-0">
                      <i
                        @click="getSearch()"
                        class="fa-solid fa-magnifying-glass"
                      ></i>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="tableBody scroltype !px-0 !mt-1"
              :class="List.length > 10 ? '!pr-4' : ''"
            >
              <div class="tableBodyContainer max-sm:!h-[350px]">
                <ul
                  v-for="(item, index) in List"
                  :key="index"
                  class="tableBodyUl px-8 cursor-pointer"
                >
                  <li class="w-1/12 py-2.5">
                    <input
                      type="checkbox"
                      v-model="checkedList"
                      :name="index"
                      :id="index"
                      :value="index"
                      class="mr-2"
                    />
                  </li>
                  <li @click="cvDetail(item.id)" class="w-3/12 py-2.5">
                    {{ formatText(item.fullname) }}
                  </li>
                  <li @click="cvDetail(item.id)" class="w-3/12 py-2.5">
                    {{ formatText(item.position) }}
                  </li>
                  <li
                    @click="cvDetail(item.id)"
                    class="w-5/12 py-2.5 text-justify flex justify-end gap-10"
                  >
                    <div class="w-8/12 flex justify-between">
                      <div class="relative group flex justify-center">
                        <p class="">{{ item.addDate | getDate }}</p>
                        <tooltip
                          :title="$t('project.detail.recommended.addDate')"
                          tipClass="z-50 !top-6"
                          :top="true"
                        />
                      </div>
                      <div class="flex gap-5 items-center">
                        <div
                          v-if="item.onlyKey"
                          class="relative group flex justify-center"
                        >
                          <img
                            class="w-[16px]"
                            src="@/assets/project/key.png"
                            alt=""
                          />
                          <tooltip
                            :title="c"
                            tipClass="z-50 w-[150px] !-left-[120px] !top-6 text-center"
                            rotateClass="ml-[100px]"
                            :top="true"
                          />
                        </div>
                        <div
                          class="relative group flex justify-center text-center"
                        >
                          <p
                            :class="{
                              'text-red-500 ':
                                item.matchPercentage >= 0 &&
                                item.matchPercentage <= 60,
                              'text-yellow-500 ':
                                item.matchPercentage >= 61 &&
                                item.matchPercentage <= 75,
                              'text-green-500 ': item.matchPercentage >= 76,
                            }"
                          >
                            %{{
                              item.matchPercentage ? item.matchPercentage : "0"
                            }}
                          </p>
                          <tooltip
                            :title="
                              item.onlyKey
                                ? $t('project.detail.recommended.onlyKeyRate')
                                : $t('project.detail.recommended.ressumeRate')
                            "
                            tipClass="z-50 w-[150px] !top-6"
                            :top="true"
                          />
                        </div>

                        <div
                          @click="cvDetail(item.id)"
                          class="relative group flex justify-center"
                        >
                          <router-link
                            :to="'/cv/profile/' + item.id"
                            class="block h-full"
                            data-v-step="4"
                          >
                            <img
                              class="w-4"
                              src="@/assets/cv/document-icon.svg"
                              alt=""
                            />
                          </router-link>
                          <tooltip
                            :title="
                              $t('project.detail.recommended.resumeAccess')
                            "
                            tipClass="z-50 w-[150px] !-left-[120px] !top-6 text-center"
                            rotateClass="ml-[100px]"
                            :top="true"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                <loadingDocument
                  :isLoading="load"
                  :length="List?.length"
                  container-class=" w-full text-center justify-center items-center flex h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex justify-between items-center border-t border-gray-200 pt-2 max-sm:py-1 max-sm:flex-col px-5 py-3"
    >
      <pagination
        v-model="currentPage"
        :pageCount="pageCount"
        :totalRowCount="totalCount"
        :hiddenTotal="true"
        paginationClass="!py-0 -ml-3 max-sm:ml-0"
      />

      <div class="mt-3 max-sm:w-full flex items-center space-x-2 ml-auto">
        <saveButton
          @click="$router.push('/cv')"
          :text="$t('globalComponents.sideBar.resumes')"
          buttonClass="!inline-block !w-44 max-sm:!block max-sm:!w-full !bg-transparent !text-gray-800 
          !border !border-gray-800 hover:!bg-[#182451]/70 hover:!text-white"
        />
        <saveButton
          @click="projectSave()"
          :load="saveLoad"
          :text="$t('project.detail.recommended.saveButton')"
          buttonClass="!inline-block !w-44 max-sm:!block max-sm:!w-full "
        />
      </div>
    </div>
  </modal>
</template>

<script>
import pagination from "@/components/pagination.vue";
//import tableLoader from "@/components/table-loader.vue";
import loadingDocument from "@/components/loadingDocument.vue";

import moment from "moment";
import "moment/locale/tr";

import saveButton from "@/components/button.vue";
import { projectConnect } from "@/networking/urlmanager";

import tooltip from "@/components/tooltip.vue";

export default {
  name: "log-component",
  props: ["cvId", "projectId", "projectName"],
  components: {
    pagination,
    //tableLoader,
    saveButton,
    tooltip,
    loadingDocument,
  },
  data() {
    return {
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,
      saveLoad: false,
      List: [],
      load: false,
      checkedList: [],
    };
  },
  methods: {
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    cvDetail(val) {
      this.$router.push("/cv/profile/" + val);
    },
    selectAll(val) {
      if (val.target.checked) {
        for (let index = 0; index < this.List.length; index++) {
          this.checkedList.push(index);
        }
      } else {
        this.checkedList = [];
      }
    },
    getAll() {
      this.List = [];
      this.load = true;
      this.axios.get(projectConnect.getResumeRecommendations, {
          params: {
            projectId: this.$route.query.id,
            page: this.currentPage,
            search: this.search,
          },
        })
        .then((res) => {
          const { detail, totalCount, pageCount } = res.data.data;
          this.List = detail;
          this.totalCount = totalCount;
          this.pageCount = pageCount;
          this.load = false;
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    projectSave() {
      if (this.checkedList.length > 0) {
        this.saveLoad = true;
        if (this.checkedList.length > 0) {
          this.axios.post(projectConnect.addResume, {
              projectId: this.$route.query.id,
              list: JSON.stringify(this.checkedList.map((r) => this.List[r])),
              projectName: this.$route.query.projectName,
            })
            .then((res) => {
              this.successMessage(res.data.message);

              this.saveLoad = false;
              this.List = [];
              this.checkedList = [];
              this.$emit("refresh", true);
              this.hide();
            })
            .catch((err) => {
              console.log(err);
              this.saveLoad = false;
            });
        }
      }
    },
    show() {
      this.load = true;
      this.List = [];
      setTimeout(() => {
        this.getAll();
      }, 50);
      this.$modal.show("recommendedModal");
    },
    hide() {
      this.$modal.hide("recommendedModal");
    },
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return this.$t("noDate");
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
};
</script>
