<template>
  <div class="mt-5 -z-[998] h-full">
    <div class="tableOverflow scroltypey h-full">
      <div class="tableWidth h-full">
        <div class="tableContainer h-full">
          <div class="tableHead">
            <ul class="tableUl">
              <li class="w-[75px] text-left">#</li>
              <li class="w-6/12 pr-3">
                {{ $t("project.projectUser.components.interview.index.questions") }}
              </li>
              <li class="w-2/12 px-4 text-left whitespace-nowrap">
                {{ $t("project.projectUser.components.interview.index.questionType") }}
              </li>
              <li class="w-2/12 px-4 text-left whitespace-nowrap">
                {{ $t("project.projectUser.components.interview.index.questionPoint") }}
              </li>
              <li v-if="isActive != 1" class="w-2/12 flex justify-center">
                <!-- <img class="w-4" src="@/assets/img/ffs.svg" alt="" /> -->
                <!-- # -->
              </li>
            </ul>
          </div>
          <div class="tableBody px-4 mb-2 scroltype">
            <div class="w-full textcol text-sm" style="height: calc(45vh - 1px)">
              <draggable v-model="List" @change="changeOrderId(List)" @start="drag = true" @end="drag = false"
                :disabled="isActive == 1">
                <ul v-for="(item, index) in List" :key="index" class="tableBodyUl bg-white cursor-pointer">

                  <li class="w-[75px] py-2.5 flex items-center justify-start">
                    {{ index + 1 }}
                  </li>
                  <li class="w-6/12 py-2.5 pr-3">
                    {{ formatText(item.question) }}
                  </li>
                  <li class="w-2/12 py-2.5 text-left">
                    {{ item.questionState == 1 ? "Soru" : "Karar Sorusu" }}
                  </li>
                  <li class="w-2/12 py-2.5 px-4 text-left underline" @dblclick="() => startEdit(item.id)">
                    <scoreEdit :List="List" :item="item" @updateList="(val) => List = val" />
                  </li>

                  <li class="w-2/12 flex gap-10 justify-end" v-if="isActive != 1">
                    <div class="flex gap-4">
                      <button :disabled="isActive == 1" :class="isActive == 1 && 'text-black text-opacity-30'"
                        @click="edit(item)" class="flex items-center h-full">
                        <div class="relative group flex justify-center">
                          <i data-v-step="2" class="fa-solid fa-pen-to-square text-base"></i>
                          <tooltip v-if="isActive != 1" title="Soruları buradan düzenleyebilirsiniz."
                            tipClass="z-50 !top-6" :top="true" />
                        </div>
                      </button>

                      <button :disabled="isActive == 1" :class="isActive == 1 && 'text-black text-opacity-30'"
                        @click="deleteItem(item)" class="flex items-center h-full">
                        <div class="relative group flex justify-center">
                          <i data-v-step="3" class="fa-solid fa-trash-can text-base"></i>
                          <tooltip v-if="isActive != 1" title="Soruları buradan silebilirsiniz." tipClass="z-50 !top-6"
                            :top="true" />
                        </div>
                      </button>
                    </div>
                    <button>
                      <div class="relative group flex justify-center">
                        <i class="fas fa-bars" data-v-step="4"></i>
                        <tooltip title="Buradan Soru Sıralamasını Değiştirebilirsiniz"
                          tipClass="z-50 w-[160px] !-left-[140px] !top-6 " rotateClass="ml-[140px]" :top="true" />
                      </div>
                    </button>
                  </li>
                </ul>
              </draggable>
              <tableLoader :load="load" :length="List.length" colspan="7" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="false">
      <tour :steps="steps" />
    </div>
  </div>
</template>
<script>
import tableLoader from "@/components/table-loader.vue";
import scoreEdit from "./scoreEdit.vue";
import tooltip from "@/components/tooltip.vue";
import { projectInterview } from "@/networking/urlmanager";
import draggable from "vuedraggable";
import moment from "moment";
import "moment/locale/tr";
import tour from "@/components/tour.vue";

export default {
  name: "tableAsistan",
  components: {
    tableLoader,
    tour,
    tooltip,
    draggable,
    scoreEdit,
  },
  data() {
    return {
      load: false,
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,
      evaluationCriteria: "",
      isActive: 2,
      List: [],
      steps: [
        {
          target: '[data-v-step="2"]',
          header: {
            title: "Düzenle",
          },
          content:
            "Eklemiş olduğumuz soruları buraya tıklayarak düzenleyebiliriz.",
          params: {
            placement: "bottom",
          },
        },
        {
          target: '[data-v-step="3"]',
          header: {
            title: "Sil",
          },
          content: "Eklemiş olduğumuz soruları buraya tıklayarak silebiliriz.",
          params: {
            placement: "bottom",
          },
        },
      ],
    };
  },
  methods: {
    changeOrderId(list) {
      if (this.isActive != 1) {
        let data = list.map((item, index) => {
          return {
            id: item.id,
            orderId: index + 1,
          };
        });
        this.axios.post(projectInterview.changeOrder, { list: data })
          .then(() => { })
          .catch((err) => {
            this.authController(err);
          });
      }
    },
    edit(val) {
      this.$emit("editQuestion", val);
    },
    getAll() {
      this.load = true;
      this.List = [];
      this.axios.get(projectInterview.getAll, {
          params: {
            page: this.currentPage,
            search: this.search,
            projectId: this.$route.query.id,
          },
        })
        .then((res) => {
          const { data, evaluationCriteria, isActive } = res.data.data;

          this.List = data;
          this.evaluationCriteria = evaluationCriteria;
          this.isActive = isActive;
          this.$emit("evaluationCriteria", this.evaluationCriteria);
          this.$emit("List", data);
          this.$emit("isActive", this.isActive);
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          this.axios.post(projectInterview.delete, {
            rowId: item.id,
            question: item.question,
            orderId: item.orderId,
            projectId: this.$route.query.id,
          })
            .then((res) => {
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              this.authController(err);
            });
        }
      });
    },
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return "Tarih Yok";
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
