<template>
  <div
    class="bg-[#8f89bf] h-full px-3 border border-gray-300 rounded-lg flex items-center gap-1.5"
  >
    <img src="@/assets/meeting/soundValue.svg" alt="" class="mt-1.5 w-7" />
    <div
      class="h-1 rounded bg-gray-100 w-full relative"
      @mousedown="handleMouseDown"
      ref="bar"
    >
      <div
        :style="{ width: barWidth }"
        class="h-full rounded bgcol cursor-pointer relative"
      >
        <div class="absolute right-0 h-6 bgcol rounded w-1 -top-[10px]"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: 100,
      dragging: false,
    };
  },
  computed: {
    barWidth() {
      return `${this.value}%`;
    },
  },
  methods: {
    handleMouseDown() {
      this.dragging = true;
      document.addEventListener("mousemove", this.handleMouseMove);
      document.addEventListener("mouseup", this.handleMouseUp);
    },
    handleMouseMove(event) {
      const bar = this.$refs.bar;
      const rect = bar.getBoundingClientRect();
      const width = rect.width;
      const x = event.clientX - rect.left;
      let percentage = (x / width) * 100;
      if (percentage < 0) {
        percentage = 0;
      } else if (percentage > 100) {
        percentage = 100;
      }
      this.value = percentage;
    },
    handleMouseUp() {
      this.dragging = false;
      document.removeEventListener("mousemove", this.handleMouseMove);
      document.removeEventListener("mouseup", this.handleMouseUp);
    },
  },
};
</script>
