<template>
  <modal name="add-seminar-course" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '30%'"
    :scrollable="true">
    <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white">
      <h1 class="text-lg">
        {{ $t("cv.profile.seminarAndCourse.components.add.add") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form @submit.prevent="isEdit ? add() : save()" class="p-5 max-h-screen  xl:pb-5 pb-20" novalidate>
      <div class="space-y-4 pb-3">
        <customInput v-model="name" type="text" min="2" max="255" :required="true" :is-error="true" ref="name"
          :placeholder="$t('cv.profile.seminarAndCourse.components.add.sertificaPlacholder')
            " :title="$t('cv.profile.seminarAndCourse.components.add.sertifica')" />

        <customInput v-model="periotStart" type="date" :required="true" :is-error="true" ref="periotStart" :placeholder="$t('cv.profile.seminarAndCourse.components.add.startPlacholder')
          " :title="$t('cv.profile.seminarAndCourse.components.add.start')" />
        <customInput v-model="periotEnd" type="date" :placeholder="$t('cv.profile.seminarAndCourse.components.add.finishPlacholder')
          " :title="$t('cv.profile.seminarAndCourse.components.add.finish')" />

        <requiredField />

        <div class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0">
          <div class="w-full">
            <buttonItem :load="load" :name="$t('cv.profile.seminarAndCourse.components.add.save')"
              buttonClass="w-full" />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import moment from "moment";
import { seminarAndCourse } from "@/networking/urlmanager.js";
import requiredField from "@/components/requiredField.vue";

export default {
  name: "computer-skills-add",
  props: ["isEdit"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      name: "",
      periotStart: "",
      periotEnd: "",
    };
  },
  methods: {
    show() {
      this.name = "";
      this.periotStart = "";
      this.periotEnd = "";
      this.$modal.show("add-seminar-course");
    },
    hide() {
      this.$modal.hide("add-seminar-course");
    },
    getDateFormater() {
      let startDate = "";
      let endDate = "";

      if (this.periotStart) {
        startDate = moment(this.periotStart).format("YYYY.MM.DD");
      }
      if (this.periotEnd) {
        endDate = moment(this.periotEnd).format("YYYY.MM.DD");
      }

      return startDate + " - " + endDate;
    },
    save() {
      this.load = true;

      this.checkErrors();
      if (this.name?.trim() && this.periotStart) {
        this.$store.commit("addCourseCertificate", {
          name: this.name,
          duration: this.getDateFormater(),
          formatDate: this.getDateFormater(),
        });

        this.name = "";
        this.periotStart = "";
        this.periotEnd = "";
        this.successMessage();

        this.load = false;
        this.hide();
      } else {
        this.load = false;
      }
    },
    add() {
      this.load = true;
      this.checkErrors();
      if (this.name?.trim() && this.periotStart) {
        let list = [
          {
            name: this.name,
            duration: this.getDateFormater(),
            formatDate: this.getDateFormater(),
          },
        ];
        this.axios
          .post(seminarAndCourse.add, {
            cvId: this.$route.params.id,
            list: JSON.stringify(list),
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.$emit("refresh", true);
            this.load = false;
            this.hide();
          })
          .catch((err) => {
            this.authController(err);
            this.load = false;
          });
      } else {
        this.load = false;
      }
    },
    checkErrors() {
      this.$refs.name.validateInput();
      this.$refs.periotStart.validateInput();
    },
  },
};
</script>
