<template>
  <div
    class="flex gap-5 py-8 border-b border-gray-200 max-md:flex-wrap"
    :class="type == 'monthInput' ? '!py-0' : ''"
  >
    <div class="w-6/12 max-md:w-full" v-if="type != 'monthInput'">
      <h1 class="text-base text-gray-700 font-semibold">{{ title }}</h1>
      <p class="text-xs text-gray-700 mt-2">{{ description }}</p>
    </div>
    <div
      class="w-6/12 h-full flex items-center max-md:w-full"
      :class="type == 'monthInput' ? '!w-full' : ''"
    >
      <input
        v-if="
          type != 'textarea' && type != 'monthInput' && type != 'phoneNumber'
        "
        class="w-full border bordercol rounded py-3 outline-none pl-3 text-xs"
        :type="type"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        v-model="selected"
        :min="min"
        :max="max"
        :minlength="min"
        :maxlength="max"
        @change="(r) => type == 'file' && $emit('change', r)"
      />

      <textarea
        v-else-if="type == 'textarea'"
        class="w-full border bordercol rounded py-3 outline-none pl-3 text-xs px-2"
        :class="textareaClass"
        :type="type"
        :required="required"
        :readonly="readonly"
        v-model="selected"
        rows="2"
      ></textarea>

      <VuePhoneNumberInput
        v-if="type == 'phoneNumber'"
        v-model="selected"
        :required="required"
        type="number"
        class="w-full flex-grow"
        color="#182451"
        @update="(value) => $emit('change', value)"
        :translations="{
          countrySelectorLabel: $t(
            'settings.contact.index.phoneInput.countrySelectorLabel'
          ),
          countrySelectorError: $t(
            'settings.contact.index.phoneInput.countrySelectorError'
          ),
          phoneNumberLabel: $t(
            'settings.contact.index.phoneInput.phoneNumberLabel'
          ),
          example: $t('settings.contact.index.phoneInput.example'),
        }"
      />

      <div v-if="type == 'monthInput'" class="relative !w-full flex">
        <input
          type="number"
          v-model="selected"
          :min="min"
          :max="max"
          :required="required"
          class="w-full border bordercol rounded py-3 pl-3 text-xs outline-none flex-grow"
        />
        <span
          class="absolute right-6 top-1/2 transform -translate-y-1/2 text-sm"
          >{{ $t("settings.general.index.cvDurationMonth") }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  name: "CustomerSettingInput",
  props: [
    "title",
    "type",
    "value",
    "required",
    "disabled",
    "min",
    "max",
    "readonly",
    "textareaClass",
    "description",
  ],
  components: {
    VuePhoneNumberInput,
  },
  computed: {
    selectedFormatted: {
      get() {
        if (this.type === "number") {
          return this.selected.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return this.selected;
      },
      set(value) {
        if (this.type === "number") {
          this.selected = value.replace(/\./g, "");
        } else {
          this.selected = value;
        }
      },
    },
  },
  data() {
    return {
      selected: this.value ? String(this.value) : "",
      load: false,
    };
  },
  model: {
    event: "change",
    prop: "value",
  },
  created() {
    if (this.type != "file") {
      this.selected = this.value;
    } else if (this.type == "file" && this.videoId) this.getUrl();
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
    selected(val) {
      if (this.type === "number" || this.type === "monthInput") {
        val = Number(val);
        if (this.max && val > this.max) {
          this.selected = this.max;
        } else {
          this.selected = val;
        }
      }
      if (this.type === "phoneNumber") {
        this.selected = val?.phoneNumber;
      }
      this.$emit("change", val);
    },
  },
};
</script>

<style>
#MazPhoneNumberInput {
  border-color: #182451;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
