<template>
  <div
    class="absolute flex-col items-center invisible opacity-0 group-hover:opacity-100 group-hover:visible flex transition-all duration-200 ease-out w-[110px] z-30"
    :class="[
      tipClass,
      {
        'bottom-10': bottom,
        'bottom-10': top ? false : true,
        'top-10': top,
      },
    ]"
  >
    <div
      class="w-3 h-3 -mb-2 rotate-45 bg-black"
      :class="rotateClass"
      v-if="top"
    ></div>
    <span
      class="relative text-center p-2 text-xs leading-none text-white whitespace-normal bg-black shadow-lg rounded"
      v-html="title"
    ></span>
    <div
      class="w-3 h-3 -mt-2 rotate-45 bg-black"
      :class="rotateClass"
      v-if="bottom ? bottom : top ? false : true"
    ></div>
  </div>
</template>
<script>
export default {
  name: "tool-tip",
  props: ["title", "tipClass", "bottom", "top", "rotateClass"],
};
</script>
