<template>
  <modal
    name="addContact"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '35%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">{{ $t("settings.contact.components.add.add") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form @submit.prevent="save" class="p-5 grid grid-cols-1 gap-4">
      <customInput
        v-model="name"
        type="text"
        min="5"
        max="40"
        :required="true"
        :placeholder="
          $t('settings.contact.components.add.nameSurnamePlacholder')
        "
        :title="$t('settings.contact.components.add.nameSurname')"
      />
      <customInput
        v-model="email"
        type="email"
        min="5"
        max="40"
        :required="true"
        :placeholder="$t('settings.contact.components.add.emailPlacholder')"
        :title="$t('settings.contact.components.add.email')"
      />
      <customInput
        v-model="phonenumber"
        type="phone"
        :required="true"
        :placeholder="$t('settings.contact.components.add.phonePlacholder')"
        :title="$t('settings.contact.components.add.phone')"
      />

      <buttonItem :load="load" name="Kaydet" buttonClass="w-full" />
    </form>
  </modal>
</template>
<script>
import customInput from "@/components/customInput.vue";
import { settingContactPersonal } from "@/networking/urlmanager";
import buttonItem from "@/components/button.vue";

export default {
  name: "addContactPersonal",
  components: {
    customInput,
    buttonItem,
  },
  data() {
    return {
      name: "",
      email: "",
      phonenumber: "",
      load: false,
    };
  },
  methods: {
    show() {
      this.$modal.show("addContact");
    },
    hide() {
      this.$modal.hide("addContact");
    },
    save() {
      if (this.name && this.email && this.phonenumber) {
        this.load = true;
        this.axios
          .post(
            settingContactPersonal.add,
            {
              name: this.name,
              email: this.email,
              phone: this.phonenumber,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.userData.token,
              },
            }
          )
          .then((res) => {
            this.successMessage(res.data.message);
            this.load = false;
            this.name = "";
            this.email = "";
            this.phonenumber = "";
            this.$emit("refresh", true);
            this.$store.commit("setContactPersonal", []);
            this.hide();
          })
          .catch((err) => {
            this.load = false;
            this.authController(err);
          });
      }
    },
  },
};
</script>
