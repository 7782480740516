<template>
  <div class="w-full">
    <multiselect
      v-model="selected"
      :options="List"
      :placeholder="$t('globalComponents.dev.status.placholder')"
      :multiple="true"
      label="name"
      track-by="id"
      class="text-sm"
      :selectLabel="$t('globalComponents.dev.status.add')"
      :deselectLabel="$t('globalComponents.dev.status.delete')"
    >
    </multiselect>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import { utility } from "@/networking/urlmanager";
export default {
  name: "resumeStatusSelect",
  props: ["value"],
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selected: [],
      List: [
        {
          id: 1,
          name: this.$t("globalComponents.dev.status.list.active"),
        },
        {
          id: 3,
          name: this.$t("globalComponents.dev.citySelect.status.list.favorite"),
        },
        {
          id: 4,
          name: this.$t("globalComponents.dev.citySelect.status.list.unsuitable"),
        }
      ],
    };
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.List.push(tag);
      this.selectedSector.push(tag);
    },
  },
  methods: {
    async getAll() {
      try {
        const response = await this.axios.get(utility.getSectorAll);
        this.List = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    // this.getAll()
  },
  watch: {
    selected(val) {
      this.$emit("change", val);
    },
    List(val) {
      if (this.value) {
        this.selected = val.find((l) => l.id == this.value[0].sectorId);
      }
    },
  },
};
</script>
<style>
.multiselect__tags {
  @apply w-full border bordercol rounded outline-none pl-3;
}
</style>
