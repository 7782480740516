<template>
    <div class="flex justify-between items-center rounded-lg h-full p-4" style="box-shadow: 0px 0px 10px rgba(61, 169, 252, 0.5);">
            <img :src="img" alt="" :class="classcard">
        <div class="text-right pr-4">
            <h2 class="textcol font-medium text-sm">{{title}}</h2>
            <h1 class="text-gray-500 text-3xl font-semibold">
                <p v-if="!load">{{count ? count : 0 }}</p>
                <p v-if="load && !count" class="text-sm text-right mt-2"> <i class="fas fa-spinner fa-spin mr-2"></i>{{ $t("general.components.card.loadTitle") }}</p>
            </h1>
        </div>
    </div>
</template>
<script>
export default {
    name:"card-costum", 
    props:["img","title","count","classcard","load"],
    data() {
        return {
            
        }
    },
}
</script>