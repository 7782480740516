<template>
  <div
    class="flex gap-4 w-full h-11 justify-end max-sm:justify-between max-sm:gap-5"
  >
    <projectList
      ref="projectModal"
      v-model="projectList"
      :isEdit="isEdit"
      :projectSave="projectSave"
    />

    <div class="w-full flex justify-end relative">
      <div v-if="$store.state.projectList?.length > 0" class="absolute bg-white w-6 h-6 rounded-full flex justify-center items-center -top-2 -right-1 z-10 border bordercol text-sm font-medium">
        {{ $store.state.projectList.length }}
      </div>
      
      <SyncButton
        buttonClass="!w-full"
        containerClass=" md:!w-[165px] w-full"
        @click="$refs.projectModal.show()"
        :text="$t('project.components.add.title')"
      />
    </div>
    <div class="w-56 max-sm:w-full" v-if="!isEdit">
      <SyncButton
        buttonClass="w-full"
        containerClass="md:!w-[165px] w-full"
        @click="save()"
        :load="load"
        :text="$t('cv.components.cvSave.save')"
        :loadText="$t('cv.components.cvSave.saving')"
      />
    </div>
  </div>
</template>
<script>
import projectList from "./project/add.vue";
import { projectConnect, cv, language, workExperience, educationLife, seminarAndCourse, contact, computerSkills, skills, projectsPublications, positions } from "@/networking/urlmanager.js";

import moment from "moment";
import SyncButton from "@/components/button.vue";

export default {
  name: "cv-save",
  props: ["isEdit"],
  components: { SyncButton, projectList },
  data() {
    return {
      List: [],
      saveView: false,
      disabled: false,
      cvState: 1,
      projectList: [],

      load: false,
      confirmationShown: false,
    };
  },

  methods: {
    clickSave() {
      this.save();
      this.disabled = true;
    },
    async save() {
      const {
        fullname,
        email,
        position,
        biography,
        gender,
        militaryStatus,
        dateBirth,
        driverLicense,
        city,
        country,
        placeBirth,
        resumeLanguage,
        educationLevel,
        professionalExperienceInMonths,
        contactInformation,
      } = this.$store.state.extractDetail;

      if (fullname) {
        this.load = true;
        try {
          const res = await this.axios.post(cv.add, {
            resumeLanguage: resumeLanguage,
            fullname: fullname,
            email: email,
            position: position,
            biography: biography,
            experience: professionalExperienceInMonths,
            educationLevel: educationLevel,
            militaryStatus: militaryStatus,
            genderId: gender,
            dateBirth: dateBirth,
            driverLicense: driverLicense,
            age: parseInt(moment().diff(dateBirth, "years", true)),
            originalFile: this.$store.state.originalFile,
            turId: this.$store.state.extractId,
            cvState: this.cvState,
            city: city,
            country: country,
            placeBirth: placeBirth,
            contact: contactInformation?.filter(
              (r) => r.icon == 1 || r.icon == 2
            ),
          });

          const { insertId, statusId, cvId } = res.data.data;

          if (statusId == 1) {
            const promises = [
              this.workExperienceSave(insertId),
              this.languageSave(insertId),
              this.educationLifeSave(insertId),
              this.seminarAndCourseSave(insertId),
              this.contactSave(insertId),
              this.computerSkillsSave(insertId),
              this.projectsPublicationsSave(insertId),
              this.projectSave(insertId),
              this.SkillsSave(insertId),
              this.positionSave(insertId, position),
              this.referencesSave(insertId),
            ];
            await Promise.all(promises);

            this.listExtract();

            await this.deleteList(insertId);

            await this.successMessage();
            this.cvState = 1;
            this.$router.push("/cv/save/middleware");
          } else {
            const result = await this.$swal({
              title: this.$t("cv.components.cvSave.methods.saveMessages.title"),
              text: this.$t("cv.components.cvSave.methods.saveMessages.text"),
              showDenyButton: true,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: this.$t(
                "cv.components.cvSave.methods.saveMessages.confirmButtonText"
              ),
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              denyButtonText: this.$t(
                "cv.components.cvSave.methods.saveMessages.denyButtonText"
              ),
              denyButtonColor: "#7e0587",
              cancelButtonText: this.$t(
                "cv.components.cvSave.methods.saveMessages.cancelButtonText"
              ),
            });

            if (result.isConfirmed) {
              this.cvState = 2;
              await this.save();

              await this.deleteCv(cvId);
            } else if (result.isDenied) {
              this.cvState = 2;
              await this.deleteList(cvId);
              await this.$router.push("/cv/save/middleware");
            }
          }
          this.load = false;
        } catch (err) {
          this.authController(err);
        }
      } else {
        this.authController(this.$t("cv.components.cvSave.warningMessage"));
      }
    },
    listExtract() {
      if (this.$store.state.cvExtractId) {
        this.axios
          .post(cv.deleteTrain, { rowId: this.$store.state.cvExtractId })
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
      }
    },
    workExperienceSave(cvId) {
      this.load = true;
      if (Array.isArray(this.$store.state.extractDetail.workexperience)) {
        if (this.$store.state.extractDetail.workexperience.length > 0) {
          this.axios
            .post(workExperience.add, {
              cvId: cvId,
              list: JSON.stringify(
                this.$store.state.extractDetail.workexperience
              ),
              experinceList: JSON.stringify(
                this.$store.state.extractDetail.workexperience
              ),
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.authController(err);
              this.load = false;
            });
        }
      }
    },
    languageSave(cvId) {
      if (Array.isArray(this.$store.state.extractDetail.languages)) {
        if (this.$store.state.extractDetail.languages.length > 0) {
          this.axios
            .post(language.add, {
              cvId: cvId,
              list: JSON.stringify(this.$store.state.extractDetail.languages),
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.authController(err);
              this.load = false;
            });
        }
      }
    },
    educationLifeSave(cvId) {
      this.load = true;
      if (Array.isArray(this.$store.state.extractDetail.schoolAndEducation)) {
        if (this.$store.state.extractDetail.schoolAndEducation.length > 0) {
          this.axios
            .post(educationLife.add, {
              cvId: cvId,
              list: JSON.stringify(
                this.$store.state.extractDetail.schoolAndEducation
              ),
              educationList: JSON.stringify(
                this.$store.state.extractDetail.schoolAndEducation
              ),
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.load = false;
            });
        }
      }
    },
    seminarAndCourseSave(cvId) {
      this.load = true;
      if (Array.isArray(this.$store.state.extractDetail.seminarAndCourse)) {
        if (this.$store.state.extractDetail.seminarAndCourse.length > 0) {
          this.axios
            .post(seminarAndCourse.add, {
              cvId: cvId,
              list: JSON.stringify(
                this.$store.state.extractDetail.seminarAndCourse
              ),
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.authController(err);
              this.load = false;
            });
        }
      }
    },
    contactSave(cvId) {
      this.load = true;
      if (Array.isArray(this.$store.state.extractDetail.contactInformation)) {
        if (this.$store.state.extractDetail.contactInformation.length > 0) {
          this.axios
            .post(contact.add, {
              cvId: cvId,
              list: JSON.stringify(
                this.$store.state.extractDetail.contactInformation
              ),
              isCostumAdd: false,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.authController(err);
              this.load = false;
            });
        }
      }
    },
    referencesSave(cvId) {
      this.load = true;
      if (Array.isArray(this.$store.state.extractDetail.references)) {
        if (this.$store.state.extractDetail.references.length > 0) {
          this.axios
            .post(references.add, {
              cvId: cvId,
              list: JSON.stringify(this.$store.state.extractDetail.references),
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.authController(err);
              this.load = false;
            });
        }
      }
    },
    computerSkillsSave(cvId) {
      this.load = true;
      if (Array.isArray(this.$store.state.extractDetail.computerSkills)) {
        if (this.$store.state.extractDetail.computerSkills.length > 0) {
          this.axios
            .post(computerSkills.add, {
              cvId: cvId,
              list: JSON.stringify(
                this.$store.state.extractDetail.computerSkills
              ),
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.authController(err);
              this.load = false;
            });
        }
      }
    },
    SkillsSave(cvId) {
      this.load = true;
      if (Array.isArray(this.$store.state.extractDetail.skills)) {
        let skillsList = this.$store.state.extractDetail.skills.filter(
          (item) => {
            return (
              item.name !== null && item.name !== undefined && item.name !== ""
            );
          }
        );
        if (skillsList.length > 0) {
          this.axios
            .post(skills.add, {
              cvId: cvId,
              list: JSON.stringify(this.$store.state.extractDetail.skills),
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.authController(err);
              this.load = false;
            });
        }
      }
    },
    projectsPublicationsSave(cvId) {
      this.load = true;
      if (Array.isArray(this.$store.state.extractDetail.projectsPublications)) {
        if (this.$store.state.extractDetail.projectsPublications.length > 0) {
          this.axios
            .post(projectsPublications.add, {
              cvId: cvId,
              list: JSON.stringify(
                this.$store.state.extractDetail.projectsPublications
              ),
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.authController(err);
              this.load = false;
            });
        }
      }
    },
    projectSave(cvId) {
      this.$refs.projectModal.chanceLoad();
      if (Array.isArray(this.projectList)) {
        if (this.projectList.length > 0) {
          this.axios
            .post(projectConnect.add, {
              userId: cvId,
              list: JSON.stringify(this.projectList),
              fullname: this.$store.state.extractDetail.fullname,
            })
            .then(() => {
              this.List = [];
              if (this.isEdit) {
                let data = this.projectList.map((item) => {
                  if (item.stateId == 2 || item.stateId == 1) {
                    return {
                      id: item.id,
                      projectName: item.projectName,
                      stateId: 1,
                    };
                  } else {
                    return {
                      id: item.id,
                      projectName: item.projectName,
                      stateId: 5,
                    };
                  }
                });
                this.$refs.projectModal.chanceLoad();
                this.$store.commit("setProjectList", data);
                this.projectList = [];
                this.successMessage();
              }
            })
            .catch((err) => {
              this.$refs.projectModal.chanceLoad();
              this.authController(err);
            });
        }
      }
    },
    positionSave(cvId, position) {
      if (
        Array.isArray(this.$store.state.extractDetail.workexperience) ||
        position
      ) {
        if (
          this.$store.state.extractDetail.workexperience.length > 0 ||
          position
        ) {
          this.load = true;
          this.axios
            .post(positions.add, {
              cvId: cvId,
              list: JSON.stringify(
                this.$store.state.extractDetail.workexperience
              ),
              position: position,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.load = false;
            });
        }
      }
    },
    async deleteCv(cvId) {
      this.axios
        .post(cv.changeStatus, {
          rowId: cvId,
          stateId: 2,
        })
        .then(() => {})
        .catch((err) => {
          this.authController(err);
        });
    },
    async deleteList(cvId) {
      await this.axios.post(cv.deleteTrain, {
        rowId: this.$store.state.extractId,
        cvId: cvId,
      });
    },
  },
  watch: {
    isSave(val) {
      this.saveView = val;
    },
  },
};
</script>
<style></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
