<template>
  <div
    class="w-7/12 flex justify-between items-center max-md:hidden relative bg-cover bg-center"
    style="
      background-image: url(https://images.unsplash.com/photo-1585599122425-251a97e9ecf4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80);
    "
  >
    <div
      class="w-full px-10 bg-opacity-70 text-white h-full flex items-center justify-center pl-10 text-justify"
      style="background: rgb(25, 36, 80, 0.9)"
    >
      <div>
        <h1 class="text-4xl font-light">
          <span class="text-5xl"
            ><b class="font-bold">CV US'</b>
            {{ $t("auth.components.information.title") }}</span
          >
          <span class="block mt-5 text-xl font-semibold">{{
            $t("auth.components.information.titleText")
          }}</span>
        </h1>
        <p class="font-Poppins text-base mt-8 font-extralight">
          {{ $t("auth.components.information.text") }}
          <b>CVUS'u</b> {{ $t("auth.components.information.textTwo") }}
        </p>

        <p
          class="text-sm absolute bottom-16 left-0 w-full text-left px-10 text-white cursor-pointer"
          @click="$router.push('/privacy-policy')"
        >
          {{ $t("auth.components.information.privacyPolicy") }}
        </p>

        <p
          class="text-sm absolute bottom-10 left-0 w-full text-left px-10 text-white"
        >
          {{ $t("auth.components.information.copyright") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "information-sign",
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    this.isLoaded = true;
  },
};
</script>
