<template>
  <div class="w-full sticky top-24 flex flex-col right-0 bgcol z-[10]">
    <div class="bgcol text-white grid grid-cols-3 max-sm:grid-cols-3 text-sm max-sm:text-sm w-full">
      <router-link
        v-for="item in link"
        :key="item.id"
        :to="item.url"
        class="py-4 text-center max-sm:py-3"
        @click="itemPage = item.id"
        :class="item.url == $route.path ? ' bg-white bg-opacity-20' : 'git'"
        >{{ item.name }}
      </router-link>
    </div>

    <div class="flex h-20 max-md:h-auto max-md:py-3 w-full px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 bg-[#454f73] text-white border-t border-gray-400 shadow max-md:flex-wrap items-center">
      <div class="w-10/12 max-md:w-full">
        <h1 class="text-white font-semibold">
          {{ title }}
        </h1>
        <p class="text-xs text-gray-200 max-md:text-xs max-sm:text-[10px]">
          {{ description }}
        </p>
      </div>

      <div v-if="!load" class="w-2/12 flex justify-end gap-4 items-center max-md:w-full max-md:mt-5 max-sm:mt-3 max-md:justify-start">
        <saveButton
          :load="buttonLoad"
          :name="$t('settings.general.index.saveButton')"
          buttonClass="!bg-white textcol max-sm:text-sm max-sm:!h-9"
        />
      </div>

      <div v-else class="w-6/12 flex justify-end gap-4 items-center max-md:w-full max-md:mt-5 max-sm:mt-3 max-md:justify-start">
        <div class="text-center flex gap-3 items-center">
          <div class="w-8 h-8 max-sm:w-5 max-sm:h-5 rounded-full animate-spin inline-block border-2 border-solid border-white border-t-transparent shadow-md"></div>
          <p class="font-light text-white block max-sm:text-sm">
            {{ $t("settings.general.index.loadText") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import saveButton from "@/components/button.vue";

export default {
  name: "navBar",
  props:["title","description","load","buttonLoad"],
  components: {saveButton},
  data() {
    return {
      link: [
        {
          id: 1,
          name: this.$t("settings.components.navbar.general"),
          url: "/setting",
        },
        {
          id: 2,
          name: this.$t("settings.components.navbar.communication"),
          url: "/setting/contact",
        },
        {
          id: 4,
          name: this.$t("settings.components.navbar.configuration"),
          url: "/setting/configration",
        },
      ],
    };
  },
};
</script>
