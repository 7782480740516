<template>
    <div class="wrapper" :class="error && 'error shake'">
        <label v-if="label" class="textcol font-semibold flex gap-1 items-center">
            {{ label }}
            <p v-if="required" class="text-[10px]">
                <i class="fa-sharp fa-solid fa-star-of-life text-[7px] text-red-600" />
            </p>
        </label>

        <VueMultiselect :taggable="taggable" @tag="newOption" @search-change="filterOptions" :loading="loading" :disabled="loading" :value="selected" :placeholder="placeholder" :options="filteredOptions" :track-by="trackBy" :label="trackLabel" @input="updateSelected" />

        <p v-if="error" class="text-red-500 text-xs mt-2">
            {{ errorText }}
        </p>
    </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import i18n from '@/plugins/i18n';
import Fuse from 'fuse.js';

export default {
    components: { VueMultiselect },
    model: {
        event: "change",
        prop: "value",
    },
    props: {
        value: {
            type: [String, Number, Object, Array],
            default: null
        },
        trackLabel: {
            type: String,
            default: 'name'
        },
        trackBy: {
            type: String,
            default: 'id'
        },
        required: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: i18n.t('general.components.select.placeholder')
        },
        getOptions: {
            type: Function,
            required: true
        },
        taggable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selected: this.value,
            options: [],
            filteredOptions: [],
            error: false,
            errorText: '',
            loading: false,
            fuse: null,
        }
    },
    watch: {
        value(newValue) {
            this.selected = newValue;
        }
    },
    methods: {
        validate(errorText) {
            if (this.selected) return;

            this.error = true;
            this.errorText = errorText || i18n.t('general.components.select.errorText', { label: this.label });
        },
        handleSelect() {
            this.error = false;
        },
        updateSelected(value) {
            this.selected = value;
            this.error = false;
            this.$emit('change', value);
        },
        newOption(option) {
            const newOption = {
                id: null,
                name: option?.trim()
            };

            this.updateSelected(newOption);
        },
        filterOptions(query) {
            if (!this.fuse)
                return;

            if (!query) {
                this.filteredOptions = [...this.options];
                return;
            }

            const results = this.fuse.search(query);
            this.filteredOptions = results.map((result) => result.item);
        }
    },
    async mounted() {
        try {
            this.loading = true;
            const response = await this.getOptions();
            this.options = response;
            this.filteredOptions = this.options;

            const value = await this.options.find(option => option.name?.toLowerCase() === this.selected?.toLowerCase());

            if (!value) {
                const newOption = {
                    id: null,
                    name: this.value?.trim()
                };
                this.updateSelected(newOption);
            }

            if (typeof this.value !== 'object') {
                this.updateSelected(value);
            }

            this.fuse = new Fuse(this.options, {
                keys: [this.trackLabel],
                includeScore: false,
                threshold: 0.8,
            });
        } catch (error) {
            console.log('error', error);
        } finally {
            this.loading = false;
        }
    }
}
</script>

<style>
.wrapper .multiselect__tags,
.multiselect__content-wrapper,
.multiselect__single,
.multiselect__input {
    @apply text-xs;
}

.multiselect__tags .multiselect__single {
    @apply leading-relaxed;
}

.error .multiselect__tags {
    @apply border-red-500;
}
</style>
