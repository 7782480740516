<template>
  <modal
    name="editContact"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '35%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">{{ $t("settings.contact.components.edit.edit") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form @submit.prevent="save" class="p-5 grid grid-cols-1 gap-4">
      <customInput
        v-model="name"
        type="text"
        min="5"
        max="40"
        :required="true"
        :placeholder="$t('settings.contact.components.edit.nameSurnamePlacholder')"
        :title="$t('settings.contact.components.edit.nameSurname')"
      />
      <customInput
        v-model="email"
        type="email"
        min="5"
        max="40"
        :required="true"
        :placeholder="$t('settings.contact.components.edit.emailPlacholder')"
        :title="$t('settings.contact.components.edit.email')"
      />
      <customInput
        v-model="phone"
        type="number"
        :required="true"
        :placeholder="$t('settings.contact.components.edit.phonePlacholder')"
        :title="$t('settings.contact.components.edit.phone')"
      />

      <buttonItem :load="load" name="Kaydet" buttonClass="w-full" />
    </form>
  </modal>
</template>
<script>
import customInput from "@/components/customInput.vue";
import { settingContactPersonal } from "@/networking/urlmanager";
import buttonItem from "@/components/button.vue";

export default {
  name: "editContactPersonal",
  props: ["detail"],
  components: {
    customInput,
    buttonItem,
  },
  data() {
    return {
      id: "",
      name: "",
      email: "",
      phone: "",
      load: false,
    };
  },
  methods: {
    show() {
      setTimeout(() => {
        this.id = this.detail.id;
        this.name = this.detail.name;
        this.email = this.detail.email;
        this.phone = this.detail.phone;
      }, 50);
      this.$modal.show("editContact");
    },
    hide() {
      this.$modal.hide("editContact");
    },
    save() {
      if (this.name && this.email && this.phone) {
        this.load = true;
        this.axios
          .post(
            settingContactPersonal.edit,
            {
              rowId: this.id,
              name: this.name,
              email: this.email,
              phone: this.phone,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.userData.token,
              },
            }
          )
          .then((res) => {
            this.successMessage(res.data.message);
            this.load = false;
            this.$store.commit("setContactPersonal", []);
            this.$emit("refresh", true);
            this.hide();
          })
          .catch((err) => {
            this.load = false;
            this.authController(err);
          });
      }
    },
  },
};
</script>