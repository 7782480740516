<template>
    <label :key="item.id" class="flex items-center gap-2" :for="`item${item.id}`" @dblclick="() => startEdit(item.id)">
        <span v-if="item.id !== listItemEditIndex" class="text-gray-600 font-medium">
            {{ item.weightedScore }}
        </span>

        <input ref="inputRef" v-else type="text" :id="`item${item.id}`"
            :value="listItemIsLoad ? listItemEditValue : item.weightedScore" @input="(val) => {
                const itemIndex = List.findIndex((i) => i.id === item.id);
                if (itemIndex !== -1) {
                    listItemEditValue = val.target.value;
                }
            }" class="bg-transparent border border-gray-300 rounded-md text-sm px-2 m-0 h-5 w-12 resize-none" @blur="() => {
                if (!listItemIsLoad)
                    listItemEditIndex = -1
            }" @keydown.enter="(e) => handleEdit(item.id, item.weightedScore)"
            :disabled="listItemIsLoad"
            @keydown.esc="() => listItemEditIndex = -1" />

        <button type="button" class="text-gray-400 hover:text-gray-600" @mousedown.prevent @click="() => listItemEditIndex !== item.id ?
            startEdit(item.id) :
            handleEdit(item.id, item.weightedScore)">
            <i v-if="listItemEditIndex !== item.id" class="fas fa-edit" />
            <i v-else-if="listItemEditIndex === item.id && listItemIsLoad" class="fas fa-spinner animate-spin" />
            <i v-else class="fas fa-check" />
        </button>
    </label>
</template>

<script>
import { projectInterview } from "@/networking/urlmanager";

export default {
    props: {
        item: Object,
        List: Array,
        noPost: Boolean
    },
    data() {
        return {
            listItemEditIndex: -1,
            listItemEditValue: null,
            listItemIsLoad: false,
            mutableList: this.List,
        };
    },
    methods: {
        startEdit(index) {
            this.listItemEditIndex = index
            this.$nextTick(() => {
                const inputRef = this.$refs.inputRef;
                inputRef.focus();
            });
        },

        async handleEdit(index, val) {
            const itemIndex = this.mutableList.findIndex((item) => item.id === index);

            if (!this.listItemEditValue || this.listItemEditValue === val)
                return this.listItemEditIndex = -1;

            this.listItemIsLoad = true;

            if (Number.isNaN(Number(this.listItemEditValue))) {
                this.listItemEditIndex = -1;
                this.listItemIsLoad = false;

                return
            }


            if (!this.noPost)
                await this.axios
                    .post(projectInterview.edit, {
                        ...this.mutableList[itemIndex],
                        rowId: this.mutableList[itemIndex].id,
                        weightedScore: this.listItemEditValue
                    })
                    .then((res) => {
                        this.mutableList[itemIndex].weightedScore = this.listItemEditValue;
                        this.$emit("updateList", this.mutableList);
                        this.successMessage(res.data.message);
                        this.listItemEditIndex = -1;
                        this.listItemEditValue = null;
                    })
                    .catch((err) => {
                        this.listItemIsLoad = false;
                        this.listItemEditValue = null;
                        this.authController(err);
                    })
            else {
                this.mutableList[itemIndex].weightedScore = this.listItemEditValue;
                this.$emit("updateList", this.mutableList);
                this.listItemEditIndex = -1;
                this.listItemEditValue = null;
            }

            this.listItemIsLoad = false;
        },
    },
};
</script>
