<script>
const alert = {
    danger: "danger",
    success: "success",
}

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "alert",
    props: ["message", "type"],
    data() {
        return {
            alertType: alert[this.type],
        };
    },
}
</script>

<template>
    <div class="px-5 py-2 rounded-md text-sm" :class="alertType">
        {{ message }}
    </div>
</template>

<style>
.danger {
    @apply bg-red-100 text-red-800;
}

.success {
    @apply bg-green-100 text-green-800;
}
</style>