<template>
    <div>
        <v-tour name="myTour" :steps="steps" :options="myOptions" :callbacks="myCallbacks" ></v-tour>
    </div>
</template>
    
<script>
import { users } from "@/networking/urlmanager";
export default {
    name: 'my-tour',
    props: ['steps', "moduleId"],
    data() {
        return {
            myCallbacks: {
                onFinish: this.save,
                onStop: this.save
            },
            myOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip:  this.$t('globalComponents.tour.buttonSkip'),
                    buttonPrevious: this.$t('globalComponents.tour.buttonPrevious'),
                    buttonNext: this.$t('globalComponents.tour.buttonNext'),
                    buttonStop: this.$t('globalComponents.tour.buttonStop')

                }
            },
        }
    },
    mounted() {
        this.$tours['myTour'].start();
    },
    methods: {
        save() {
            if (this.moduleId) {
                this.axios.post(users.tourControl, {
                    moduleId: this.moduleId,
                    email:this.$store.state.userData.email
                }).then(() => {
                    this.$store.commit("setTourModule", this.moduleId);
                }).catch((error) => {
                    console.error(error);
                });
            }

        },
    },
}
</script>

  