var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"name":"personel-information","height":_vm.isMobile ? '100%' : 'auto',"width":_vm.isMobile ? '100%' : '40%',"scrollable":true}},[_c('div',{staticClass:"flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"},[_c('h1',{staticClass:"text-lg"},[_vm._v(" "+_vm._s(_vm.$t( "project.projectUser.components.shortList.components.personelInformation.index.preliminaryInterviewAppointment" ))+" ")]),_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.hide.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-times"})])]),_c('div',{staticClass:"p-10 flex flex-col gap-5 max-sm:p-4"},[_c('card',{attrs:{"url":_vm.passportPhoto,"load":_vm.load,"title":_vm.$t(
          'project.projectUser.components.shortList.components.personelInformation.index.card.passportPhoto'
        )}}),_c('card',{attrs:{"url":_vm.healthReport,"load":_vm.load,"title":_vm.$t(
          'project.projectUser.components.shortList.components.personelInformation.index.card.healthReport'
        )}}),_c('card',{attrs:{"url":_vm.diploma,"load":_vm.load,"title":_vm.$t(
          'project.projectUser.components.shortList.components.personelInformation.index.card.diploma'
        )}}),_c('card',{attrs:{"url":_vm.criminalRecordPaper,"load":_vm.load,"title":_vm.$t(
          'project.projectUser.components.shortList.components.personelInformation.index.card.criminalRecordPaper'
        )}}),_c('card',{attrs:{"url":_vm.certificateResidence,"load":_vm.load,"title":_vm.$t(
          'project.projectUser.components.shortList.components.personelInformation.index.card.certificateOfResidence'
        )}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }