<template>
  <div class="h-[400px]">
    <div class="p-5 scroltype h-full xl:pb-1 pb-10">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
        <!-- EĞİTİM SEVİYESİ -->
        <educationSelect v-model="list.educationType" />

        <!-- OKUL -->
        <div class="w-full">
          <label class="text textcol font-semibold">{{
            $t("project.components.add.school")
          }}</label>
          <institutionSelect
            :isEdit="isEdit"
            v-model="list.institutionList"
            :educationType="list.educationType"
          />
        </div>

        <!-- BÖLÜM -->
        <div class="w-full">
          <label class="text textcol font-semibold">{{
            $t("project.components.add.section")
          }}</label>
          <departmentSelect :isEdit="isEdit" v-model="list.departmentList" />
        </div>

        <!-- YABANCI DİL -->
        <div class="w-full">
          <label class="textcol font-semibold">{{
            $t("project.components.add.foreignLanguage")
          }}</label>
          <languageSelect :isEdit="isEdit" v-model="list.languageList" />
        </div>
        <div class="">
          <experienceRange v-model="list.experienceDuration" />
        </div>

        <!-- ÇALIŞMA İLİ -->
        <div>
          <label class="textcol font-semibold flex gap-1 items-center"
            >{{ $t("project.components.add.WorkCity") }}
          </label>
          <citySelect :isEdit="isEdit" v-model="list.cityList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import educationSelect from "./educationSelect.vue";
import experienceRange from "./experienceRange.vue";
import languageSelect from "@/components/dev/languageSelect.vue";
import institutionSelect from "@/components/dev/institutionSelect.vue";
import departmentSelect from "@/components/dev/departmentSelect.vue";
import citySelect from "@/components/dev/citySelect.vue";
export default {
  name: "steptwoPage",
  props: ["stepList", "isEdit"],
  components: {
    educationSelect,
    experienceRange,
    languageSelect,
    institutionSelect,
    departmentSelect,
    citySelect,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({ list: (state) => state.project.stepData.stepTwo }),
  },
};
</script>
