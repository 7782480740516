<template>
  <div class="signIn flex">
    <info />
    <div
      class="h-screen w-5/12 px-24 max-md:w-full max-lg:px-10 max-sm:px-6 flex items-center"
    >
      <div class="w-full">
        <form @submit.prevent="save">
          <img class="mx-auto w-72" src="@/assets/global/fullLogo.png" alt="" />

          <div class="w-full mt-16 text-center">
            <h1 class="text-lg font-semibold text-center textcol">
              {{ $t("auth.setPassword.password") }}
            </h1>
            <p class="text-center mb-5 text-sm">
              {{ $t("auth.setPassword.message") }}
            </p>

            <customInput
              v-model="email"
              type="text"
              min="3"
              max="255"
              :required="true"
              :placeholder="$t('auth.setPassword.email')"
            />
            <div class="mt-6">
              <asyncButton
                :text="$t('auth.setPassword.passwordAgain')"
                :load="load"
                buttonClass=" !w-full "
                :loadText="$t('auth.setPassword.loadText')"
              />
              <asyncButton
                @click="$router.push('/')"
                :text="$t('auth.setPassword.back')"
                buttonClass="mt-3 !w-full !bg-white textcol !border !bordercol "
                :loadText="$t('auth.setPassword.loadText')"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import info from "./components/information.vue";
import customInput from "@/components/diffInput.vue";
import asyncButton from "@/components/button.vue";

import { auth } from "@/networking/urlmanager";
import axios from "axios";

export default {
  name: "setPassword",
  components: {
    info,
    customInput,
    asyncButton,
  },
  data() {
    return {
      load: false,
      email: "",
    };
  },
  methods: {
    save() {
      if (/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
        this.load = true;
        axios
          .post(auth.resetPassword, {
            email: this.email,
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.load = false;
            this.email = "";
            this.$router.push("/");
          })
          .catch((err) => {
            this.load = false;
            this.authController(
              err.response.data.message ? err.response.data.message : err
            );
          });
      } else {
        this.warningMesage(this.$t("auth.setPassword.warningMessage"));
        this.load = false;
      }
    },
  },
};
</script>
