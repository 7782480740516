<template>
  <div>
    <slot name="external"></slot>
    <div
      @mousedown="close"
      class="w-full bg-black z-[998] fixed h-screen top-0 left-0 transition-all ease-out"
      :class="[
        'bg-opacity-0 invisible ',
        isOpen ? '!bg-opacity-20 !visible' : '',
        externalClass,
      ]"
    >
      <div
        @mousedown.stop="show"
        class="bg-white p-4 shadow-md"
        :class="[
          'sidebar z-[998]',
          isOpen ? 'sidebar-open' : '',
          modalClass,
        ]" >

        <div v-if="isHeader" class="flex justify-between items-center p-4 border-b border-gray-200" >
          <button @click="close()" class="flex items-center">
            <i class="fas fa-times fa-2x text-[#344CA0] text-2xl"></i>
          </button>
          <h2 class="text-lg font-bold text-[#344CA0]">
            {{ headerTitle }}
          </h2>
          <button v-if="isRefresh" @click="$emit('refresh',true)" class="flex items-center">
              <i class="fa-solid fa-arrows-rotate text-[#344CA0] text-xl"></i>
          </button>
        </div>

        <div class="scroltype h-full" :class="hidenOverflowY ? '' : 'overflow-y-auto'">
          <slot name="content"></slot>
        </div>
        
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "CustomModal",
  props: ["externalClass", "modalClass","isHeader","headerTitle","isClosable","closeController","isRefresh","hidenOverflowY"],
  data() {
    return {
      isOpen: false,
      load: false,
    };
  },
  methods: {
    async show() {
      this.isOpen = true;
      this.$emit("show", true);
    },
    async close() {
      if(this.isClosable && !this.closeController){
        this.$emit("closeErorr", true);
      }else{
        this.isOpen = false;
        this.$emit("close", false);
      }
    },
  },
};
</script>
  
<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 500;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar-open {
  transform: translateX(0);
}

.scroltype::-webkit-scrollbar {
  width: 6px;
}

.scroltype::-webkit-scrollbar-thumb {
  background: #344ca0;
  border-radius: 10px;
}
</style>
