<template>
  <modal name="add-computer-skills" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '30%'"
    :scrollable="true">
    <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white">
      <h1 class="text-lg">
        {{ $t("cv.profile.information.computerSkills.add.add") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form @submit.prevent="isEdit ? add() : save()" class="p-5 max-h-screen xl:pb-5 pb-20" novalidate>
      <div class="space-y-4 pb-3">
        <multiSelectVue :placeholder="$t('cv.profile.information.computerSkills.add.SkillPlacholder')
          " :label="$t('cv.profile.information.computerSkills.add.SkillTitle')
            " ref="SkillRef" v-model="name" :required="true" :getOptions="getSkills" />

        <customInput v-model="degree" type="select" :selectList="skilsDegree" :placeholder="$t('cv.profile.information.computerSkills.add.levelPlacholder')
          " :title="$t('cv.profile.information.computerSkills.add.level')" />

        <requiredField />
        <div class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0">
          <div class="w-full">
            <buttonItem :load="load" :name="$t('cv.profile.information.computerSkills.add.save')"
              buttonClass="w-full" />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import { computerSkills, utility } from "@/networking/urlmanager.js";

export default {
  name: "computer-skills-add",
  props: ["isEdit"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      name: null,
      degree: 0,
      skilsDegree: [
        {
          value: 0,
          name: this.$t(
            "cv.profile.information.computerSkills.add.unspecified"
          ),
        },
        {
          value: 1,
          name: this.$t("cv.profile.information.computerSkills.add.beginner"),
        },
        {
          value: 2,
          name: this.$t(
            "cv.profile.information.computerSkills.add.intermediateLevel"
          ),
        },
        {
          value: 3,
          name: this.$t(
            "cv.profile.information.computerSkills.add.advancedLevel"
          ),
        },
      ],
    };
  },
  methods: {
    show() {
      this.name = "";
      this.degree = 0;
      this.$modal.show("add-computer-skills");
    },
    hide() {
      this.$modal.hide("add-computer-skills");
    },
    save() {
      this.load = true;

      this.$refs.SkillRef.validate();

        this.$store.commit("addComputerSkils", {
          name: this.name,
          level: this.degree,
        });

        this.name = null;
        this.degree = 0;
        this.successMessage();

        this.load = false;
        this.hide();
    },
    add() {
      this.load = true;
      this.$refs.SkillRef.validate();

      let list = [
        {
          ...this.name,
          level: this.degree,
        },
      ];
      this.axios
        .post(computerSkills.add, {
          cvId: this.$route.params.id,
          list: JSON.stringify(list),
        })
        .then((res) => {
          this.successMessage(res.data.message);
          this.$emit("refresh", true);
          this.load = false;
          this.hide();
        })
        .catch((err) => {
          this.authController(err);
          this.load = false;
        }).finally(() => {
          this.load = false;
        });
    },
    async getSkills() {
      const response = await this.axios.get(utility.getSkillAll);
      return response.data.data;
    },
  },
};
</script>
