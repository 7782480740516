<template>
  <modal
    name="add-information"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.personalInformation.components.add.add") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="isEdit ? add() : save()"
      class="p-5 max-h-screen xl:pb-5 pb-20"
      novalidate
    >
      <div>
        <label class="textcol font-semibold flex gap-1 items-center"
          >{{ $t("cv.profile.personalInformation.components.add.userBlog") }}
          <p class="text-[10px]">
            <i
              class="fa-sharp fa-solid fa-star-of-life text-[7px] text-red-500"
            ></i>
          </p>
        </label>

        <select
          v-model="selected"
          required
          class="w-full border bordercol rounded py-3 outline-none pl-3 text-xs"
        >
          <option
            v-for="(item, index) in addList"
            :key="index"
            :value="item"
            :disabled="item.disabled"
          >
            {{ item.name }}
          </option>
        </select>
      </div>

      <div v-if="selected" class="space-y-4 pb-3 mt-5">
        <div v-if="selected.type == 'select'">
          <customInput
            v-model="value"
            type="select"
            :selectList="selected.selectList"
            :placeholder="selected.name"
            :title="selected.name"
          />
        </div>
        <div v-else-if="selected.type == 'date'">
          <customInput
            v-model="value"
            type="date"
            :selectList="selectList"
            :placeholder="selected.name"
            :title="selected.name"
          />
        </div>
        <div v-else-if="selected.type == 'multiSelect'">
          <multiSelectVue
            :placeholder="selected.name"
            :label="selected.name"
            v-model="value"
            :required="true"
            :getOptions="selected.getOptions"
          />
        </div>
        <div v-else>
          <customInput
            v-model="value"
            type="text"
            min="2"
            max="255"
            :required="true"
            :is-error="true"
            ref="valueInput"
            :placeholder="selected.name"
            :title="selected.name"
          />
        </div>
      </div>

      <div class="pt-4">
        <requiredField />
      </div>
      <div
        class="flex gap-10 pt-4 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
      >
        <div class="w-full">
          <buttonItem
            :load="load"
            :disabled="value === ''"
            :name="$t('cv.profile.personalInformation.components.add.save')"
          />
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import { cv } from "@/networking/urlmanager.js";
import moment from "moment";

import { utility } from "@/networking/urlmanager";

export default {
  name: "add-language",
  props: ["isEdit", "Detail", "index"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      selected: "",
      value: "",

      addList: [
        {
          id: 1,
          name: this.$t(
            "cv.profile.personalInformation.components.add.dateOfBirth"
          ),
          type: "date",
          disabled: false,
        },
        {
          id: 2,
          name: this.$t(
            "cv.profile.personalInformation.components.add.country"
          ),
          type: "multiSelect",
          getOptions: async () => {
            const response = await this.axios.get(utility.getCountryAll);
            return response.data.data
          },
          disabled: true,
        },
        {
          id: 3,
          name: this.$t("cv.profile.personalInformation.components.add.city"),
          type: "multiSelect",
          getOptions: async () => {
            const response = await this.axios.get(utility.getCityAll);
            return response.data.data
          },
          disabled: true,
        },
        {
          id: 6,
          name: this.$t(
            "cv.profile.personalInformation.components.add.drivingLicence"
          ),
          type: "select",
          disabled: true,
          selectList: [
            {
              name: this.$t(
                "cv.profile.personalInformation.components.add.drivingLicenceYes"
              ),
              value: 1,
            },
            {
              name: this.$t(
                "cv.profile.personalInformation.components.add.drivingLicenceNo"
              ),
              value: 2,
            },
          ],
        },
        {
          id: 4,
          name: this.$t(
            "cv.profile.personalInformation.components.add.placeOfBirth"
          ),
          type: "text",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    show() {
      (this.selected = ""),
        (this.value = ""),
        setTimeout(() => {
          this.informationController();
        }, 50);
      this.$modal.show("add-information");
    },
    hide() {
      this.$modal.hide("add-information");
    },
    informationController() {
      this.addList.forEach((item) => {
        if (item.id == 1)
          item.disabled = this.$store.state.extractDetail.dateBirth
            ? true
            : false;
        if (item.id == 2)
          item.disabled = this.$store.state.extractDetail.country
            ? true
            : false;
        if (item.id == 3)
          item.disabled = this.$store.state.extractDetail.city ? true : false;
        if (item.id == 4)
          item.disabled = this.$store.state.extractDetail.placeBirth
            ? true
            : false;
        if (item.id == 6)
          item.disabled = this.$store.state.extractDetail.driverLicense
            ? true
            : false;
      });
    },
    save() {
      this.load = true;

      if (this.value?.trim()) {
        this.$emit("loadChance", true);
        this.$store.commit("setEditPersonalInformation", {
          id: this.selected.id,
          value: this.value,
        });

        this.value = "";
        this.successMessage();

        this.load = false;
        this.$emit("loadChance", true);
        this.hide();
      } else {
        this.load = false;
      }
    },
    add() {
      this.load = true;

      const data = this.addValue();

      if (data) {
        this.axios
          .post(cv.edit, {
            cvId: this.$route.params.id,
            ...data,
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.$emit("refresh", true);
            this.load = false;
            this.hide();
          })
          .catch((err) => {
            console.log(err);
            this.authController(err);
            this.load = false;
          });
      } else {
        this.load = false;
      }
    },
    addValue() {
      switch (this.selected.id) {
        case 1:
          return { dateBirth: moment(this.value).format("YYYY.MM.DD") };
          break;
        case 2:
          return { country: this.value };
          break;
        case 3:
          return { city: this.value };
          break;
        case 4:
          return { placeBirth: this.value };
          break;
        case 6:
          return { driverLicense: this.value };
          break;
        default:
          return false;
          break;
      }
    },
  },
  watch: {
    selected() {
      this.value = "";
    },
  },
};
</script>
