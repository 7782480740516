<template>
  <modal
    name="add-contact"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.information.contact.add.add") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="isEdit ? add() : save()"
      class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
      novalidate
    >
      <div class="space-y-4 pb-3">
        <customInput
          v-model="icon"
          type="select"
          :selectList="typeList"
          :required="true"
          :is-error="true"
          ref="icon"
          :placeholder="$t('cv.profile.information.contact.add.typePlacholder')"
          :title="$t('cv.profile.information.contact.add.type')"
        />

        <div v-if="icon == 4">
          <customInput
            v-model="type"
            type="text"
            min="2"
            max="255"
            :required="true"
            :is-error="true"
            ref="type"
            :placeholder="
              $t('cv.profile.information.contact.add.socialMediaNamePlacholder')
            "
            :title="$t('cv.profile.information.contact.add.socialMediaName')"
          />
        </div>

        <customInput
          v-if="icon"
          v-model="value"
          :type="isTypeValue"
          :required="true"
          :is-error="true"
          ref="value"
          :placeholder="$t('cv.profile.information.contact.add.contactAddress')"
          :title="
            (icon == 4
              ? $t('cv.profile.information.contact.add.socialMediaName')
              : contactName(icon)) +
            $t('cv.profile.information.contact.add.enter')
          "
        />

        <requiredField />

        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="$t('cv.profile.information.contact.add.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import { contact } from "@/networking/urlmanager.js";

export default {
  name: "contact-add",
  props: ["isEdit", "contactName"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      type: "",
      icon: 0,
      value: "",

      typeList: [
        {
          value: 0,
          name: this.$t("cv.profile.information.contact.add.choose"),
        },
        {
          value: 1,
          name: this.$t("cv.profile.information.contact.add.email"),
        },
        {
          value: 2,
          name: this.$t("cv.profile.information.contact.add.phone"),
        },
        {
          value: 3,
          name: this.$t("cv.profile.information.contact.add.adress"),
        },
        {
          value: 4,
          name: this.$t("cv.profile.information.contact.add.socialMedia"),
        },
        {
          value: 5,
          name: this.$t("cv.profile.information.contact.add.webSite"),
        },
      ],
    };
  },
  computed: {
    isTypeValue() {
      if (this.icon === 1) return "email";
      else if (this.icon === 3 || this.icon === 4) return "text";
      else if (this.icon === 2) return "number";
      else if (this.icon === 5) return "url";
      else return "text";
    },
  },
  methods: {
    show() {
      this.icon = "";
      this.type = "";
      this.value = "";

      this.$modal.show("add-contact");
    },
    hide() {
      this.$modal.hide("add-contact");
    },
    save() {
      this.load = true;

      this.checkErrors();

      if (this.icon && this.value?.trim()) {
        this.$store.commit("addContact", {
          icon: this.icon,
          type: this.icon == 4 ? this.type : this.contactName(this.icon),
          value: this.value,
        });

        this.icon = "";
        this.type = "";
        this.value = "";
        this.successMessage();

        this.load = false;
        this.hide();
      } else {
        this.load = false;
      }
    },

    add() {
      this.load = true;
      this.checkErrors();
      if (this.icon && this.value?.trim()) {
        this.load = true;
        let list = [
          {
            icon: this.icon,
            type: this.icon == 4 ? this.type : this.contactName(this.icon),
            value: this.value,
          },
        ];
        this.axios
          .post(contact.add, {
            cvId: this.$route.params.id,
            list: JSON.stringify(list),
            isCostumAdd: true,
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.$emit("refresh", true);
            this.load = false;
            this.hide();
          })
          .catch((err) => {
            this.authController(err);
            this.load = false;
          });
      } else {
        this.load = false;
      }
    },
    checkErrors() {
      this.$refs.icon.validateInput();
      if (this.icon !== "") {
        if (this.$refs.value) {
          this.$refs.value.validateInput();
        }
        if (this.$refs.type) {
          this.$refs.type.validateInput();
        }
      }
    },
  },
};
</script>
