<!-- Logo ve içerik aynı beyaz kutuda, logo üstte -->

<template>
  <div
    class="min-h-screen bg-[#050C39] p-4 sm:p-6 lg:p-8 flex items-center justify-center"
  >
    <button
      class="absolute top-0 left-0 m-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full flex items-center"
      @click="$router.go(-1)"
    >
      <i class="fas fa-arrow-left"></i>
    </button>
    <div class="max-w-2xl bg-white rounded-lg shadow-lg overflow-hidden">
      <img
        class="h-48 w-48 object-contain mx-auto"
        src="../../assets/global/PolicyLogo.png"
        alt="Politika Resmi"
      />
      <div class="px-8 pb-8">
        <div
          class="uppercase tracking-wide text-sm text-indigo-500 font-semibold"
        >
          {{ $t("privacyPolicy.title") }}
        </div>
        <h1 class="block mt-1 text-lg leading-tight font-semibold text-black">
          {{ $t("privacyPolicy.welcome") }}
        </h1>
        <div
          v-for="(section, index) in $t('privacyPolicy.content')"
          :key="index"
          class="mt-4 text-gray-700"
        >
          <h3 class="font-semibold mb-2">{{ section.title }}</h3>
          <p v-html="section.paragraph"></p>
        </div>
        <p class="mt-4 text-gray-500 text-xs">
          {{ $t("privacyPolicy.lastUpdate") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivacyPolicy",
  data() {
    return {};
  },
  methods: {},
};
</script>
