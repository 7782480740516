<template>
  <div
    @mousedown="
      $refs.filtering.isOpen == true && $refs.filtering.toggleSidebar()
    "
  >
    <div
      class="w-full flex items-center max-sm:text-sm justify-between font-semibold py-3 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 bgcol text-gray-200"
    >
      {{ $t("cvSimilar.index.title") }}
      <img src="@/assets/cvSimilar/compatible.png" alt="" class="w-12" />
    </div>

    <div
      class="my-10 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 relative max-sm:my-8"
    >
      <div class="tableOverflow scroltypey mt-5" data-v-step="1">
        <div class="tableWidth">
          <div class="tableContainer">
            <div class="tableHead">
              <ul class="tableUl">
                <li class="w-1/12">#</li>
                <li class="w-3/12">{{ $t("cvSimilar.index.table.resume") }}</li>
                <li class="w-4/12 max-md:w-3/12">
                  {{ $t("cvSimilar.index.table.position") }}
                </li>
                <li class="w-4/12 max-md:w-5/12 flex justify-end">
                  <div class="w-[300px] tableSearch relative">
                    <input
                      v-model="search"
                      @keypress.enter="getAll()"
                      type="text"
                      class="h-full w-full outline-none pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
                      :placeholder="$t('cvSimilar.index.table.searchText')"
                    />
                    <i
                      class="fa-solid fa-magnifying-glass px-3 textcol absolute right-0"
                    ></i>
                    <button
                      @click="projectFilter = !projectFilter"
                      class="absolute right-6"
                    >
                      <div class="relative group flex justify-center">
                        <img
                          src="@/assets/cv/filteredit.svg"
                          alt=""
                          class="w-5 mr-5 object-contain"
                          data-v-step="2"
                        />
                      </div>
                    </button>
                    <div
                      @mousedown.stop="projectFilter = true"
                      :class="{
                        'invisible opacity-0 transition-all ease-in duration-100':
                          !projectFilter,
                      }"
                      class="w-full z-50 rounded mt-1 px-2 text-xs absolute bg-white top-full border border-gray-300 text-black shadow-md"
                    >
                      <button
                        v-for="(item, index) in projectState"
                        :key="index"
                        @click="filterButton(item)"
                        :class="{ 'text-blue-700': item.active }"
                        class="w-full py-2 border-b border-gray-100 text-left"
                      >
                        {{ item.name }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tableBody scroltype">
              <div class="tableBodyContainer">
                <ul
                  v-for="(item, index) in List"
                  :key="item.id"
                  class="tableBodyUl"
                >
                  <li
                    @click="$router.push('/cv/profile/' + item.id)"
                    class="cursor-pointer select-none w-1/12 py-2.5"
                  >
                    {{ currentPage * 10 + (index + 1) }}
                  </li>
                  <li
                    @click="$router.push('/cv/profile/' + item.id)"
                    class="cursor-pointer select-none w-3/12 py-2.5"
                    id=""
                  >
                    {{ formatFullname(item.fullname) }}
                  </li>
                  <li
                    @click="$router.push('/cv/profile/' + item.id)"
                    class="cursor-pointer select-none w-4/12 max-md:w-3/12 py-2.5 text-justify pr-5"
                  >
                    {{ formatFullname(item.position) }}
                  </li>
                  <li
                    class="w-4/12 max-md:w-5/12 flex gap-6 justify-between px-3 items-center"
                  >
                    <div class="w-5/12">
                      <select
                        data-v-step="3"
                        class="border border-[#182451] rounded"
                        v-if="!item.changeStatus"
                        v-model="item.stateId"
                        @change="(val) => changeStatus(val, item)"
                      >
                        <option value="1">
                          {{
                            $t("cvSimilar.index.table.changeStatusList.active")
                          }}
                        </option>
                        <option value="3">
                          {{
                            $t(
                              "cvSimilar.index.table.changeStatusList.favorite"
                            )
                          }}
                        </option>
                        <option value="4">
                          {{
                            $t(
                              "cvSimilar.index.table.changeStatusList.unsuitable"
                            )
                          }}
                        </option>
                        <option value="2">
                          {{
                            $t("cvSimilar.index.table.changeStatusList.delete")
                          }}
                        </option>
                      </select>
                      <div
                        v-else
                        class="flex items-center gap-1 text-sm w-full"
                      >
                        <i class="fas fa-spinner fa-spin"></i>
                        <label class="text-sm"
                          >{{ $t("cvSimilar.index.loadText") }}
                        </label>
                      </div>
                    </div>
                    <p class="w-7/12">{{ item.addDate | getDate }}</p>
                    <div class="relative group flex justify-center">
                      <router-link
                        :to="'/cv/profile/' + item.id"
                        class="block h-full"
                        data-v-step="4"
                      >
                        <img
                          class="w-5"
                          src="@/assets/cv/document-icon.svg"
                          alt=""
                        />
                      </router-link>
                      <tooltip
                        :title="$t('cvSimilar.index.toolTip')"
                        tipClass="z-50 w-[160px] !-left-[120px] !top-6 "
                        rotateClass="ml-[100px]"
                        :top="true"
                      />
                    </div>
                  </li>
                </ul>
                <tableLoader :load="load" :length="List.length" colspan="7" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <tour
        :steps="steps"
        :moduleId="2"
        v-if="!$store.state.userData.tourModuleId.includes(2)"
      />
      <pagination
        v-model="currentPage"
        :pageCount="pageCount"
        :totalRowCount="totalCount"
      />
    </div>
  </div>
</template>

<script>
import tableLoader from "@/components/table-loader.vue";
import pagination from "@/components/pagination.vue";

import moment from "moment";
import "moment/locale/tr";
import { cv } from "@/networking/urlmanager";
import tour from "@/components/tour.vue";
import tooltip from "@/components/tooltip.vue";
export default {
  name: "smiiler-cv-page",
  components: {
    tableLoader,
    pagination,
    tour,
    tooltip,
  },
  data() {
    return {
      List: [],
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,
      rowId: "",

      stateId: "",
    };
  },
  methods: {
    formatFullname(fullname) {
      const words = fullname.split(" ");
      const modifiedWords = words.map((word) => {
        const lowerCaseWord = word.toLowerCase();
        return lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
      });
      return modifiedWords.join(" ");
    },
    getAll() {
      this.load = true;
      this.List = [];
      this.axios
        .get(
          cv.getAll +
            "?page=" +
            this.currentPage +
            "&search=" +
            this.search +
            "&stateId=" +
            this.stateId
        )
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;
          this.List = detail.map((r) => {
            return {
              ...r,
              changeStatus: false,
            };
          });
          this.load = false;
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getAll();
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return this.$t("cvSimilar.index.date");
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
