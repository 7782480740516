<template>
  <modal
    name="editUser"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '35%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">{{ $t("user.components.edit.title") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form @submit.prevent="edit()" class="p-5">
      <div class="grid grid-col-1 gap-y-3">
        <div class="flex justify-between items-center gap-x-2">
          <customInput
            v-model="firstName"
            type="text"
            min="3"
            max="40"
            :required="true"
            :title="$t('user.components.edit.table.name')"
          />
          <customInput
            v-model="lastName"
            type="text"
            min="3"
            max="40"
            :required="true"
            :title="$t('user.components.edit.table.surname')"
          />
        </div>
        <customInput
          v-model="email"
          type="email"
          min="3"
          max="40"
          :required="true"
          :title="$t('user.components.edit.table.email')"
        />

        <customInput
          v-model="authority"
          type="select"
          :selectList="[
            {
              value: 1,
              name: $t('user.components.edit.table.authorityList.executive'),
            },
            {
              value: 2,
              name: $t('user.components.edit.table.authorityList.user'),
            },
          ]"
          :required="true"
          :placeholder="$t('user.components.edit.table.authority')"
          :title="$t('user.components.edit.table.authority')"
        />

        <requiredField />
        <buttonItem :load="load" buttonClass="w-full" />
      </div>
    </form>
  </modal>
</template>

<script>
// global components
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import moment from "moment";
import "moment/locale/tr";
import { users } from "@/networking/urlmanager";

export default {
  name: "editUser",
  props: ["detail"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,

      id: "",
      firstName: "",
      lastName: "",
      email: "",
      authority: " ",
      load: false,
      authBack: "",
    };
  },
  methods: {
    show() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      (this.authority = 0), (this.authBack = 0);
      setTimeout(() => {
        (this.id = this.detail.id),
          (this.firstName = this.detail.firstName),
          (this.lastName = this.detail.lastName),
          (this.email = this.detail.email);
        this.authority = this.detail.authority;
        this.authBack = this.detail.authority;
      }, 50);
      this.$modal.show("editUser");
    },
    hide() {
      this.$modal.hide("editUser");
    },
    edit() {
      this.load = true;
      this.axios
        .post(
          users.edit,
          {
            rowId: this.detail.id,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            authority: this.authority,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.successMessage(res.data.message);
          this.load = false;
          this.$emit("refresh", true);
          this.hide();
        })
        .catch((err) => {
          console.error(err);
          this.authController(err);
          this.load = false;
        });
    },
  },
  mount() {
    this.show();
  },
  watch: {
    endDate(val) {
      this.endDate = val ? moment(val).format("YYYY-MM-DD") : null;
    },
  },
};
</script>

<style></style>
