var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("external"),_c('div',{staticClass:"w-full bg-black z-[998] fixed h-screen top-0 left-0 transition-all ease-out",class:[
      'bg-opacity-0 invisible ',
      _vm.isOpen ? '!bg-opacity-20 !visible' : '',
      _vm.externalClass,
    ],on:{"mousedown":_vm.close}},[_c('div',{staticClass:"bg-white p-4 shadow-md",class:[
        'sidebar z-[998]',
        _vm.isOpen ? 'sidebar-open' : '',
        _vm.modalClass,
      ],on:{"mousedown":function($event){$event.stopPropagation();return _vm.show.apply(null, arguments)}}},[(_vm.isHeader)?_c('div',{staticClass:"flex justify-between items-center p-4 border-b border-gray-200"},[_c('button',{staticClass:"flex items-center",on:{"click":function($event){return _vm.close()}}},[_c('i',{staticClass:"fas fa-times fa-2x text-[#344CA0] text-2xl"})]),_c('h2',{staticClass:"text-lg font-bold text-[#344CA0]"},[_vm._v(" "+_vm._s(_vm.headerTitle)+" ")]),(_vm.isRefresh)?_c('button',{staticClass:"flex items-center",on:{"click":function($event){return _vm.$emit('refresh',true)}}},[_c('i',{staticClass:"fa-solid fa-arrows-rotate text-[#344CA0] text-xl"})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"scroltype h-full",class:_vm.hidenOverflowY ? '' : 'overflow-y-auto'},[_vm._t("content")],2)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }