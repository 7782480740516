<template>
  <sideModal ref="historyModal" modalClass="w-5/12 max-xl:w-10/12 max-sm:w-full h-full" :isHeader="true"
    :headerTitle="$t('project.detail.interviewQuestions')">
    <template v-slot:content>

      <div class="bg-white pt-4 pb-16 flex flex-col w-full h-full justify-between">
        <alert :key="scoreCalculate" class="mb-2" :type="scoreCalculate === 100 ? 'success' : 'danger'"
          :message="$t('project.detail.preInterviewDetail.alertTotalScore', { score: scoreCalculate })"
          v-if="Questions.length" />

        <div class="flex items-center justify-between px-2" :class="!items.length && 'opacity-50'">
          <Checkbox :checked="!!items.length && allSelected" :disabled="!items.length" :toggle="toggleSelectAll"
            :label="selectAllLabel" />

          <div class="text-sm flex gap-5 items-center">
            <span>
              {{ selectedItemsLabel }}
            </span>

            <template v-if="selectedItems.length">
              <button @click="deleteAction">
                <i class="text-lg text-red-600 hover:text-red-900 fas fa-trash" />
              </button>

              <!-- More Actions -->
            </template>
          </div>
        </div>

        <div v-if="items.length" class="h-full w-full overflow-y-auto flex flex-col gap-2">
          <InterviewItem :ref="el => {
            itemsRef[`InterviewItem` + index] = el
            }" v-for="(item, index) in items" :key="index"
            :item="item" :index="index" :isOpen="isOpen[index]" :toggleCollapse="() => toggleCollapse(index)"
            :toggleSelect="() => toggleSelect(index)" :saveQuestion="saveQuestion" />
        </div>

        <div class="w-full h-full flex flex-col items-center jsutify-center text-sm text-gray-500 py-56 gap-5" v-else>
          <i v-if="!loading" class="fas fa-user-slash text-7xl text-gray-300" />
          <i v-else class="text-7xl text-gray-400 fas fa-spinner animate-spin" />
          {{ !loading ? $t("project.detail.candidateNotSelected") : "" }}
        </div>

        <ButtonItem class="w-full" @click="handlePrepareInterview" :text="$t('project.detail.startInterview')"
          :loadText="$t('project.detail.interviewLinkSending')" :load="interviewLoad"
          :disabled="!selectedItems.length || scoreCalculate !== 100" />
      </div>
    </template>
  </sideModal>
</template>

<script>
import sideModal from "@/components/sideModal.vue";
import ButtonItem from "@/components/button.vue";
import InterviewItem from "./components/interviewItem.vue";
import Checkbox from "./components/interviewCheckbox.vue";
import { projectInterview } from "@/networking/urlmanager";

export default {
  name: "interview-panel",
  components: {
    sideModal,
    ButtonItem,
    InterviewItem,
    Checkbox,
  },
  data() {
    return {
      isOpen: [],
      loading: false,
      interviewLoad: false,
      items: [],
      itemsRef: [],
      currentPage: 0,
      search: "",
      Questions: [],
    };
  },
  computed: {
    scoreCalculate() {
      return this.Questions.reduce((acc, item) => {
        return Number(acc) + Number(item.weightedScore);
      }, 0);
    },
    selectedItems() {
      return this.items.filter((item) => item.selected);
    },
    allSelected() {
      return this.items.every((item) => {
        if (item.stateId === 2) return true;

        return item.selected;
      });
    },
    selectAllLabel() {
      return this.allSelected && this.items.length
        ? this.$t("project.detail.deselectAll")
        : this.$t("project.detail.selectAll")
    },
    selectedItemsLabel() {
      const count = this.selectedItems.length;
      const totalCount = this.items.length;
      return count ? `${count} ${this.$t('project.detail.personSelected')}` : `${totalCount} ${this.$t('project.detail.person')}`;
    },
  },
  methods: {
    async getAllOtherQuestions() {
      const response = await this.axios.get(projectInterview.getAll, {
        params: {
          page: this.currentPage,
          search: this.search,
          projectId: this.$route.query.id,
        },
      })
      const { data } = response.data.data;

      this.Questions = data;
    },
    async getAll() {
      let refreshMS = 1000;

      try {
        const res = await this.axios.get(projectInterview.getCandidateQuesion, {
          params: {
            projectId: this.$route.query.id,
          },
        });
        this.items = res.data.data.map((r) => {
          return {
            ...r,
            selected: false,
            questions: this.listFormatController(r.questions),
          };
        });
      } catch (err) {
        console.log(err);
        this.authController(err);
      } finally {
        // this.$emit("refresh", true);
      }

      if (this.items.every((item) => item.stateId === 2) && this.items.length)
        setTimeout(this.getAll, refreshMS * 2);
    },
    async toggleSidebar() {
      this.$refs.historyModal.show();

      this.loading = true;
      Promise.all([this.getAll(), this.getAllOtherQuestions()]).then(() => {
        console.log('thisQuestions', this.Questions)
        console.log('this.items', this.items)
      }).catch((err) => {
        console.error(err);
        this.authController(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    toggleCollapse(index) {
      if (this.option) return;
      this.$set(this.isOpen, index, !this.isOpen[index]);
      this.isOpen.forEach((_item, i) => {
        if (i !== index) {
          this.$set(this.isOpen, i, false);
        }
      });
    },
    toggleSelect(index) {
      this.$set(this.items[index], "selected", !this.items[index].selected);
    },
    toggleSelectAll() {
      const allSelected = this.allSelected;
      this.items.map((item) => {
        if (item.stateId === 2) return;
        item.selected = !allSelected;
      });
    },
    async handlePrepareInterview() {
      const selectedItems = this.selectedItems;
      this.interviewLoad = true;
      try {
        const res = await this.axios.post(projectInterview.mail, {
          list: JSON.stringify(selectedItems),
          projectId: this.$route.query.id,
        });
      } catch (err) {
        console.error(err);
        this.authController(err);
      } finally {
        this.getAll();
        this.$emit("refresh", true);
        this.interviewLoad = false;
      }
    },
    async deleteAction() {
      this.loading = true;
      await this.axios
        .post(projectInterview.deleteAction, {
          list: JSON.stringify(this.selectedItems),
          projectId: this.$route.query.id,
        })
        .then(() => {
          this.getAll();
        })
        .catch((err) => {
          console.error(err);
          this.authController(err);
        })
        .finally(() => {
          this.$emit("refresh", true);
          this.loading = false;
        });
    },
    async saveQuestion(index, questionIndex, editedQuestion) {
      let result;
      const data = this.items[index].questions.find(
        (question) => question.id === questionIndex
      );

      try {
        this.loading = true;
        const res = await this.axios.post(projectInterview.edit, {
          projectId: this.items[index].projectId,
          question: editedQuestion?.name,
          rowId: data.id,
          questionState: 1,
          expectedAnswer: 1,
          isEditQuestion: 1,
        });
        result = res.data;
      } catch (err) {
        console.error(err);
        this.authController(err);
        throw err;
      } finally {
        this.$emit("refresh", true);
        this.loading = false;
      }

      return result;
    },
  },
  watch: {
    items: {
      handler() {
        this.items.map((_item, index) => {
          this.itemsRef[`InterviewItem` + index].updateHeight();
        })
        console.log('this.itemsRef', )
      },
      deep: true,
    },
  }
};
</script>