var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex gap-5 py-8 border-b border-gray-200 max-md:flex-wrap",class:_vm.type == 'monthInput' ? '!py-0' : ''},[(_vm.type != 'monthInput')?_c('div',{staticClass:"w-6/12 max-md:w-full"},[_c('h1',{staticClass:"text-base text-gray-700 font-semibold"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"text-xs text-gray-700 mt-2"},[_vm._v(_vm._s(_vm.description))])]):_vm._e(),_c('div',{staticClass:"w-6/12 h-full flex items-center max-md:w-full",class:_vm.type == 'monthInput' ? '!w-full' : ''},[((_vm.type)==='checkbox'&&(
        _vm.type != 'textarea' && _vm.type != 'monthInput' && _vm.type != 'phoneNumber'
      ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"w-full border bordercol rounded py-3 outline-none pl-3 text-xs",attrs:{"disabled":_vm.disabled,"required":_vm.required,"readonly":_vm.readonly,"min":_vm.min,"max":_vm.max,"minlength":_vm.min,"maxlength":_vm.max,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,null)>-1:(_vm.selected)},on:{"change":[function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}},(r) => _vm.type == 'file' && _vm.$emit('change', r)]}}):((_vm.type)==='radio'&&(
        _vm.type != 'textarea' && _vm.type != 'monthInput' && _vm.type != 'phoneNumber'
      ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"w-full border bordercol rounded py-3 outline-none pl-3 text-xs",attrs:{"disabled":_vm.disabled,"required":_vm.required,"readonly":_vm.readonly,"min":_vm.min,"max":_vm.max,"minlength":_vm.min,"maxlength":_vm.max,"type":"radio"},domProps:{"checked":_vm._q(_vm.selected,null)},on:{"change":[function($event){_vm.selected=null},(r) => _vm.type == 'file' && _vm.$emit('change', r)]}}):(
        _vm.type != 'textarea' && _vm.type != 'monthInput' && _vm.type != 'phoneNumber'
      )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"w-full border bordercol rounded py-3 outline-none pl-3 text-xs",attrs:{"disabled":_vm.disabled,"required":_vm.required,"readonly":_vm.readonly,"min":_vm.min,"max":_vm.max,"minlength":_vm.min,"maxlength":_vm.max,"type":_vm.type},domProps:{"value":(_vm.selected)},on:{"change":(r) => _vm.type == 'file' && _vm.$emit('change', r),"input":function($event){if($event.target.composing)return;_vm.selected=$event.target.value}}}):(_vm.type == 'textarea')?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"w-full border bordercol rounded py-3 outline-none pl-3 text-xs px-2",class:_vm.textareaClass,attrs:{"type":_vm.type,"required":_vm.required,"readonly":_vm.readonly,"rows":"2"},domProps:{"value":(_vm.selected)},on:{"input":function($event){if($event.target.composing)return;_vm.selected=$event.target.value}}}):_vm._e(),(_vm.type == 'phoneNumber')?_c('VuePhoneNumberInput',{staticClass:"w-full flex-grow",attrs:{"required":_vm.required,"type":"number","color":"#182451","translations":{
        countrySelectorLabel: _vm.$t(
          'settings.contact.index.phoneInput.countrySelectorLabel'
        ),
        countrySelectorError: _vm.$t(
          'settings.contact.index.phoneInput.countrySelectorError'
        ),
        phoneNumberLabel: _vm.$t(
          'settings.contact.index.phoneInput.phoneNumberLabel'
        ),
        example: _vm.$t('settings.contact.index.phoneInput.example'),
      }},on:{"update":(value) => _vm.$emit('change', value)},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.type == 'monthInput')?_c('div',{staticClass:"relative !w-full flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"w-full border bordercol rounded py-3 pl-3 text-xs outline-none flex-grow",attrs:{"type":"number","min":_vm.min,"max":_vm.max,"required":_vm.required},domProps:{"value":(_vm.selected)},on:{"input":function($event){if($event.target.composing)return;_vm.selected=$event.target.value}}}),_c('span',{staticClass:"absolute right-6 top-1/2 transform -translate-y-1/2 text-sm"},[_vm._v(_vm._s(_vm.$t("settings.general.index.cvDurationMonth")))])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }