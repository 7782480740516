<template>
  <modal
    name="edit-project-publications"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.projectsPublications.components.edit.edit") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form
      @submit.prevent="isEdit ? add() : save()"
      class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
    >
      <div class="space-y-4 pb-3">
        <customInput
          v-model="name"
          type="text"
          min="2"
          max="255"
          :required="true"
          :is-error="true"
          ref="nameInput"
          :placeholder="
            $t(
              'cv.profile.projectsPublications.components.edit.projectPlacholder'
            )
          "
          :title="$t('cv.profile.projectsPublications.components.edit.project')"
        />

        <customInput
          v-model="url"
          type="url"
          :placeholder="
            $t(
              'cv.profile.projectsPublications.components.edit.projectUrlPLacholder'
            )
          "
          :title="
            $t('cv.profile.projectsPublications.components.edit.projectUrl')
          "
        />

        <customInput
          v-model="date"
          type="date"
          :placeholder="
            $t('cv.profile.projectsPublications.components.edit.dataPlacholder')
          "
          :title="$t('cv.profile.projectsPublications.components.edit.data')"
        />

        <customInput
          v-model="description"
          type="textarea"
          :required="true"
          :is-error="true"
          ref="textAreaRef"
          max="500"
          :placeholder="
            $t(
              'cv.profile.projectsPublications.components.edit.explanationPlacholder'
            )
          "
          :title="
            $t('cv.profile.projectsPublications.components.edit.explanation')
          "
          :rows="4"
        />

        <requiredField />
        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="$t('cv.profile.projectsPublications.components.edit.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import moment from "moment";
import requiredField from "@/components/requiredField.vue";

import { projectsPublications } from "@/networking/urlmanager.js";

export default {
  name: "addProject",
  props: ["isEdit", "Detail", "index"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      rowId: "",
      name: "",
      project: "",
      url: "",
      description: "",
      date: "",

      formErrors: {
        name: null,
        description: null,
      },
    };
  },
  methods: {
    show() {
      setTimeout(() => {
        this.rowId = this.Detail.id;
        this.name = this.Detail.name;
        this.url = this.Detail.url;
        this.description = this.Detail.description;

        if (this.Detail.formatDate) {
          this.date = moment(this.Detail.date).format("YYYY-MM-DD");
        }
      }, 50);
      this.$modal.show("edit-project-publications");
    },
    hide() {
      this.$modal.hide("edit-project-publications");
    },
    checkErrors() {
      this.$refs.textAreaRef.validateInput();
      this.$refs.nameInput.validateInput();
    },
    save() {
      this.load = true;

      this.checkErrors();

      if (this.name?.trim() && this.description?.trim()) {
        this.$emit("loadChance", true);
        this.$store.commit("editProject", {
          index: this.index,
          list: {
            name: this.name,
            description: this.description,
            url: this.url,
            date: this.dateFormatter(this.date),
            formatDate: this.dateFormatter(this.date),
          },
        });

        this.project = "";
        this.description = "";
        this.url = "";
        this.date = "";

        if (!this.isEdit) {
          this.successMessage();
        }

        this.load = false;
        this.$emit("loadChance", true);

        if (this.isEdit) {
          this.$emit("refresh", true);
        }
        this.hide();
      } else {
        this.load = false;
      }
    },
    add() {
      this.load = true;

      this.checkErrors();

      if (this.name?.trim() && this.description?.trim()) {
        let list = [
          {
            name: this.name,
            description: this.description,
            url: this.url,
            date: this.dateFormatter(this.date),
            formatDate: this.dateFormatter(this.date),
          },
        ];
        this.axios
          .post(projectsPublications.edit, {
            cvId: this.$route.params.id,
            list: JSON.stringify(list),
            rowId: this.rowId,
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.save();
            this.load = false;
            this.hide();
          })
          .catch((err) => {
            this.authController(err);
            this.load = false;
          });
      } else {
        this.load = false;
      }
    },
    dateFormatter(val) {
      return moment(val).format("YYYY.MM.DD");
    },
  },
  watch: {
    name() {
      this.formErrors.name = this.name.trim() === "" ? true : null;
    },
    description() {
      this.formErrors.description =
        this.description.trim() === "" ? true : null;
    },
  },
};
</script>
