<template>
  <div>
    <section class="px-20 -z-1" ref="pdfContent" v-show="contentVisible"></section>
  </div>
</template>

<script>
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import axios from "axios";
const titleFontSize = 13;
import { settingGeneral } from "@/networking/urlmanager";

const types = {
  personalInformation: 0,
  fullname: 1,
  position: 10,
  biography: 11,
  schoolAndEducation: 2,
  workExperience: 3,
  languages: 4,
  computerSkills: 5,
  skills: 6,
  seminarAndCourse: 7,
  projectsPublications: 8,
  contactInformation: 9,
  backgroundImg: null,
};

export default {
  name: "cv-profile-page",
  props: ["hiddenList", "isLanguage"],
  data() {
    return {
      contentVisible: false,
      currentYPosition: 730,
      activePage: null,
      pdfDoc: null,
      pageWidth: 0,
      pageHeight: 0,
      backgroundImage: null,

      contentGap: 30,
    };
  },
  methods: {
    async headerItem() {
      const pdfDoc = await PDFDocument.create();
      pdfDoc.registerFontkit(fontkit);
      const fontPath = require("@/assets/font/Roboto-Regular.ttf");
      const fontBytes = await axios
        .get(fontPath, { responseType: "arraybuffer" })
        .then((res) => res.data);

      const font = await pdfDoc.embedFont(fontBytes);
      return await { pdfDoc, font };
    },
    async addPageBackround(pdfDoc) {
      pdfDoc.addPage();
      const pageWidth = pdfDoc.getPages()[pdfDoc.getPageCount() - 1].getWidth();
      const pageHeight = pdfDoc
        .getPages()
      [pdfDoc.getPageCount() - 1].getHeight();

      const imageUrl = await this.axios(settingGeneral.getFormatFile);
      const imageResponse = await fetch(imageUrl.data.data);
      const contentType = imageResponse.headers.get("content-type");
      const backgroundImgUint8Array = new Uint8Array(
        await imageResponse.arrayBuffer()
      );

      let backgroundImage;
      if (contentType == "image/png")
        backgroundImage = await pdfDoc.embedPng(backgroundImgUint8Array);
      else if (contentType == "image/jpg" || contentType == "image/jpeg")
        backgroundImage = await pdfDoc.embedJpg(backgroundImgUint8Array);
      else {
        this.warningMesage(this.$t("cv.profile.export.index.warningMessage"));
        throw this.$t("cv.profile.export.index.warningMessageText");
      }

      const page = pdfDoc.getPages()[pdfDoc.getPageCount() - 1];
      page.drawImage(backgroundImage, {
        x: 0,
        y: 0,
        width: pageWidth,
        height: pageHeight,
      });

      return await { page, pageWidth, pageHeight, backgroundImage };
    },
    async generatePDF() {
      try {
        
        this.$emit("load", true);
        this.currentYPosition = 730;
        const { pdfDoc, font } = await this.headerItem();

        this.pdfDoc = pdfDoc;
        const { page, pageWidth, pageHeight, backgroundImage } = await this.addPageBackround(this.pdfDoc);
        this.activePage = page;
        this.pageWidth = pageWidth;
        this.pageHeight = pageHeight;
        this.backgroundImage = backgroundImage;

        const { fullname, biography, position, schoolAndEducation, workexperience, languages, skills, computerSkills, seminarAndCourse, projectsPublications, contactInformation, city, country, dateBirth, placeBirth, driverLicense, gender } = this.$store.state.extractDetail;

        if (fullname && !this.hiddenControl(types.fullname)) {
          this.addFullname(fullname, font);
          this.currentYPosition -= 15;
        }
        if (position && !this.hiddenControl(types.position)) {
          this.addPosition(position, font);
          this.currentYPosition -= 20;
        }
        if (biography && !this.hiddenControl(types.biography)) {
          this.addBiography(biography, font);
        }
        if ((city || country || dateBirth || placeBirth || driverLicense || gender) && !this.hiddenControl(types.personalInformation)) {
          this.currentYPosition -= this.contentGap;
          this.makePersonalInformation(font, { city, country, dateBirth, placeBirth, driverLicense, gender });
        }
        if (schoolAndEducation?.length > 0 && !this.hiddenControl(types.schoolAndEducation)) {
          this.currentYPosition -= this.contentGap;
          this.makeEducation(schoolAndEducation, font);
        }
        if (workexperience?.length > 0 && !this.hiddenControl(types.workExperience)) {
          this.currentYPosition -= this.contentGap;
          this.makeProfessionalExperience(workexperience, font);
        }
        if (languages?.length > 0 && !this.hiddenControl(types.languages)) {
          this.currentYPosition -= this.contentGap;
          this.makeLanguages(languages, font);
        }
        if (skills?.length > 0 && !this.hiddenControl(types.skills)) {
          this.currentYPosition -= this.contentGap;
          this.makeSkills(skills, font);
        }
        if (computerSkills?.length > 0 && !this.hiddenControl(types.computerSkills)) {
          this.currentYPosition -= this.contentGap;
          this.makeComputerSkills(computerSkills, font);
        }
        if (seminarAndCourse?.length > 0 && !this.hiddenControl(types.seminarAndCourse)) {
          this.currentYPosition -= this.contentGap;
          this.makeSeminarAndCourses(seminarAndCourse, font);
        }
        if (projectsPublications?.length > 0 && !this.hiddenControl(types.projectsPublications)) {
          this.currentYPosition -= this.contentGap;
          this.makeProjectsPublications(projectsPublications, font);
        }
        if (contactInformation?.length > 0 && !this.hiddenControl(types.contactInformation)) {
          this.currentYPosition -= this.contentGap;
          this.makeContactInformations(contactInformation, font);
        }

        const newPdfBytes = await this.pdfDoc.save();
        const blob = new Blob([newPdfBytes], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fullname + ".pdf";
        link.click();
        this.$emit("load", false);

      } catch (error) {
        this.$emit("load", false);
      }
    },
    wrapText(text, maxWidth, font, fontSize) {
      const cleanedText = text ? text.toString().replace(/\s+/g, " ").trim() : "";
      const words = cleanedText.split(" ");
      const lines = [];
      let line = "";

      words.forEach((word) => {
        const testLine = line + " " + word;
        const testLineWidth = font.widthOfTextAtSize(testLine, fontSize);

        if (testLineWidth > maxWidth && line.length > 0) {
          lines.push(line);
          line = word;
        } else {
          line = testLine.trim();
        }
      });

      if (line.length > 0) {
        lines.push(line);
      }

      return lines;
    },
    hiddenControl(val) {
      return this.hiddenList.filter((r) => r == val).length > 0;
    },
    addFullname(list, font) {
      this.activePage.drawText(list ? list.trim() : "", {
        x:
          (this.pageWidth - font.widthOfTextAtSize(list.toUpperCase(), 16)) / 2,
        y: this.currentYPosition,
        size: 16,
        color: rgb(0, 0, 0),
        font,
      });
    },
    addPosition(list, font) {
      this.activePage.drawText(list ? list.trim() : "", {
        x: (this.pageWidth - font.widthOfTextAtSize(list.toUpperCase(), 8)) / 2,
        y: this.currentYPosition,
        size: 8,
        color: rgb(0, 0, 0),
        font,
      });
    },
    addBiography(list, font) {
      const lines = this.wrapText(list, 500, font, 8);
      lines.forEach((line) => {

        this.checkPageHeight(120, 120)

        this.activePage.drawText(line ? line : "", {
          x: 50,
          y: this.currentYPosition,
          size: 8,
          color: rgb(0, 0, 0),
          font,
        });
        this.currentYPosition -= 15;
      });
    },
    addHeaderInSection(title, font) {
      this.activePage.drawText(title, {
        x: 50,
        y: this.currentYPosition,
        size: titleFontSize,
        color: rgb(0, 0, 0),
        font,
      });
      this.currentYPosition -= 3;
      this.activePage.drawLine({
        start: {
          x: 50,
          y: this.currentYPosition,
          color: rgb(0, 0, 0),
        },
        end: {
          x: 550,
          y: this.currentYPosition,
          color: rgb(0, 0, 0),
        },
      });
      this.currentYPosition -= 25;
    },
    // sections
    makeEducation(list, font) {
      this.addHeaderInSection(this.isLanguage == 1 ? "Eğitim Bilgileri" : "Education", font);

      list.forEach((item, index) => {
        if (item.name) {

          this.checkPageHeight()

          this.activePage.drawText(
            item.duration ? item.duration.replace("to", "-") : "          -",
            {
              x: 70,
              y: this.currentYPosition,
              size: 10,
              color: rgb(0, 0, 0),
              font,
            }
          );
          let pureName = "";
          if (item.status) {
            pureName = item.status.toString().toLowerCase();
          }
          let degree = "";
          if (!pureName) degree = "     -";
          else if (pureName == 5) degree = "Doktora";
          else if (pureName == 4) degree = "Yüksek Lisans";
          else if (pureName == 3) degree = "Lisans";
          else if (pureName == 2) degree = "Ön Lisans";
          else if (pureName == 1) degree = "Lise";
          this.activePage.drawText(degree, {
            x: 210,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });
          let name = this.wrapText(item.name + " - " + item.department, 200, font, 10);
          name.forEach((line) => {
            this.activePage.drawText(line, {
              x: 325,
              y: this.currentYPosition,
              size: 10,
              color: rgb(0, 0, 0),
              font,
            });
            this.currentYPosition -= 15;
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 25;
          }
        }
      });
    },
    async makeProfessionalExperience(list, font) {
      // add Title
      this.addHeaderInSection(this.isLanguage == 1 ? "İş Deneyimi" : "Professional Experiences", font);

      list.forEach((item, index) => {
        this.checkPageHeight()

        this.activePage.drawText(
          item.company ? item.company : "Company name not found",
          {
            x: 70,
            y: this.currentYPosition - 10,
            size: 11,
            color: rgb(0, 0, 0),
            font,
          }
        );

        // Pozisyon ve süre
        this.currentYPosition -= 20;
        this.activePage.drawText(
          (item.position ? item.position : "Position not specified") +
          (item.duration ? "  [ " + item.duration + " ]" : ""),
          {
            x: 70,
            y: this.currentYPosition - 5,
            size: 9,
            color: rgb(0, 0, 0),
            font,
          }
        );

        const descriptionLines = this.wrapText(item.description, 470, font, 8);
        if (descriptionLines.length > 0) {
          this.currentYPosition -= 25;
        }

        // Açıklama
        descriptionLines.forEach((line, index) => {

          this.checkPageHeight(null, 120)

          //Sayfadaki konumu
          this.activePage.drawText(line ? line : "", {
            x: 70,
            y: this.currentYPosition,
            size: 8,
            color: rgb(0, 0, 0),
            font,
          });
          //currentLineHeight = Math.max(currentLineHeight, (lineIndex + 1) * 15);

          this.currentYPosition -= 13;
        });

        if (list.length != index + 1 && descriptionLines.length == 0) {
          this.currentYPosition -= 25;
        } else {
          this.currentYPosition -= 8;
        }
        //this.currentYPosition -= currentLineHeight + 15;
      });
    },
    makePersonalInformation(font, data) {
      this.addHeaderInSection(this.isLanguage == 1 ? "Kişisel Bilgiler " : "Personal Information", font);
      this.checkPageHeight(null, 120)

      const adress = `${this.isLanguage == 1 ? 'Adres' : 'Address'} : ${data.country + ' / ' + data.city || '-'}`
      this.activePage.drawText(adress, {
        x: 70,
        y: this.currentYPosition,
        size: 10,
        color: rgb(0, 0, 0),
        font,
      });

      const dateBirth = `${this.isLanguage == 1 ? 'Doğum Tarihi' : 'Date Birth'} : ${data?.dateBirth ? data?.dateBirth : ' - '}`
      this.activePage.drawText(dateBirth, {
        x: (this.pageWidth - font.widthOfTextAtSize(dateBirth.toUpperCase(), 10)) / 2,
        y: this.currentYPosition,
        size: 10,
        color: rgb(0, 0, 0),
        font,
      });

      const driverLicense = `${this.isLanguage == 1 ? 'Ehliyet' : 'Driver License'} : ${data.driverLicense ? this.isLanguage == 1 ? (data.driverLicense == 2 ? "Var" : "Yok") : (data.driverLicense == 2 ? "Yes" : "No") : "-"}`
      this.activePage.drawText(driverLicense, {
        x: (this.pageWidth - font.widthOfTextAtSize(driverLicense.toUpperCase(), 10)) / 1 - 70,
        y: this.currentYPosition,
        size: 10,
        color: rgb(0, 0, 0),
        font,
      });

      this.currentYPosition -= 30;
      const gender = `${this.isLanguage == 1 ? 'Cinsiyet' : 'Gender'} : ${data.gender ? (this.isLanguage == 1 ? (data.gender == 1 ? "Erkek" : "Kadın") : (data.gender == 1 ? "Man" : "Woman")) : "-"}`
      this.activePage.drawText(gender, {
        x: (this.pageWidth - font.widthOfTextAtSize(gender.toUpperCase(), 10)) / 2 - 90,
        y: this.currentYPosition,
        size: 10,
        color: rgb(0, 0, 0),
        font,
      });

      const placeBirth = `${this.isLanguage == 1 ? 'Doğum yeri' : 'PlaceBirth'} : ${data.placeBirth || "-"}`
      this.activePage.drawText(placeBirth, {
        x: (this.pageWidth - font.widthOfTextAtSize(placeBirth.toUpperCase(), 10)) / 2 + 90,
        y: this.currentYPosition,
        size: 10,
        color: rgb(0, 0, 0),
        font,
      });
    },
    makeLanguages(list, font) {
      this.checkPageHeight()
      this.addHeaderInSection(this.isLanguage == 1 ? "Dil Becerileri" : "Languages", font);
      list.forEach((item, index) => {
        if (item.name) {
          this.checkPageHeight()
          this.activePage.drawText(item.name, {
            x: 70,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });
          if (!item.level) item.level = "-";
          else if (item.level == 4) item.level = "Ana Dili Seviyesi";
          else if (item.level == 3) item.level = "İleri Seviye";
          else if (item.level == 2) item.level = "Orta Seviye";
          else if (item.level == 1) item.level = "Başlangıç Seviyesi";

          this.activePage.drawText(item.level, {
            x: 300,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });
          if (list.length != index + 1) {
            this.currentYPosition -= 15;
          }
        }
      });
    },
    makeSkills(list, font) {
      this.checkPageHeight()
      this.addHeaderInSection(this.isLanguage == 1 ? "Yetenekler" : "Skills", font);
      list.forEach((item, index) => {
        if (item.name) {
          this.checkPageHeight()

          if (!item.level) item.level = "-";
          else if (item.level == 3) item.level = "İleri Seviye";
          else if (item.level == 2) item.level = "Orta Seviye";
          else if (item.level == 1) item.level = "Başlangıç Seviyesi";

          this.activePage.drawText(item.level, {
            x: 300,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });

          let name = this.wrapText(item.name, 200, font, 10);
          name.forEach((line) => {
            this.activePage.drawText(line, {
              x: 70,
              y: this.currentYPosition,
              size: 10,
              color: rgb(0, 0, 0),
              font,
            });
            this.currentYPosition -= 15;
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 10;
          }
        }
      });
    },
    makeComputerSkills(list, font) {

      this.checkPageHeight()

      // add Title
      this.addHeaderInSection(
        this.isLanguage == 1 ? "Bilgisayar Becerileri" : "Computer Skills",
        font
      );
      list.forEach((item, index) => {
        if (item.name) {

          this.checkPageHeight()

          if (!item.level) item.level = "-";
          else if (item.level == 3) item.level = "İleri Seviye";
          else if (item.level == 2) item.level = "Orta Seviye";
          else if (item.level == 1) item.level = "Başlangıç Seviyesi";

          this.activePage.drawText(item.level, {
            x: 300,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });
          let name = this.wrapText(item.name, 200, font, 10);
          name.forEach((line) => {
            this.activePage.drawText(line, {
              x: 70,
              y: this.currentYPosition,
              size: 10,
              color: rgb(0, 0, 0),
              font,
            });
            this.currentYPosition -= 15;
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 10;
          }
        }
      });
    },
    makeSeminarAndCourses(list, font) {

      this.checkPageHeight()

      // add Title
      this.addHeaderInSection(this.isLanguage == 1 ? "Kurs ve Sertifikalar" : "Seminars And Courses", font);
      list.forEach((item, index) => {
        if (item.name) {

          this.checkPageHeight()

          this.activePage.drawText(item.name, {
            x: 70,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });
          this.currentYPosition -= 15;
          this.activePage.drawText(item.duration ? item.duration : " ", {
            x: 70,
            y: this.currentYPosition,
            size: 9,
            color: rgb(0, 0, 0),
            font,
          });
          if (list.length != index + 1) {
            this.currentYPosition -= 25;
          }
        }
      });
    },
    makeProjectsPublications(list, font) {
      this.checkPageHeight()

      // add Title
      this.addHeaderInSection(
        this.isLanguage == 1 ? "Projeler" : "Published Projects",
        font
      );
      list.forEach((item, index) => {
        if (item.name) {

          this.checkPageHeight()

          this.activePage.drawText(item.name, {
            x: 70,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });
          this.currentYPosition -= 15;

          this.activePage.drawText(item.date ? item.date : "", {
            x: 70,
            y: this.currentYPosition,
            size: 9,
            color: rgb(0, 0, 0),
            font,
          });
          this.currentYPosition -= 15;
          this.activePage.drawText(item.url ? item.url : "", {
            x: 70,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });
          this.currentYPosition -= 18;

          let description = this.wrapText(item.description, 470, font, 9);
          description.forEach((line) => {
            this.activePage.drawText(line, {
              x: 70,
              y: this.currentYPosition,
              size: 9,
              color: rgb(0, 0, 0),
              font,
            });
            this.currentYPosition -= 15;
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 25;
          }
        }
      });
    },
    makeContactInformations(list, font) {
      this.checkPageHeight()
      this.addHeaderInSection(this.isLanguage == 1 ? "İletişim" : "Contact Informations", font);
      list.forEach((item, index) => {
        if (item.value) {
          this.checkPageHeight()

          this.currentYPosition -= 10;
          this.activePage.drawText(item.type ? item.type : "-", {
            x: 70,
            y: this.currentYPosition,
            size: 9,
            color: rgb(0, 0, 0),
            font,
          });
          this.activePage.drawText(item.value, {
            x: 150,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 20;
          }
        }
      });
    },
    checkPageHeight(position, pageHeight) {
      if (this.currentYPosition < (position ? position : 90)) {
        this.pdfDoc.addPage();
        this.activePage =
          this.pdfDoc.getPages()[this.pdfDoc.getPageCount() - 1];

        this.currentYPosition = this.pageHeight - (pageHeight ? pageHeight : 140);
        this.activePage.drawImage(this.backgroundImage, {
          x: 0,
          y: 0,
          width: this.pageWidth,
          height: this.pageHeight,
        });
      }
    },
  },
};
</script>