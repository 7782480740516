<template>
  <div class="w-full z-10">
    <multiselect
      v-model="selectedSector"
      :options="filterList"
      :placeholder="$t('globalComponents.dev.selectorSelect.placholder')"
      :multiple="false"
      :internal-search="false"
      @search-change="asyncFind"
      label="name"
      track-by="id"
      class="text-sm"
      :selectLabel="$t('globalComponents.dev.selectorSelect.add')"
      :deselectLabel="$t('globalComponents.dev.selectorSelect.delete')"
    >
    </multiselect>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import { utility } from "@/networking/urlmanager";
import Fuse from "fuse.js";

export default {
  props: ["value"],
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedSector: [],
      List: [],
      filterList: [],
      fuse: null,
    };
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.List.push(tag);
      this.selectedSector.push(tag);
    },
    asyncFind(val) {
      if (!this.fuse) return;

      if (!val) {
        this.filterList = [...this.List];
        return;
      }

      const results = this.fuse.search(val);
      const newFilterList = results.map((result) => result.item);
      this.filterList = newFilterList;
    },
    async getAll() {
      try {
        const response = await this.axios.get(utility.getSectorAll);
        this.List = response.data.data;
        this.filterList = [...this.List];

        this.fuse = new Fuse(this.List, {
          keys: ["name"],
          includeScore: false,
          threshold: 0.4,
        });
      } catch (error) {
        console.error("Sektör bilgileri yüklenirken bir hata oluştu:", error);
      }
    },
  },
  async created() {
    await this.getAll();
  },
  watch: {
    selectedSector(val) {
      this.$emit("change", val);
    },
    List(val) {
      if (this.value) {
        this.selectedSector = val.find((l) => l.id == this.value[0].sectorId);
      }
    },
  },
};
</script>
