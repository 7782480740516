var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute flex-col items-center invisible opacity-0 group-hover:opacity-100 group-hover:visible flex transition-all duration-200 ease-out w-[110px] z-30",class:[
    _vm.tipClass,
    {
      'bottom-10': _vm.bottom,
      'bottom-10': _vm.top ? false : true,
      'top-10': _vm.top,
    },
  ]},[(_vm.top)?_c('div',{staticClass:"w-3 h-3 -mb-2 rotate-45 bg-black",class:_vm.rotateClass}):_vm._e(),_c('span',{staticClass:"relative text-center p-2 text-xs leading-none text-white whitespace-normal bg-black shadow-lg rounded",domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.bottom ? _vm.bottom : _vm.top ? false : true)?_c('div',{staticClass:"w-3 h-3 -mt-2 rotate-45 bg-black",class:_vm.rotateClass}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }