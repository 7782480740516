<template>
  <modal
    name="log"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '60%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white z-50"
    >
      <h1 class="">{{ $t("globalComponents.log.logTitle") }}</h1>
      <button class=" " type="button" @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="px-3 py-4 relative">
      <div class="tableOverflow scroltypey">
        <div class="tableWidth">
          <div class="tableContainer !border-0 !px-0">
            <div class="tableHead !bg-gray-200 !textcol !static">
              <ul class="tableUl">
                <li class="w-1/12">#</li>
                <li class="w-3/12">{{ $t("globalComponents.log.user") }}</li>
                <li class="w-3/12">{{ $t("globalComponents.log.action") }}</li>
                <li class="w-5/12 flex justify-end items-center pl-4">
                  <div
                    class="w-[300px] flex items-center border border-[#182451] rounded-md h-9 relative"
                  >
                    <input
                      v-model="search"
                      @keypress.enter="getAll()"
                      type="text"
                      class="h-full w-full outline-none pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
                      :placeholder="
                        $t('globalComponents.log.searchPlaceholder')
                      "
                    />
                    <i
                      class="fa-solid fa-magnifying-glass px-3 textcol absolute right-0"
                    ></i>
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="tableBody scroltype !px-0 !mt-1"
              :class="List.length > 5 ? '!pr-4' : ''"
            >
              <div class="tableBodyContainer">
                <ul
                  v-for="(item, index) in List"
                  :key="index"
                  class="tableBodyUl !px-8"
                >
                  <li class="w-1/12 py-2.5">{{ index + 1 }}</li>
                  <li class="w-3/12 py-2.5">
                    {{ item.firstName + " " + item.lastName }}
                  </li>
                  <li class="w-3/12 py-2.5">{{ item.message }}</li>
                  <li class="w-5/12 py-2.5 text-justify flex justify-end pl-4">
                    {{ item.addDate | getDate }}
                  </li>
                </ul>
                <tableLoader :load="load" :length="List.length" colspan="7" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex justify-between items-center border-t border-gray-200 pt-3 px-4"
      >
        <pagination
          v-model="currentPage"
          :pageCount="pageCount"
          :totalRowCount="totalCount"
          paginationClass="!py-0 -ml-3"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import pagination from "@/components/pagination.vue";
import tableLoader from "@/components/table-loader.vue";

import { utility } from "@/networking/urlmanager";

import moment from "moment";
import "moment/locale/tr";

export default {
  name: "log-component",
  components: {
    pagination,
    tableLoader,
  },
  data() {
    return {
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,
      moduleId: 0,
      List: [],
      load: false,
    };
  },
  methods: {
    show() {
      this.modulePage();
      if (this.moduleId > 0) {
        setTimeout(() => {
          this.getAll();
        }, 50);
        this.$modal.show("log");
      }
    },
    hide() {
      this.$modal.hide("log");
    },
    modulePage() {
      switch (this.$route.name) {
        case "project":
          this.moduleId = 3;
          break;
        case "cv":
          this.moduleId = 2;
          break;
        case "users":
          this.moduleId = 1;
          break;
        case "setting":
          this.moduleId = 6;
          break;
        case "contact":
          this.moduleId = 6;
          break;
        case "settingInterview":
          this.moduleId = 6;
          break;
        case "configration":
          this.moduleId = 6;
          break;
        default:
          this.moduleId = 0;
          break;
      }
    },
    getAll() {
      this.load = true;
      this.List = [];
      this.axios.get(utility.getLogModule ,{
        params:{
          moduleId:this.moduleId,
          page:this.currentPage,
          search:this.search
        }
      }).then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;
          this.List = detail;
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    formatLocalDate(utcDate) {
      if (!utcDate) return "Tarih Yok";
      const serverTimezoneOffset = -3;
      const serverTime = new Date(utcDate);
      serverTime.setMinutes(
        serverTime.getMinutes() + serverTimezoneOffset * 60
      );
      const months = [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık",
      ];
      const day = serverTime.getDate();
      const monthIndex = serverTime.getMonth();
      const year = serverTime.getFullYear();
      const hours = serverTime.getHours();
      const minutes = serverTime.getMinutes();
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      return `${day} ${months[monthIndex]} ${year} ${formattedTime} `;
    },
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LLL");
      else return "Tarih Yok";
    },
  },
  async created() {
    await this.modulePage();
    await this.$emit("isLog", this.moduleId);
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    async "$route.name"(val) {
      await this.modulePage();
      await this.$emit("isLog", this.moduleId);
    },
  },
};
</script>
