var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{key:_vm.item.id,staticClass:"flex items-center gap-2",attrs:{"for":`item${_vm.item.id}`},on:{"dblclick":() => _vm.startEdit(_vm.item.id)}},[(_vm.item.id !== _vm.listItemEditIndex)?_c('span',{staticClass:"text-gray-600 font-medium"},[_vm._v(" "+_vm._s(_vm.item.weightedScore)+" ")]):_c('input',{ref:"inputRef",staticClass:"bg-transparent border border-gray-300 rounded-md text-sm px-2 m-0 h-5 w-12 resize-none",attrs:{"type":"text","id":`item${_vm.item.id}`,"disabled":_vm.listItemIsLoad},domProps:{"value":_vm.listItemIsLoad ? _vm.listItemEditValue : _vm.item.weightedScore},on:{"input":(val) => {
            const itemIndex = _vm.List.findIndex((i) => i.id === _vm.item.id);
            if (itemIndex !== -1) {
                _vm.listItemEditValue = val.target.value;
            }
        },"blur":() => {
            if (!_vm.listItemIsLoad)
                _vm.listItemEditIndex = -1
        },"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return ((e) => _vm.handleEdit(_vm.item.id, _vm.item.weightedScore)).apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return (() => _vm.listItemEditIndex = -1).apply(null, arguments)}]}}),_c('button',{staticClass:"text-gray-400 hover:text-gray-600",attrs:{"type":"button"},on:{"mousedown":function($event){$event.preventDefault();},"click":() => _vm.listItemEditIndex !== _vm.item.id ?
        _vm.startEdit(_vm.item.id) :
        _vm.handleEdit(_vm.item.id, _vm.item.weightedScore)}},[(_vm.listItemEditIndex !== _vm.item.id)?_c('i',{staticClass:"fas fa-edit"}):(_vm.listItemEditIndex === _vm.item.id && _vm.listItemIsLoad)?_c('i',{staticClass:"fas fa-spinner animate-spin"}):_c('i',{staticClass:"fas fa-check"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }