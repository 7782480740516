<template>
  <modal
    name="add-contact-project"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">{{ $t("project.detail.addEmail.addTitle") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="add()"
      class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
    >
      <div class="space-y-4 pb-3">
        <customInput
          v-model="value"
          min="2"
          max="255"
          :required="true"
          :placeholder="$t('project.detail.addEmail.emailAddress')"
          :title="$t('project.detail.addEmail.emailAddress')"
        />
        <requiredField />
        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem :load="load" buttonClass="w-full" />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";
import { contact } from "@/networking/urlmanager.js";

export default {
  name: "contact-add",
  props: ["cvId"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,
      value: "",
    };
  },
  methods: {
    show() {
      this.value = "";
      this.$modal.show("add-contact-project");
    },
    hide() {
      this.$modal.hide("add-contact-project");
    },
    add() {
      if (this.value) {
        this.load = true;
        let list = [
          {
            icon: 1,
            type: this.$t("project.detail.addEmail.email"),
            value: this.value,
          },
        ];
        this.axios
          .post(contact.add, {
            cvId: this.cvId,
            list: JSON.stringify(list),
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.$emit("refresh", true);
            this.load = false;
            this.hide();
          })
          .catch((err) => {
            console.log(err);
            this.authController(err);
            this.load = false;
          });
      }
    },
  },
};
</script>
