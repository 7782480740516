<template>
  <div id="chart" class="pb-5">
    <apexchart
      ref="chart"
      type="bar"
      height="480"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import { general } from "@/networking/urlmanager";

export default {
  name: "positionsChart",
  data() {
    return {
      colors: ["black", "black", "black"],
      series: [],
      chartOptions: {
        title: {},
        xaxis: {},
      },
    };
  },
  methods: {
    getpositionAll() {
      this.axios
        .get(general.getpositionAll)
        .then((res) => {
          const data = res.data.data[0];
          this.series = [
            {
              name: this.$t("general.components.positionChart.situation"),
              data: [data.completedCount, data.openCount, data.closedCount],
            },
          ];
        })
        .catch((error) => {
          console.log("Veri alınamadı:", error);
        });
    },
    getChartOptions() {
      return {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {},
          },
        },
        title: {
          text: this.$t("general.components.positionChart.openPositionChart"),
          align: "left",
          style: {
            fontSize: "12px",
          },
        },
        colors: ["#182451", "#878DA5", "#006ff7"],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [
            this.$t("general.components.positionChart.completed"),
            this.$t("general.components.positionChart.open"),
            this.$t("general.components.positionChart.cancel"),
          ],
          labels: {
            style: {
              colors: this.colors,
              fontSize: "12px",
            },
          },
        },
      };
    },
    updateChartOptions() {
      this.chartOptions.title.text = this.$t(
        "general.components.positionChart.openPositionChart"
      );
      this.chartOptions.xaxis.categories = [
        this.$t("general.components.positionChart.completed"),
        this.$t("general.components.positionChart.open"),
        this.$t("general.components.positionChart.cancel"),
      ];
    },
    updateSeries() {
      if (this.series && this.series.length) {
        this.series = [
          {
            name: this.$t("general.components.positionChart.situation"),
            data: this.series[0].data,
          },
        ];
      }
    },
  },
  watch: {
    "$i18n.locale": {
      handler() {
        this.updateChartOptions();
        this.updateSeries();

        if (this.$refs.chart) {
          this.$refs.chart.updateOptions({
            title: {
              text: this.chartOptions.title.text,
            },
            xaxis: {
              categories: this.chartOptions.xaxis.categories,
            },
          });
          this.$refs.chart.updateSeries(this.series, false, true);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.chartOptions = this.getChartOptions();
    this.getpositionAll();
  },
};
</script>
