<template>
  <div class="flex" @mousedown="handleMouseDown">
    <div
      class="w-[77%] h-screen z-10"
      :class="
        meetingLiveBar == false
          ? 'w-full transition-all ease-in-out'
          : ' transition-all ease-in-out'
      "
    >
      <meetingNavbar />
      <appearance />
      <meetingFooter />
    </div>
    <div
      class="w-[23%] relative h-screen"
      :class="
        meetingLiveBar == false
          ? 'w-0 transition-all ease-in-out'
          : ' transition-all ease-in-out '
      "
    >
      <!--  style="clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 89%);" -->
      <div
        class="border-r border-gray-300 bg-white h-60 w-12 shadow-md absolute right-full z-50 flex items-end"
        style="border-radius: 0px 0px 0px 10px"
      >
        <div class="w-full">
          <div
            class="flex justify-center items-end"
            :class="meetingLiveBar == true && 'shadow-xl'"
            style="border-radius: 0px 0px 0px 10px"
          >
            <button @click="meetingLiveBar = !meetingLiveBar">
              <img class="py-6" src="@/assets/meeting/message.svg" alt="" />
            </button>
          </div>
          <div class="flex justify-center">
            <button>
              <img class="py-6" src="@/assets/meeting/+.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
      <div
        class="overflow-y-auto transition-all ease-in-out"
        v-if="meetingLiveBar"
      >
        <div class="button flex border-b border-gray-300 px-6 h-16 py-3">
          <div class="flex w-full rounded bg-[#182451]">
            <button
              @click="isButton = false"
              class="w-6/12 py-2 text-gray-400 text-sm"
              :class="isButton == false && 'bg-[#071238] !text-white rounded'"
            >
              Meeting
            </button>
            <button
              @click="isButton = true"
              class="w-6/12 py-2 text-gray-400 text-sm"
              :class="isButton == true && 'bg-[#071238] !text-white rounded'"
            >
              Firma
            </button>
          </div>
        </div>
      </div>
      <div v-if="meetingLiveBar" class="transition-all ease-in-out">
        <users />
        <message />
      </div>
    </div>
    <div
      v-if="$store.state.isMeetingBar == true"
      class="fixed z-50 opacity-80 bg-gray-200 top-0 h-full w-full transition-all ease-in-out"
    ></div>
  </div>
</template>
<script>
import users from "./components/users.vue";
import message from "./components/message.vue";
import meetingFooter from "./components/footer.vue";
import meetingNavbar from "./components/meetingNavbar.vue";
import appearance from "./components/appearance.vue";
export default {
  name: "connetMeeting",
  components: {
    users,
    message,
    meetingFooter,
    meetingNavbar,
    appearance,
  },
  data() {
    return {
      isButton: false,
      meetingLiveBar: true,
    };
  },
  methods: {
    handleMouseDown() {
      if (this.$store.state.isMeetingBar) {
        this.$store.state.isMeetingBar = !this.$store.state.isMeetingBar;
        this.$store.commit("setMeetingBar", this.$store.state.isMeetingBar);
      }
    },
  },
};
</script>
<style>
.scroltype::-webkit-scrollbar {
  width: 6px;
}

.scroltype::-webkit-scrollbar-thumb {
  background: #182451;
  border-radius: 10px;
}
</style>
