<template>
  <div
    class="flex items-center w-full justify-center"
    style="height: calc(100vh - 150px)"
  >
    <cvListModal
      ref="cvListModal"
      :isNext="true"
      @load="(val) => (load = val)"
    />

    <div class="w-6/12 max-sm:w-11/12">
      <img src="@/assets/cv/question-mark.png" alt="" class="mx-auto mb-5" />
      <h1 class="text-2xl text-center text-gray-800">
        {{ $t("cv.cvSaveMiddle.title") }}
      </h1>
      <div class="flex justify-center gap-10 mt-10 max-sm:px-10">
        <button
          :disabled="load"
          @click="$refs.cvListModal.nextCv()"
          class="px-14 py-2 max-sm:px-0 max-sm:w-full bg-blue-400 hover:bg-blue-500/90 text-white mt-5 rounded text-lg"
        >
          {{ this.$t("cv.cvSaveMiddle.afterCv") }}
        </button>
        <button
          :disabled="load"
          class="px-14 py-2 max-sm:px-0 max-sm:w-full bg-white border border-blue-400 hover:bg-blue-400 text-blue-400 hover:text-white text-lg rounded mt-5"
          @click="$router.push('/cv')"
        >
          {{ this.$t("cv.cvSaveMiddle.resumePage") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import cvListModal from "./components/cvListSide/index.vue";

export default {
  name: "cv-save-page",
  components: {
    cvListModal,
  },
  data() {
    return {
      load: false,
    };
  },
  async mounted() {
    await this.$refs.cvListModal.getAll();
    if (this.$store.state.extractCvList.length > 0) {
      const data = this.$store.state.extractCvList.every(
        (item) => item.stateId === 1
      );
      if (this.$store.state.extractCvList.length == 1 || data) {
        this.$refs.cvListModal.listExtract();
        this.$store.commit("extractCvListDelete");
        this.$router.push("/cv");
      }
    }
  },
};
</script>
