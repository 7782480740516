<template>
  <div class="mb-5">
    <div class="flex items-center space-x-2">
      <label class="textcol font-semibold">{{
        $t("support.components.uploadFile.file")
      }}</label>
      <div class="mt-2 relative group flex justify-center">
        <i
          class="fa-solid fa-circle-exclamation text-sm text-[#182451] mb-1"
        ></i>
        <tooltip
          :title="$t('support.components.uploadFile.note')"
          tipClass="z-50 ml-2 !bottom-6 !w-[170px]"
          :bottom="true"
        />
      </div>
    </div>

    <div class="w-full h-24 border bordercol rounded">
      <div
        class="px-20 max-sm:px-10 flex items-center h-full justify-center"
        @dragover.prevent
        @dragenter="dragenter"
        @dragleave="dragleave"
        @drop="drop"
        :class="{ 'bg-gray-200': isDragging }"
      >
        <input
          type="file"
          multiple
          name="file"
          id="fileInput"
          class="hidden"
          @change="onChange"
          ref="file"
          accept=".jpg,.jpeg,.png"
        />

        <label
          for="fileInput"
          class="w-full flex flex-col justify-center items-center"
        >
          <i class="fas fa-upload text-3xl" v-if="isDragging"></i>
          <div v-if="isDragging">
            {{ $t("support.components.uploadFile.dropFilesHere") }}
          </div>
          <div v-if="!isDragging && files.length == 0">
            {{ $t("support.components.uploadFile.uploadFile") }}
          </div>
          <div v-if="!isDragging && files.length > 0">
            {{ files.length }}
            {{ $t("support.components.uploadFile.numberOfFilesUploaded") }}
          </div>
        </label>
      </div>
    </div>
    <div v-if="files.length > 0" class="mt-2">
      <h3 class="font-semibold">
        {{ $t("support.components.uploadFile.uploadedFiles") }}
      </h3>
      <div class="grid grid-cols-1 gap-4">
        <div
          v-for="(file, index) in files"
          :key="index"
          class="border border-gray-200 rounded p-4"
        >
          <div class="flex items-center justify-between">
            <div>
              <div class="truncate w-40 flex items-center">
                {{ shortenFileName(file.name) }}
              </div>
              <div class="text-sm text-gray-500">
                {{ formatFileSize(file.size) }}
              </div>
            </div>
            <button
              type="button"
              class="text-red-500 hover:text-red-700"
              @click="remove(index)"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tooltip from "@/components/tooltip.vue";

export default {
  name: "uploadPanel",
  components: { tooltip },
  data() {
    return {
      isDragging: false,
      dragCounter: 0,
      files: [],
      load: false,
      maxFiles: 2,
      maxFileSize: 10 * 1024 * 1024, // 10MB
      allowedFormats: ["jpg", "jpeg", "png"],
    };
  },
  methods: {
    shortenFileName(name) {
      if (name.length > 15) {
        const extension = name.split(".").pop();
        return `${name.substring(0, 12)}...${extension}`;
      }
      return name;
    },
    onChange() {
      const selectedFiles = [...this.$refs.file.files];
      const validFiles = selectedFiles.filter(this.validateFile);
      const totalFiles = this.files.length + validFiles.length;

      if (totalFiles > this.maxFiles) {
        this.warningMesage(
          this.$t("support.components.uploadFile.maxFilesWarning")
        );
        return;
      }

      this.files = [...this.files, ...validFiles];
      this.$emit("fileUpload", this.files);
    },
    validateFile(file) {
      const fileExists = this.files.some((f) => f.name === file.name);
      if (fileExists) {
        this.warningMesage(
          this.$t("support.components.uploadFile.duplicateFileWarning")
        );
        return false;
      }

      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!this.allowedFormats.includes(fileExtension)) {
        this.warningMesage(
          this.$t("support.components.uploadFile.allowedFormatsWarning")
        );
        return false;
      }
      return true;
    },
    dragenter(e) {
      e.preventDefault();
      this.dragCounter++;
      this.isDragging = true;
    },
    dragleave(e) {
      e.preventDefault();
      this.dragCounter--;
      if (this.dragCounter === 0) {
        this.isDragging = false;
      }
    },
    drop(e) {
      e.preventDefault();
      this.dragCounter = 0;
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    formatFileSize(size) {
      if (size >= 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
      } else if (size >= 1024) {
        return `${(size / 1024).toFixed(2)} KB`;
      } else {
        return `${size} B`;
      }
    },
  },
};
</script>

<style scoped>
.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100vh;
  justify-content: center;
  text-align: center;
}

.dropzone-container {
  padding: 4rem;
  background: #f7fafc;
  border: 1px solid #e2e8f0;
}
</style>
