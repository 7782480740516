import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/css/table.css";
import "./assets/css/style.css";

//alert kütüphanesi
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
import "sweetalert2/dist/sweetalert2.min.css";

import sideModal from "./components/sideModal.vue";
Vue.component('sideModal', sideModal);

import alert from "./components/alert.vue";
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('alert', alert);

//modal kütüphanesi
import VModal from "vue-js-modal/dist/ssr.nocss";
import "vue-js-modal/dist/styles.css";

import Multiselect from "./components/multiSelect.vue";
Vue.component('multiSelectVue', Multiselect);

Vue.use(VModal, {
  componentName: "modal",
  dynamicDefault: { draggable: true, resizable: false },
  adaptive: true,
});

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});

//tur kütüphanesi
import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");
Vue.use(VueTour);

//grafik kütüphanesi
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("apexchart", VueApexCharts);

//axios İnstance
import axiosInstance from "./plugins/axiosInstance";
Vue.prototype.axios = axiosInstance;

// recaptcha;
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, {
  siteKey: "6LcCPW0pAAAAAOjbqZCWmx69SuPmgV5hu8OL11Bm",
});

// dil kütüphanesi
import i18n from "./plugins/i18n";

//ortak Karışımlar
import commonMixins from "./plugins/commonMixins.js";
Vue.mixin(commonMixins);

Vue.prototype.$bus = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
