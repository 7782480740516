<template>
  <div class="w-full flex max-sm:flex-wrap mt-10">
    <div class="w-full max-sm:w-full">
      <div class="flex justify-between max-sm:flex-col-reverse">
        <h4 class="text-lg tracking-wider textcol font-semibold">
          <span class="border-t-2 pt-1 border-[#344CA0]">
            {{ $t("cv.profile.schoolAndEducation.index.scool") }}</span
          >
        </h4>

        <addItem ref="addModal" :isEdit="isEdit" @refresh="getAll()" />
        <editItem
          ref="editItem"
          :isEdit="isEdit"
          @refresh="getAll()"
          :Detail="selected"
          :index="index"
          @loadChance="loading = !loading"
        />

        <div
          class="flex justify-end items-center gap-5 max-sm:justify-between max-sm:mb-6"
        >
          <div class="relative group flex justify-center">
            <button
              @click="$refs.addModal.show()"
              class="w-20 py-2 text-base rounded text-black"
            >
              <i class="fas fa-plus mr-1"></i>
              {{ $t("cv.profile.schoolAndEducation.index.add") }}
            </button>
            <tooltip
              :title="$t('cv.profile.schoolAndEducation.index.addToolTip')"
              tipClass=" bottom-8 max-sm:ml-2 text-center"
            />
          </div>
        </div>
      </div>
      <div v-if="isContent && !loading" class="relative">
        <draggable
          v-model="$store.state.extractDetail.schoolAndEducation"
          @change="
            $store.commit(
              'setSchoolAndEducation',
              $store.state.extractDetail.schoolAndEducation
            )
          "
          @start="drag = true"
          @end="drag = false"
        >
          <div
            class="w-full border-b border-b-[#344ba045] pb-4 pt-1 border-dotted avoid-page-break"
            v-for="(item, index) in $store.state.extractDetail
              .schoolAndEducation"
            :key="index"
          >
            <card
              :img="require('@/assets/cv/education-information.svg')"
              :rowId="item.id"
              :title="item.name"
              :sub="item.duration"
              :third="item.department"
              :five="getItemStatus(item.status)"
              :index="index"
              deleteCommitName="deleteEducation"
              :isDraggable="true"
              @deleteItem="!isEdit ? deleteStoreItem(index) : deleteItem(item)"
              @editItem="editItem(item, index)"
            />
          </div>
        </draggable>
      </div>

      <dataLoader
        :load="loading"
        :isContent="isContent"
        :title="$t('cv.profile.schoolAndEducation.index.dataLoader')"
      />
    </div>
  </div>
</template>
<script>
import card from "../components/card.vue";
import { educationLife } from "@/networking/urlmanager.js";
import draggable from "vuedraggable";
import tooltip from "@/components/tooltip.vue";
import addItem from "./components/add.vue";
import editItem from "./components/edit.vue";
import dataLoader from "../components/dataLoader.vue";

export default {
  name: "educationLife",
  props: ["load", "isEdit"],
  components: {
    card,
    draggable,
    tooltip,
    addItem,
    editItem,
    dataLoader,
  },
  data() {
    return {
      loading: false,
      selected: "",
      index: "",
    };
  },
  methods: {
    getItemStatus(val) {
      if (val == 1) {
        return this.$t("cv.profile.schoolAndEducation.index.highSchool");
      } else if (val == 2) {
        return this.$t("cv.profile.schoolAndEducation.index.associateDegree");
      } else if (val == 3) {
        return this.$t("cv.profile.schoolAndEducation.index.licence");
      } else if (val == 4) {
        return this.$t("cv.profile.schoolAndEducation.index.degree");
      } else if (val == 5) {
        return this.$t("cv.profile.schoolAndEducation.index.doctorate");
      } else {
        return this.$t("cv.profile.schoolAndEducation.index.unspecified");
      }
    },
    getAll() {
      this.loading = true;
      this.axios
        .get(educationLife.getAll + "?cvId=" + this.$route.params.id)
        .then((res) => {
          this.$store.commit("setSchoolAndEducation", res.data.data);
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          let schoolAndEducation =
            this.$store.state.extractDetail.schoolAndEducation;

          this.axios
            .post(educationLife.delete, {
              rowId: item.id,
              educationList: schoolAndEducation,
              cvId: this.$route.params.id,
            })
            .then((res) => {
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    deleteStoreItem(index) {
      this.verifiedMessage().then((acc) => {
        if (acc.isConfirmed) {
          this.$store.commit("deleteEducation", index);
        }
      });
    },
    educationCalculator(val, item) {
      let list = val
        .filter((r) => r.id != item.id)
        .sort(function (a, b) {
          return b.status - a.status;
        });

      if (list.length > 0) return list[0].status;
      else return 0;
    },
    editItem(val, index) {
      this.$refs.editItem.show();
      this.selected = val;
      this.index = index;
    },
  },
  computed: {
    isContent() {
      if (Array.isArray(this.$store.state.extractDetail.schoolAndEducation))
        if (this.$store.state.extractDetail.schoolAndEducation.length > 0)
          return true;
        else return false;
      else return false;
    },
  },
};
</script>
