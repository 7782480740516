<template>
  <div class="flex py-8 border-b border-gray-200 max-md:flex-wrap gap-[97px]">
    <div class="w-6/12 max-md:w-full">
      <h1 class="text-base text-gray-700 font-semibold">
        {{ $t("settings.general.components.refreshToken.authorizationServices") }}
      </h1>
      <p class="text-xs text-gray-700 mt-2">
        {{ $t("settings.general.components.refreshToken.authorizationServicesText") }}
      </p>
    </div>
    <div class="w-6/12 h-full max-md:w-full flex flex-col gap-4">
      <div class="flex flex-col gap-2 w-full justify-end">
        <label class="textcol font-semibold flex gap-1 items-center pb-1">
          {{ $t("settings.general.components.refreshToken.defaultService") }}
        </label>
        <select required :value="currentService" @change="(e) => setDefaultService(e.target.value)"
          class="w-full border bordercol rounded py-3 outline-none pl-3 text-xs">
          <option selected disabled>
            {{ $t('settings.general.components.refreshToken.chooseService') }}
          </option>
          <option v-for="(key, index) in Object.keys(PROVIDE_SERVICE)" :key="index" :value="key">
            {{ PROVIDE_SERVICE[key] }}
          </option>
        </select>
      </div>

      <div :key="$props.defaultService" class="flex gap-10 items-end justify-end">
        <customButton v-if="currentService == 1"
          :readonly="isMicrosoftTokenLoad || $store.state.integration.microsoft.isRefresh"
          :disabled="!$store.state.integration.microsoft.isRefresh" :load="isMicrosoftTokenLoad" type="button"
          loadText="Erişim Sağlanıyor..." @click="tokenMicrosoftController()" :text="$store.state.integration.microsoft.isRefresh
            ? $t('settings.general.components.refreshToken.accessProvided')
            : $t('settings.general.components.refreshToken.microsoftAccess')
            " buttonClass="w-full " :imgSrc="require('@/assets/img/outlookIcon.svg')" alt="Microsoft Icon"
          containerBackground="bg-gray-100 !text-black !shadow  justify-center gap-3 !w-full"
          container-class="w-full md:w-auto" spinnerClass="border-black" imgClass="w-5" />

        <customButton v-else :readonly="isGoogleTokenLoad || $store.state.integration.google.isRefresh"
          :disabled="!$store.state.integration.google.isRefresh" :load="isGoogleTokenLoad" type="button"
          loadText="Erişim Sağlanıyor..." @click="tokenGoogleController()" :text="$store.state.integration.google.isRefresh
            ? $t('settings.general.components.refreshToken.accessProvided')
            : $t('settings.general.components.refreshToken.googleAccess')
            " buttonClass="w-full " :imgSrc="require('@/assets/img/googleIcon.svg')" alt="Google Icon"
          containerBackground="bg-gray-100 !text-black !shadow  justify-center gap-3 !w-full"
          container-class="w-full md:w-auto" spinnerClass="border-black" imgClass="w-5" />
      </div>
    </div>
  </div>
</template>

<script>
const PROVIDE_SERVICE = {
  1: "Microsoft",
  2: "Google"
}

import customButton from "@/components/button.vue";
import {
  technicalInterviewMicrosoft,
  technicalInterviewGoogle,
} from "@/networking/urlmanager";

export default {
  name: "refresh-token",
  components: {
    customButton,
  },
  props: ["defaultService"],
  data() {
    return {
      PROVIDE_SERVICE,
      currentService: this.$props.defaultService,
      isMicrosoftTokenLoad: false,
      isGoogleTokenLoad: false,
      errorMessage: "",
    };
  },
  methods: {
    setDefaultService(val) {
      this.$store.commit("setDefaultService", val);
      this.$emit("setDefaultService", val);
      this.currentService = val;
    },

    async tokenMicrosoftController() {
      try {
        const vuexState = JSON.parse(localStorage.getItem("vuex"));
        const microsoftToken = vuexState.integration.microsoft.token;
        const microsoftRefreshToken = vuexState.integration.microsoft.refreshToken;
        this.isMicrosoftTokenLoad = true;

        const res = await this.axios.post(
          technicalInterviewMicrosoft.tokenController,
          {
            accessToken: microsoftToken,
            refreshToken: microsoftRefreshToken,
          }
        );

        const data = res.data.data;
        this.isMicrosoftTokenLoad = false;
        if (data?.token && data?.refresh_token) {
          this.$store.commit("setIntegrationMicrosoft", data);
        } else {
          await this.generateMicrosoftToken();
        }
        this.$store.commit("setIsMicrosoftRefresh", false);
        this.successMessage(res.data.message);
      } catch (err) {
        this.isMicrosoftTokenLoad = false;
        await this.generateMicrosoftToken();
        console.log(err);
      }
    },

    async tokenGoogleController() {
      try {
        const vuexState = JSON.parse(localStorage.getItem("vuex"));
        const googleToken = vuexState.integration.google.token;
        const googleRefreshToken = vuexState.integration.google.refreshToken;
        this.isGoogleTokenLoad = true;

        const res = await this.axios.post(
          technicalInterviewGoogle.tokenController,
          {
            access_token: googleToken,
            refresh_token: googleRefreshToken,
          }
        );

        const data = res.data.data;
        if (data?.token && data?.refresh_token) {
          this.$store.commit("setIntegrationGoogle", data);
        } else {
          await this.generateGoogleToken();
        }
        this.$store.commit("setIsGoogleRefresh", false);
        this.isGoogleTokenLoad = false;
        this.successMessage(res.data.message);
      } catch (err) {
        this.isGoogleTokenLoad = false;
        await this.generateGoogleToken();
        console.log(err);
      }
    },

    generateGoogleToken() {
      try {
        this.warningMesage(
          this.$t(
            "project.projectUser.components.shortList.components.meetModal.warningMesage"
          ),
          3500
        ).then(() => {
          this.axios.get(technicalInterviewGoogle.authorize).then((res) => {
            window.open(res.data.data);
          });
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    generateMicrosoftToken() {
      try {
        this.warningMesage("Outlook Oturum yetkilendirmeniz bulunmuyor,erişim için yönlendiliyorsunuz", 3500)
          .then(() => {
            let CLIENT_ID = "b7b10a24-b31b-40b2-97fa-c0a07ff71e08"
            const domain = new URL(window.location.href).origin;
            window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${CLIENT_ID}&redirect_uri=${domain}/outlook&response_type=code&scope=User.ReadWrite.All Calendars.Read Calendars.ReadWrite profile openid offline_access OnlineMeetings.ReadWrite Group.ReadWrite.All ServiceActivity-Teams.Read.All`);
          });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
  },
};
</script>