<template>
  <modal
    name="cv-add-project"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("project.components.add.title") }}
      </h1>

      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="
        !isEdit ? save() : projectSave($store.state.extractDetail.id)
      "
      class="p-5 dxl:pb-5"
    >
      <projectList v-model="selectedList" :isEdit="isEdit" class="mb-2.5" />
      <requiredField />

      <div
        class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
      >
        <div class="w-full pt-2">
          <buttonItem
            :load="load"
            :name="$t('cv.profile.schoolAndEducation.components.add.save')"
            buttonClass="w-full"
          />
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import projectList from "./projectList.vue";

import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

export default {
  name: "addProject",
  props: ["isEdit", "projectSave"],
  components: {
    buttonItem,
    requiredField,
    projectList,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      isModalOver: false,
      load: false,
      selectedList: [],
    };
  },
  methods: {
    show() {
      this.$modal.show("cv-add-project");
    },
    hide() {
      this.$modal.hide("cv-add-project");
    },
    save() {
      this.load = true;
      this.$store.commit("setProjectList", this.selectedList);
      this.successMessage();
      this.hide();
      this.load = false;
    },
    chanceLoad() {
      this.load = !this.load;
      if (this.load == false) {
        this.hide();
      }
    },
  },
  watch: {
    selectedList(val) {
      this.$emit("change", val);
    },
    value(val) {
      this.selectedList = val;
    },
    "$store.state.projectList": function () {
      this.selectedList = this.$store.state.projectList;
    },
  },
};
</script>
<style>
.vm--modal {
  overflow: visible !important;
}
</style>
