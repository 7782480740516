<template>
  <modal
    name="ressume-export-panel"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <exportPanel
      :hiddenList="hidenList"
      :isLanguage="isLanguage"
      ref="exportPanel"
    />

    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.components.ressumeExport.title") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20">
      <div class="space-y-4 pb-3">
        <div class="gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0">
          <div
            v-for="(item, index) in List"
            :key="index"
            class="flex py-2 border-b border-gray-100 h-full"
          >
            <div class="w-full text-gray-700">
              {{ item.name }}
            </div>
            <button
              @click="chanceHidenList(item)"
              class="w-[45px] h-full p-1 rounded-full border bordercol"
              :class="hidenList.includes(item.id) ? 'bg-white' : ' bgcol'"
            >
              <div
                class="w-4 h-4 rounded-full transition-all ease-in"
                :class="
                  hidenList.includes(item.id) ? 'bgcol' : 'ml-[55%]  bg-white'
                "
              ></div>
            </button>
          </div>

          <div
            class="w-full text-gray-700 flex justify-between items-center py-3 border-b border-gray-100"
          >
            <span>{{
              $t("cv.profile.components.ressumeExport.language")
            }}</span>

            <div>
              <customInput
                inputClass="!py-1"
                v-model="isLanguage"
                type="select"
                :selectList="[
                  {
                    value: 1,
                    name: $t(
                      'cv.profile.components.ressumeExport.languageList.turkish'
                    ),
                  },
                  {
                    value: 2,
                    name: $t(
                      'cv.profile.components.ressumeExport.languageList.english'
                    ),
                  },
                ]"
                :required="true"
                :placeholder="
                  $t(
                    'cv.profile.components.ressumeExport.languageList.authority'
                  )
                "
              />
            </div>
          </div>
        </div>
        <div class="w-full">
          <buttonItem
            @click="generatePDF()"
            :load="load"
            :text="$t('cv.profile.components.ressumeExport.export')"
            :loadText="
              $t('cv.profile.components.ressumeExport.exportPlacholder')
            "
            buttonClass="w-full"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import exportPanel from "../export/index";

export default {
  name: "addProject",
  props: ["isEdit"],
  components: {
    customInput,
    buttonItem,
    exportPanel,
  },
  data() {
    return {
      hidenList: [],
      List: [
        {
          id: 1,
          name: this.$t("cv.profile.components.ressumeExport.nameSurname"),
        },
        {
          id: 10,
          name: this.$t("cv.profile.components.ressumeExport.pozition"),
        },
        {
          id: 11,
          name: this.$t("cv.profile.components.ressumeExport.biography"),
        },
        {
          id: 0,
          name: this.$t("cv.profile.components.ressumeExport.user"),
        },
        {
          id: 2,
          name: this.$t("cv.profile.components.ressumeExport.scool"),
        },
        {
          id: 3,
          name: this.$t("cv.profile.components.ressumeExport.work"),
        },
        {
          id: 4,
          name: this.$t("cv.profile.components.ressumeExport.skill"),
        },
        {
          id: 5,
          name: this.$t("cv.profile.components.ressumeExport.computer"),
        },
        {
          id: 6,
          name: this.$t("cv.profile.components.ressumeExport.abilities"),
        },
        {
          id: 7,
          name: this.$t("cv.profile.components.ressumeExport.sertificate"),
        },
        {
          id: 8,
          name: this.$t("cv.profile.components.ressumeExport.project"),
        },
        {
          id: 9,
          name: this.$t("cv.profile.components.ressumeExport.communication"),
        },
      ],
      isLanguage: 1,
      load: false,
    };
  },
  methods: {
    show() {
      setTimeout(() => {
        this.hidenList = this.$store.state.exportRessume.hidenList || [];
        this.isLanguage = this.$store.state.exportRessume.isLanguage || 1;
      }, 50);
      this.$modal.show("ressume-export-panel");
    },
    hide() {
      this.$modal.hide("ressume-export-panel");
    },
    chanceHidenList(val) {
      if (this.hidenList.includes(val.id)) {
        const index = this.hidenList.findIndex((r) => r === val.id);
        if (index >= 0) this.hidenList.splice(index, 1);
      } else {
        this.hidenList.push(val.id);
      }
    },
    async generatePDF() {
      this.load = true;
      this.$store.commit("setExportRessume", {
        hidenList: this.hidenList,
        isLanguage: this.isLanguage,
      });
      await this.$refs.exportPanel.generatePDF();
      this.load = false;
      this.hide();
    },
  },
};
</script>
