<template>
  <modal
    name="edit-information"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-xl">
        {{ $t("cv.profile.personalInformation.components.edit.edit") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="isEdit ? add() : save()"
      class="p-5 max-h-screen xl:pb-5 pb-20"
    >
      <div class="space-y-4 pb-3">
        <div v-if="Detail.type == 'select'">
          <customInput
            v-model="value"
            type="select"
            :selectList="selectList"
            :placeholder="title"
            :title="title"
          />
        </div>
        <div v-else-if="Detail.type == 'date'">
          <customInput
            v-model="value"
            type="date"
            :selectList="selectList"
            :placeholder="title"
            :title="title"
          />
        </div>
        <div v-else-if="Detail.type === 'multiSelect'">
          <multiSelectVue
            :placeholder="Detail.name"
            :label="Detail.name"
            v-model="value"
            :required="true"
            :taggable="false"
            :getOptions="this.Detail?.getOptions" />
        </div>
        <div v-else>
          <customInput
            v-model="value"
            type="text"
            min="2"
            max="255"
            :required="true"
            :is-error="true"
            ref="valueInput"
            :placeholder="title"
            :title="title"
          />
        </div>

        <requiredField />
        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="$t('cv.profile.personalInformation.components.edit.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import { cv } from "@/networking/urlmanager.js";
import moment from "moment";

export default {
  name: "edit-language",
  props: ["isEdit", "Detail", "index"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      id: "",
      value: "",
      inputType: "",
      title: "",
      selectList: "",
      commitName: "",
    };
  },
  methods: {
    show() {
      setTimeout(async () => {
        const {
          id,
          item,
          inputType,
          title,
          selectList,
          commitName,
          saveUrl,
        } = this.Detail;

        (this.id = id), (this.value = item);
        this.inputType = inputType;
        this.title = title;
        this.selectList = selectList;
        this.commitName = commitName;
        this.saveUrl = saveUrl;

        setTimeout(() => {
          if (inputType == "date") {
            this.value = item ? moment(item).format("YYYY-MM-DD") : "";
          }
        }, 50);
      }, 100);
      this.$modal.show("edit-information");
    },
    hide() {
      this.$modal.hide("edit-information");
    },
    save() {
      this.load = true;

      if (this.value?.trim()) {
        this.$emit("loadChance", true);
        this.$store.commit("setEditPersonalInformation", {
          id: this.id,
          value: this.value,
        });

        this.value = "";
        this.successMessage();

        this.load = false;
        this.$emit("loadChance", true);
        this.hide();
      } else {
        this.load = false;
      }
    },
    addValue() {
      switch (this.id) {
        case 1:
          return { dateBirth: moment(this.value).format("YYYY.MM.DD") };
          break;
        case 2:
          return { country: this.value };
          break;
        case 3:
          return { city: this.value };
          break;
        case 4:
          return { placeBirth: this.value };
          break;
        case 5:
          return { email: this.value };
          break;
        case 6:
          return { driverLicense: this.value };
          break;
        default:
          return false;
          break;
      }
    },
    add() {
      this.load = true;

      const data = this.addValue();

      if (data) {
        this.axios
          .post(cv.edit, {
            cvId: this.$route.params.id,
            ...data,
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.$emit("refresh", true);
            this.load = false;
            this.hide();
          })
          .catch((err) => {
            this.authController(err);
            this.load = false;
          });
      }
    },
  },
  watch: {
    selected() {
      this.value = "";
    },
  },
};
</script>
