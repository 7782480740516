<template>
  <div class="w-full z-50" data-v-step="3">
    <multiselect
      :options="List"
      :value="getList()"
      @select="onSelect"
      @remove="onRemove"
      :multiple="true"
      :close-on-select="true"
      :clear-on-select="true"
      :placeholder="$t('cv.components.projectList.addProjectPlaceholder')"
      label="companyAndProject"
      track-by="id"
      :taggable="true"
      :loading="load"
      @search-change="searchItem"
      :selectLabel="$t('cv.components.projectList.selectLabel')"
      :deselectLabel="$t('cv.components.projectList.deselectLabel')"
      :selected-label="$t('cv.components.projectList.selectedLabel')"
      :preselect-first="true"
    >
      <template v-slot:selection="{ values }">
        {{ values.length }} {{ $t("cv.components.projectList.projectLength") }}
      </template>
    </multiselect>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import { project } from "@/networking/urlmanager";
export default {
  props: ["value", "isEdit"],
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedList: [],
      List: [],
      search: "",
      load: false,
    };
  },
  methods: {
    searchItem(query) {
      this.search = query;
      this.getAll();
    },
    onSelect(val) {
      const findIndex = this.selectedList.findIndex(
        (item) => item.id == val.id
      );
      if (findIndex > -1) {
        const item = this.selectedList[findIndex];
        if (this.isEdit && item.stateId == 3 && item.rowId)
          this.selectedList[findIndex].stateId = 1;
        if (item.stateId != 1) this.selectedList[findIndex].stateId = 2;
      } else {
        const tag = {
          id: val.id,
          projectName: val.projectName,
          stateId: 2,
        };
        this.selectedList.push(tag);
      }
    },
    onRemove(val) {
      const findIndex = this.selectedList.findIndex(
        (item) => item.id === val.id
      );
      if (findIndex > -1) {
        if (this.isEdit) {
          this.selectedList[findIndex].stateId = 3;
        } else {
          this.selectedList.splice(findIndex, 1);
        }
      }
    },
    getList() {
      return this.selectedList.filter((r) => r.stateId != 3 && r.stateId != 5);
    },
    async getAll() {
      try {
        this.load = true;
        const response = await this.axios.post(project.getList, {
          search: (this.search = ""),
          matchingPositionList: JSON.stringify(this.$store.state.extractDetail.positionsToWork),
        });
        this.load = false;
        this.List = response.data.data.map(r=> {
          return{
            ...r,
            companyAndProject:this.formatText(r.projectName) + " / " + this.formatText(r.companyName)
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    this.selectedList = [];
    this.List = [];
    this.getAll();
    this.selectedList = [...this.$store.state.projectList];
  },
  watch: {
    selectedList(val) {
      this.$emit("change", val);
    },
    value(val) {
      this.selectedList = val;
    },
    "$store.state.projectList": function () {
      this.selectedList = this.$store.state.projectList;
    },
  },
};
</script>
