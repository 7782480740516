<template>
  <div class="signIn flex">
    <info />
    <div
      class="h-screen w-5/12 px-24 overflow-y-auto max-md:w-full max-lg:px-10 max-sm:px-6"
    >
      <logobutton />
      <div class="w-full my-3">
        <form @submit.prevent="signUp">
          <customInput
            v-model="companyName"
            type="text"
            min="3"
            max="30"
            :required="true"
            :placeholder="$t('auth.signUp.companyName')"
          />
          <customInput
            v-model="firstName"
            type="text"
            min="3"
            max="30"
            :required="true"
            :placeholder="$t('auth.signUp.name')"
          />
          <customInput
            v-model="lastName"
            type="text"
            min="3"
            max="30"
            :required="true"
            :placeholder="$t('auth.signUp.surname')"
          />
          <customInput
            v-model="email"
            type="text"
            min="3"
            max="30"
            :required="true"
            :placeholder="$t('auth.signUp.email')"
          />
          <customInput
            v-model="password"
            type="password"
            min="3"
            max="30"
            :required="true"
            :placeholder="$t('auth.signUp.password')"
          />
          <customInput
            v-model="passwordOnly"
            type="password"
            min="3"
            max="30"
            :required="true"
            :placeholder="$t('auth.signUp.passwordAgain')"
          />
          <button
            class="w-full py-2.5 bgcol mt-10 text-white rounded-lg font-semibold"
          >
              {{$t('auth.signUp.signUp')}}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import info from "./components/information.vue";
import logobutton from "./components/logobutton.vue";
import customInput from "@/components/diffInput.vue";

import { auth } from "@/networking/urlmanager";
import axios from "axios";
export default {
  name: "signUp",
  components: {
    info,
    logobutton,
    customInput,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordOnly: "",
      companyName: "",
    };
  },
  methods: {
    signUp() {
      if (
        /^[^@]+@\w+(\.\w+)+\w$/.test(this.email) &&
        this.companyName &&
        this.password &&
        this.passwordOnly &&
        this.firstName &&
        this.lastName
      ) {
        if (this.password === this.passwordOnly) {
          this.load = true;
          axios
            .post(auth.signUp, {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              password: this.password,
              companyName: this.companyName,
            })
            .then((res) => {
              this.load = false;
              this.$store.commit("signIn", res.data.data);
              this.$router.push("/general");
            })
            .catch((err) => {
              this.authController(
                err.response.data.message ? err.response.data.message : err
              );
              this.load = false;
            });
        } else {
          this.warningMesage(
           this.$t('auth.signUp.warningMessage')
          );
          this.load = false;
        }
      } else {
        this.warningMesage(
          this.$t('auth.signUp.warningMessageTwo')
          );
        this.load = false;
      }
    },
  },
};
</script>