<template>
  <div class="px-2 py-3">
    <div class="h-48 overflow-auto scroltype">
      <div
        v-for="item in users"
        :key="item"
        class="w-full flex items-center px-3 py-2.5"
      >
        <img
          src="@/assets/global/profile.png"
          alt=""
          class="bg-cover object-cover w-9"
        />
        <div class="flex justify-between w-full">
          <div class="pl-3">
            <h5 class="textcol font-semibold text-[13px]">{{ item.title }}</h5>
            <p class="text-xs textcol">{{ item.detail }}</p>
          </div>
          <div class="flex gap-3 justify-end">
            <button>
              <img
                src="@/assets/meeting/ses.svg"
                alt=""
                class="bg-cover w-[14px]"
              />
            </button>
            <button>
              <img
                src="@/assets/meeting/liveView.svg"
                alt=""
                class="bg-cover w-[14px]"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "usersComponents",
  data() {
    return {
      users: [
        {
          title: "Natura",
          detail: "Project Manager",
        },
        {
          title: "Natura",
          detail: "Project Manager",
        },
        {
          title: "Natura",
          detail: "Project Manager",
        },
        {
          title: "Natura",
          detail: "Project Manager",
        },
        {
          title: "Natura",
          detail: "Project Manager",
        },
      ],
    };
  },
};
</script>
