<template>
  <modal
    name="addUser"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '35%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">{{ $t("user.components.add.title") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form @submit.prevent="save()" class="p-5">
      <div class="grid grid-col-1 gap-y-3">
        <div class="flex justify-between items-center gap-x-2">
          <customInput
            v-model="firstName"
            type="text"
            min="3"
            max="40"
            :required="true"
            :title="$t('user.components.add.table.name')"
          />

          <customInput
            v-model="lastName"
            type="text"
            min="3"
            max="40"
            :required="true"
            :title="$t('user.components.add.table.surname')"
          />
        </div>

        <customInput
          v-model="email"
          type="email"
          min="3"
          max="40"
          :required="true"
          :title="$t('user.components.add.table.email')"
        />

        <customInput
          v-model="authority"
          type="select"
          :selectList="[
            {
              value: 1,
              name: $t('user.components.add.table.authorityList.executive'),
            },
            {
              value: 2,
              name: $t('user.components.add.table.authorityList.user'),
            },
          ]"
          :required="true"
          :placeholder="$t('user.components.add.table.authority')"
          :title="$t('user.components.add.table.authority')"
        />

        <!-- <customInput
          v-model="isHolding"
          type="select"
          :selectList="[
            {
              value: 1,
              name: $t('user.components.add.table.holdingCompanyList.myCompany'),
            },
            {
              value: 2,
              name: $t('user.components.add.table.holdingCompanyList.anotherCompany'),
            },
          ]"
          :required="true"
          placeholder="Holding"
          title="Holding"
        /> -->

        <!-- <selectSubCompany v-model="company" v-if="isHolding == 2"/> -->

        <requiredField />

        <buttonItem :load="load" buttonClass="w-full" />
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
//import selectSubCompany from "@/components/dev/selectSubCompany.vue";

import { users } from "@/networking/urlmanager";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

export default {
  name: "addUser",
  components: {
    customInput,
    //selectSubCompany,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,

      firstName: "",
      lastName: "",
      email: "",
      authority: 2,
      isHolding: 1,
      company: "",

      load: false,
    };
  },
  methods: {
    show() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.password = "";
      (this.authority = 2), this.$modal.show("addUser");
    },
    hide() {
      this.$modal.hide("addUser");
    },
    save() {
      if (
        /^[^@]+@\w+(\.\w+)+\w$/.test(this.email) &&
        this.firstName &&
        this.lastName
      ) {
        this.load = true;
        this.axios
          .post(
            users.add,
            {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              authority: this.authority,
              isHolding: this.isHolding,
              company: this.company,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then((res) => {
            this.successMessage(res.data.message);
            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.load = false;
            this.$emit("refresh", true);
            this.hide();
          })
          .catch((err) => {
            this.authController(err);
            this.load = false;
          });
      } else {
        this.warningMesage(this.$t("user.components.add.alertMessage"));
        this.load = false;
      }
    },
  },
  mount() {
    this.show();
  },
};
</script>

<style></style>
