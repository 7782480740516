<template>
  <modal
    name="edit-seminar-course"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.seminarAndCourse.components.edit.edit") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="isEdit ? edit() : save()"
      class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
    >
      <div class="space-y-4 pb-3">
        <customInput
          v-model="name"
          type="text"
          min="2"
          max="255"
          :required="true"
          :is-error="true"
          ref="name"
          :placeholder="
            $t(
              'cv.profile.seminarAndCourse.components.edit.sertificaPlacholder'
            )
          "
          :title="$t('cv.profile.seminarAndCourse.components.edit.sertifica')"
        />

        <customInput
          v-model="periotStart"
          type="date"
          :required="true"
          :is-error="true"
          ref="periotStart"
          :placeholder="
            $t('cv.profile.seminarAndCourse.components.edit.startPlacholder')
          "
          :title="$t('cv.profile.seminarAndCourse.components.edit.start')"
        />

        <customInput
          v-model="periotEnd"
          type="date"
          :placeholder="
            $t('cv.profile.seminarAndCourse.components.edit.finishPlacholder')
          "
          :title="$t('cv.profile.seminarAndCourse.components.edit.finish')"
        />

        <requiredField />

        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="$t('cv.profile.seminarAndCourse.components.edit.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import moment from "moment";
import requiredField from "@/components/requiredField.vue";

import { seminarAndCourse } from "@/networking/urlmanager.js";

export default {
  name: "edit-language",
  props: ["isEdit", "Detail", "index"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      rowId: "",
      name: "",
      periotStart: "",
      periotEnd: "",
    };
  },
  methods: {
    show() {
      setTimeout(() => {
        this.rowId = this.Detail.id;
        this.name = this.Detail.name;
        if (this.Detail.formatDate) {
          const data = this.Detail.formatDate.split("-");
          this.periotStart = data[0]
            ? moment(data[0]).format("YYYY-MM-DD")
            : "";
          this.periotEnd = data[1] ? moment(data[1]).format("YYYY-MM-DD") : "";
        }
      }, 50);
      this.$modal.show("edit-seminar-course");
    },
    hide() {
      this.$modal.hide("edit-seminar-course");
    },
    save() {
      this.load = true;

      this.checkErrors();

      if (this.name?.trim() && this.periotStart) {
        this.$emit("loadChance", true);
        this.$store.commit("editCourseCertificate", {
          index: this.index,
          list: {
            name: this.name,
            duration: this.getDateFormater(),
            formatDate: this.getDateFormater(),
          },
        });

        this.name = "";
        this.periotStart = "";
        this.periotEnd = "";
        this.successMessage();

        this.load = false;
        this.$emit("loadChance", true);
        this.hide();
      } else {
        this.load = false;
      }
    },
    edit() {
      this.load = true;

      this.checkErrors();

      if (this.name?.trim() && this.periotStart) {
        let list = [
          {
            name: this.name,
            duration: this.getDateFormater(),
            formatDate: this.getDateFormater(),
          },
        ];
        this.axios
          .post(seminarAndCourse.edit, {
            rowId: this.rowId,
            cvId: this.$route.params.id,
            list: JSON.stringify(list),
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.$emit("refresh", true);
            this.load = false;
            this.hide();
          })
          .catch((err) => {
            this.authController(err);
            this.load = false;
          });
      } else {
        this.load = false;
      }
    },
    getDateFormater() {
      let startDate = "";
      let endDate = "";

      if (this.periotStart) {
        startDate = moment(this.periotStart).format("YYYY.MM.DD");
      }
      if (this.periotEnd) {
        endDate = moment(this.periotEnd).format("YYYY.MM.DD");
      }

      return startDate + " - " + endDate;
    },
    checkErrors() {
      this.$refs.name.validateInput();
      this.$refs.periotStart.validateInput();
    },
  },
};
</script>
