<template>
  <div :class="`accordion my-2 ${wrapperClass}`">
    <div
      :class="`accordion-header cursor-pointer bg-gray-50 rounded-md text-black p-4 ${isOpen  && 'pb-0'} flex justify-between items-center ${headerClass}`"
      @click="toggle"
    >
      {{ item && item.header ? item.header : header }}
      <i :class="!isOpen ? 'fa-solid fa-chevron-right' :'fa-sharp fa-solid fa-chevron-down '"></i>
    </div>
    <div
      :class="`accordion-content transition-all duration-300 ease-in-out overflow-hidden bg-gray-50 rounded-b-md ${contentClass}`"
      :style="{ maxHeight: isOpen ? `${maxHeight}px` : '0', opacity: isOpen ? '1' : '0' }"
    >
      <div class="p-5">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion-item",
  props: {
    item: {
      type: Object,
      required: false,
    },
    header: {
      type: String,
      required: false,
    },
    index: {
      type: Number,
      required: true,
    },
    openedItem: {
      type: Number,
      default: null,
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    headerClass: {
      type: String,
      default: "",
    },
    contentClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      maxHeight: 0,
    }
  },
  computed: {
    isOpen() {
      return this.openedItem === this.index;
    },
  },
  methods: {
    toggle() {
      this.$emit("toggle", this.index);
      if (!this.isOpen) {
        this.maxHeight = this.$el.querySelector('.accordion-content').scrollHeight;
      } else {
        this.maxHeight = 0;
      }
    },
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.maxHeight = this.$el.querySelector('.accordion-content').scrollHeight;
        });
      }
    }
  }
};
</script>
