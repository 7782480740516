<template>
    <ul v-if="load || length == 0"  class="text-gray-800 h-full text-center flex items-center justify-center text-base" >
      <li v-if="load" :colspan="colspan" class="p-4">
        <i class="fas fa-spinner fa-spin"></i>
        {{ loadMessage ? loadMessage : $t("project.projectUser.components.shortList.components.personelInformation.utility.card.loading") }}
      </li>
      <li v-if="!load && length == 0" :colspan="colspan" class="p-4 text-black">
        {{ $t("globalComponents.tableLoader.tableLoaderTitle") }}
      </li>
    </ul>
</template>

<script>
export default {
  name: "table-loader",
  props: ["load", "length", "colspan",'loadMessage'],
};
</script>
