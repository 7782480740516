<template>
  <div class="w-full z-[997] top-0 shadow-lg px-12 flex items-center justify-between align-middle sticky h-24 bg-[#878DA5] max-lg:px-8 max-md:px-5"  align="right">
    <div class="flex items-center w-5/12 gap-6 max-md:w-1/12">

      <button @click="toggleSidebar">
        <i class="fa-solid fa-bars text-white text-2xl"></i>
      </button>

      <!-- <div class="bg-indigo-50 h-9 w-full flex rounded-md max-md:hidden relative">
        <input type="text" class="h-full bg-indigo-50 w-full rounded-md outline-none pl-3 xplaceholder:textcol"
          placeholder="Arama" />
        <div class="flex px-3 justify-center items-center">
          <i class="fa-solid fa-magnifying-glass textcol"></i>
        </div>
      </div> -->

    </div>
    <div
      class="w-7/12 flex justify-end items-center gap-6 max-md:w-11/12 max-md:gap-4"
    >
      <div class="flex items-center gap-4 z-50">
        <button
          v-if="this.$store.state.userData.authority != 2 && isLog"
          @click="logToggle"
          data-v-step="7"
        >
          <img src="@/assets/cv/history.png" alt="" class="w-5" />
        </button>

        <languageSelect />
        <notification />
        
      </div>
      <!-- <button>
        <img src="@/ass ts/img/mesage.svg" alt="" />
      </button> -->
      <profile />
    </div>
  </div>
</template>
<script>
import profile from "./components/profile.vue";
import notification from "./components/notification/index.vue";
import languageSelect from "./components/language.vue";

export default {
  name: "nav-bar",
  props: ["toggleSidebar", "isSidebar", "logToggle", "isLog"],
  components: {
    profile,
    notification,
    languageSelect,
  },
};
</script>
