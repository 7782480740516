<template>
  <div
    v-if="load || lenghtItem == 0"
    class="h-[77vh] flex justify-center items-center"
  >
    <div v-if="load" class="text-center">
      <i class="fas fa-spinner fa-spin fa-2x"></i>
      <label class="block mt-2">{{
        $t("cv.components.cvListModal.resumeLoading")
      }}</label>
    </div>
    <div v-if="!load && lenghtItem == 0">
      <label class="block mt-2">{{
        $t("cv.components.cvListModal.notFoundedResumeListed")
      }}</label>
    </div>
  </div>
</template>
<script>
export default {
  props: ["load", "lenghtItem"],
  name:"loading-items"
};
</script>