<template>
  <div class="w-full">
    <multiselect
      :options="filterList"
      :value="getList()"
      @select="onSelect"
      @remove="onRemove"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :internal-search="false"
      @search-change="asyncFind"
      placeholder=""
      label="name"
      track-by="id"
      :taggable="true"
      @tag="addTag"
      :selectLabel="$t('globalComponents.dev.positionSelect.add')"
      :deselectLabel="$t('globalComponents.dev.positionSelect.delete')"
      :loading="load"
      :preselect-first="true"
    >
      <template v-slot:selection="{ values }">
        {{ values.length }}
        {{ $t("globalComponents.dev.positionSelect.positionChosen") }}
      </template>
    </multiselect>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import { utilityPosition } from "@/networking/urlmanager";
import Fuse from "fuse.js";

export default {
  props: ["value", "isEdit"],
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedList: [],
      List: [],
      filterList: [],
      load: false,
      isActive: true,
      fuse: null,
    };
  },
  methods: {
    onSelect(val) {
      const findIndex = this.selectedList.findIndex(
        (item) => item.id == val.id
      );
      if (findIndex > -1) {
        const item = this.selectedList[findIndex];
        if (this.isEdit && item.status == 3 && item.rowId)
          this.selectedList[findIndex].status = 1;
        if (item.status != 1) this.selectedList[findIndex].status = 2;
      } else {
        const tag = {
          id: val.id,
          name: val.name,
          status: 2,
        };
        this.selectedList.push(tag);
      }
    },
    onRemove(val) {
      const findIndex = this.selectedList.findIndex((r) => r.id == val.id);
      if (this.isEdit) this.selectedList[findIndex].status = 3;
      else this.selectedList.splice(findIndex, 1);
    },
    addTag(newTag) {
      let tag = {
        name: newTag,
        id: newTag,
        status: 2,
      };

      this.verifiedMessage(
        "'" +
          newTag +
          this.$t("globalComponents.dev.positionSelect.addQuestion"),
        this.$t("globalComponents.dev.positionSelect.addReply")
      ).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.axios.post(utilityPosition.add, {
              name: newTag,
            });
            tag.id = response.data.data;

            this.List.push(tag);
            this.selectedList.push(tag);
          } catch (err) {
            console.log(err);
            this.authController(err);
          }
        }
      });
    },
    getList() {
      return this.selectedList.filter((r) => r.status != 3);
    },
    asyncFind(val) {
      if (!this.fuse) return;

      if (!val) {
        this.filterList = [...this.List];
        return;
      }

      const results = this.fuse.search(val);
      const newFilterList = results.map((result) => result.item);
      this.filterList = newFilterList;
    },
    async getAll() {
      try {
        this.load = true;
        const response = await this.axios.get(utilityPosition.getAll);
        this.List = response.data.data;
        this.filterList = response.data.data;
        this.load = false;

        const options = {
          keys: ["name"],
          includeScore: false,
          threshold: 0.4,
        };
        this.fuse = new Fuse(this.List, options);
      } catch (error) {
        this.load = false;
        console.log("pozisyon yüklenirken bir hata oluştu:", error);
      }
    },
  },
  async created() {
    await this.getAll();
    this.selectedList = this.value;
  },
  watch: {
    selectedList(val) {
      this.filterList.sort((a, b) => {
        const indexA = this.selectedList.findIndex((r) => r.id === a.id);
        const indexB = this.selectedList.findIndex((r) => r.id === b.id);
        return indexB - indexA;
      });
      this.$emit("change", val);
    },
    value(val) {
      this.selectedList = val;
    },
  },
};
</script>
