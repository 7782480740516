<template>
  <div
    class="my-10 w-full max-sm:my-8 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4"
  >
    <supportAdd ref="supportAdd" @refresh="getAll()" />

    <button
      data-v-step="0"
      class="px-5 py-2 bgcol h-11 text-sm text-white rounded"
      @click="$refs.supportAdd.show()"
    >
      <i class="fas fa-plus mr-2"></i>
      {{ $t("support.index.add") }}
    </button>

    <div class="tableOverflow scroltypey mt-5">
      <div class="tableWidth">
        <div class="tableContainer">
          <div class="tableHead" data-v-step="1">
            <ul class="tableUl">
              <li class="w-[5%]">#</li>
              <li class="w-[18%]">{{ $t("support.index.table.user") }}</li>
              <li class="w-[32%]">{{ $t("support.index.table.subject") }}</li>
              <li class="w-[18%]">{{ $t("support.index.table.date") }}</li>
              <li class="w-[27%] flex justify-end">
                <div class="w-[300px] tableSearch relative">
                  <input
                    v-model="search"
                    @keypress.enter="getSearch()"
                    type="text"
                    class="h-full w-full outline-none pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
                    :placeholder="$t('support.index.table.searchPlacholder')"
                  />
                  <button class="px-3 textcol absolute right-0">
                    <i
                      @click="getSearch()"
                      class="fa-solid fa-magnifying-glass"
                    ></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div class="tableBody scroltype">
            <div class="tableBodyContainer">
              <ul
                v-for="(item, index) in List"
                :key="index"
                class="tableBodyUl"
              >
                <li
                  @click="$router.push('/support/' + item.id)"
                  class="select-none cursor-pointer w-[5%] py-2.5 flex items-center"
                >
                  {{ index + 1 }}
                </li>
                <li
                  @click="$router.push('/support/' + item.id)"
                  class="select-none cursor-pointer w-[18%] py-2.5"
                >
                  {{
                    formatText(
                      item.fullname.length > 23
                        ? item.fullname.substring(0, 23) + "..."
                        : item.fullname
                    )
                  }}
                </li>
                <li
                  @click="$router.push('/support/' + item.id)"
                  class="select-none cursor-pointer w-[32%] py-2.5"
                >
                  {{
                    formatText(
                      item.subject.length > 45
                        ? item.subject.substring(0, 45) + "..."
                        : item.subject
                    )
                  }}
                </li>
                <li
                  @click="$router.push('/support/' + item.id)"
                  class="select-none cursor-pointer w-[18%] py-2.5"
                >
                  {{ item.addDate | getDate }}
                </li>

                <li
                  @click="$router.push('/support/' + item.id)"
                  class="select-none cursor-pointer w-[27%] flex gap-6 max-md:gap-3 justify-between px-3"
                >
                  <p
                    :class="{
                      'text-red-400': item.stateId == 1,
                      'text-green-500': item.stateId == 2,
                      'text-black-500': item.stateId == 3,
                    }"
                  >
                    {{
                      item.stateId == 1
                        ? $t("support.index.table.replyWaiting")
                        : item.stateId == 2
                        ? $t("support.index.table.answered")
                        : $t("support.index.table.close")
                    }}
                  </p>
                  <div class="flex gap-4 justify-center">
                    <router-link
                      :to="'/support/' + item.id"
                      class="flex items-center h-full"
                      data-v-step="2"
                    >
                      <i class="fa-solid fa-magnifying-glass text-base"></i>
                    </router-link>
                  </div>
                </li>
              </ul>
              <tableLoader :load="load" :length="List.length" colspan="7" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <tour
      :steps="steps"
      :moduleId="5"
      v-if="!$store.state.userData.tourModuleId.includes(5)"
    />
    <pagination
      v-model="currentPage"
      :pageCount="pageCount"
      :totalRowCount="totalCount"
    />
  </div>
</template>
<script>
import pagination from "@/components/pagination.vue";
import tableLoader from "@/components/table-loader.vue";
import supportAdd from "./components/add.vue";
import { support } from "@/networking/urlmanager";
import { supporttour } from "@/tourmodel/tour.js";
import moment from "moment";
import "moment/locale/tr";
import tour from "@/components/tour.vue";
export default {
  name: "support-page",
  components: {
    pagination,
    tableLoader,
    supportAdd,
    tour,
  },
  data() {
    return {
      load: false,

      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,
      steps: supporttour,
    };
  },
  methods: {
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    getAll() {
      this.load = true;
      this.List = [];
      this.axios
        .get(
          support.getAll +
            "?page=" +
            this.currentPage +
            "&search=" +
            this.search
        )
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;
          this.List = detail;
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getAll();
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return this.$t("support.index.noDate");
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
};
</script>
