var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"scroltype",attrs:{"name":"addInterview","height":_vm.isMobile ? '100%' : 'auto',"width":_vm.isMobile ? '100%' : '40%',"scrollable":true}},[_c('div',{staticClass:"flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"},[_c('h1',{staticClass:"text-xl"},[_vm._v(" "+_vm._s(_vm.$t("project.projectUser.components.interview.components.add.addQuestion"))+" ")]),_c('button',{on:{"click":function($event){return _vm.hide()}}},[_c('i',{staticClass:"fas fa-times"})])]),_c('form',{staticClass:"p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20",on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('div',{staticClass:"space-y-4 pb-3"},[_c('div',{staticClass:"flex gap-10 max-sm:flex-wrap max-sm:gap-3 w-ull"},[_c('div',{staticClass:"w-full relative"},[_c('customInput',{attrs:{"type":"text","min":"5","max":"2500","required":true,"placeholder":_vm.$t('project.projectUser.components.interview.components.add.placeholderAddQuestion'),"title":_vm.$t('project.projectUser.components.interview.components.add.question')},model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}})],1)]),_c('div',{staticClass:"flex w-full gap-5"},[_c('customInput',{attrs:{"type":"select","title":_vm.$t('project.projectUser.components.interview.components.add.questionType'),"selectList":[
            {
              value: 1,
              name: _vm.$t(
                'project.projectUser.components.interview.components.add.question'
              ),
            },
            {
              value: 2,
              name: _vm.$t(
                'project.projectUser.components.interview.components.add.decisionQuestion'
              ),
            },
          ],"required":true},model:{value:(_vm.questionState),callback:function ($$v) {_vm.questionState=$$v},expression:"questionState"}})],1),(_vm.questionState == 2)?_c('div',[_c('div',{staticClass:"flex w-full gap-5"},[_c('customInput',{attrs:{"type":"select","title":_vm.$t('project.projectUser.components.interview.components.add.decisionQuestionAnswer'),"selectList":[
              {
                value: 1,
                name: _vm.$t(
                  'project.projectUser.components.interview.components.add.yes'
                ),
              },
              {
                value: 2,
                name: _vm.$t(
                  'project.projectUser.components.interview.components.add.no'
                ),
              },
            ],"required":true},model:{value:(_vm.expectedAnswer),callback:function ($$v) {_vm.expectedAnswer=$$v},expression:"expectedAnswer"}})],1)]):_vm._e(),_c('div',{staticClass:"flex w-full gap-5"},[_c('customInput',{attrs:{"type":"select","title":_vm.$t(
          'project.projectUser.components.interview.components.add.shouldChange'
        ),"selectList":[
            {
              value: 1,
              name: _vm.$t(
                'project.projectUser.components.interview.components.add.yes'
              ),
            },
            {
              value: 2,
              name: _vm.$t(
                'project.projectUser.components.interview.components.add.no'
              ),
            },
          ],"required":true},model:{value:(_vm.isEditQuestion),callback:function ($$v) {_vm.isEditQuestion=$$v},expression:"isEditQuestion"}})],1),_c('div',{staticClass:"w-full relative"},[_c('customInput',{attrs:{"type":"number","min":"0","max":"100","required":true,"placeholder":"Değerlendirme Puanı  ","title":" Değerlendirme Puanı"},model:{value:(_vm.weightedScore),callback:function ($$v) {_vm.weightedScore=$$v},expression:"weightedScore"}})],1),_c('div',{staticClass:"flex w-full gap-5"}),_c('requiredField'),_c('div',{staticClass:"flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"},[_c('div',{staticClass:"w-full"},[_c('buttonItem',{attrs:{"load":_vm.load,"name":_vm.$t(
            'project.projectUser.components.interview.components.add.save'
          ),"buttonClass":"w-full"}})],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }