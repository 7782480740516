<template>
    <div @click="(e) => {
        e.stopPropagation()
        if (item.stateId === 2 || item.disabled) return
        toggleCollapse()
    }" class="p-4 bg-gray-50 rounded-xl flex flex-col justify-between items-start cursor-pointer"
        :class="(item.stateId === 2 || item.disabled) && 'opacity-50'">
        <div class="flex-shrink-0 flex justify-between w-full gap-2 items-center relative">
            <Checkbox :disabled="item.stateId === 2" :checked="item.selected" :toggle="toggleSelect"
                :label="item.fullname" />


            <div class="flex gap-4 text-xs font-medium" :class="stateColors[item.stateId]">
                {{ item.stateName }}
                <div class="pr-2 text-gray-600">
                    <i v-if="item.stateId === 2" class="fas fa-spinner fa-spin" />

                    <i v-else class="fas fa-chevron-down" :class="isOpen ? 'transform rotate-180' : ''" />
                </div>
            </div>

        </div>

        <div class="pl-10 w-full text-sm text-gray-500 h-full transition-all overflow-hidden"
            :class="isOpen ? 'opacity-100' : 'opacity-0'" :style="{ maxHeight: isOpen ? (height + 4) + 'px' : '0px' }">
            <ul ref="itemRef" class="divide-y divide-gray-200" @click="(e) => e.stopPropagation()">
                <li class="flex justify-between gap-2 py-2 pr-2" v-for="question in item.questions" :key="question.id"
                    :class="questionLoading ? 'animate-pulse pointer-events-none' : ''">
                    <span v-if="editIndex !== question.id" class="text-gray-600 font-medium"
                        @dblclick="() => editQuestion(question.id)">
                        {{ formatText(question.name) }}
                    </span>

                    <input v-else :value="editedQuestion.name" autofocus
                        @input="(e) => updateEditedQuestion(e.target.value)" type="text"
                        class="bg-transparent border border-gray-300 rounded-md w-full px-2 py-1 m-0 resize-none"
                        @keydown.enter="(e) => saveQuestionHandle(question.id, editedQuestion)"
                        @blur="() => editIndex = -1" @keydown.esc="() => editIndex = -1" />

                    <button class="text-gray-400 hover:text-gray-600" @click="() => editIndex !== question.id ?
                        editQuestion(question.id) :
                        saveQuestionHandle(question.id, editedQuestion)">
                        <i v-if="editedQuestion?.id === question.id && questionLoading"
                            class="fas fa-spinner animate-spin" />
                        <i v-else-if="editIndex !== question.id" class="fas fa-edit" />
                        <i v-else class="fas fa-check" />
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Checkbox from './interviewCheckbox.vue';
const stateColors = {
    2: 'text-yellow-500',
    3: 'text-green-500',
    10: 'text-red-500'
}

export default {
    props: {
        item: Object,
        isOpen: Boolean,
        toggleCollapse: Function,
        toggleSelect: Function,
        saveQuestion: Function,
        index: Number,
    },
    components: { Checkbox },
    data() {
        return {
            editedQuestion: null,
            editIndex: -1,
            height: 0,
            originQuestionVal: '',
            questionLoading: false,
            stateColors
        }
    },
    mounted() {
        this.updateHeight();
    },
    methods: {
        updateHeight() {
            const el = this.$refs.itemRef;
            this.$nextTick(() => {
                this.height = el.clientHeight;
            });
        },
        updateEditedQuestion(value) {
            this.originQuestionVal = value;
        },

        editQuestion(questionIndex) {
            this.editedQuestion = this.item.questions.find((question) => question.id === questionIndex);
            this.editIndex = questionIndex;
            this.lastEditedQuestion = this.item.questions.find((question) => question.id === this.editIndex);
        },

        async saveQuestionHandle(questionIndex, editedQuestion) {
            this.editIndex = -1;
            this.questionLoading = true;

            if (!this.originQuestionVal.length) {
                this.questionLoading = false;
                return;
            }

            await this.saveQuestion(this.index, questionIndex, {
                id: editedQuestion.id,
                name: this.originQuestionVal
            }).then(() => {
                this.editedQuestion.name = this.originQuestionVal;
            }).catch((err) => {
                console.log('err', err)
            }).finally(() => {
                this.questionLoading = false;
            });

            this.updateHeight()
        }
    }
}
</script>
