<template>
  <div class="w-full flex max-sm:flex-wrap mt-10">
    <div class="w-full max-sm:w-full">
      <div class="flex justify-between max-sm:flex-col-reverse">
        <h4 class="text-lg tracking-wider textcol font-semibold">
          <span class="border-t-2 pt-1 border-[#344CA0]">{{ $t('cv.profile.slills.index.title') }}</span>
        </h4>

        <addItem ref="addModal" :isEdit="isEdit" @refresh="getAll()" />
        <editItem
          ref="editItem"
          :isEdit="isEdit"
          @refresh="getAll()"
          :Detail="selected"
          :index="index"
          @loadChance="loading = !loading"
        />

        <div
          class="flex justify-end items-center gap-5 max-sm:justify-between max-sm:mb-6"
        >
          <div class="relative group flex justify-center">
            <button
              @click="$refs.addModal.show()"
              class="w-20 py-2 text-base rounded text-black"
            >
              <i class="fas fa-plus mr-1"></i>
              {{ $t('cv.profile.slills.index.add') }}
            </button>
            <tooltip
              :title="$t('cv.profile.slills.index.toolTip')"
              tipClass=" bottom-8 max-sm:ml-2 text-center"
            />
          </div>

        </div>
      </div>
      <div
        v-if="isContent && !loading"
        class="grid grid-cols-2 max-md:grid-cols-1 gap-y-2 gap-x-4 relative"
      >
        <div
          class="w-full border-b border-b-[#344ba045] pb-4 border-dotted avoid-page-break"
          v-for="(item, index) in $store.state.extractDetail.skills"
          :key="index"
        >
          <card
            :img="require('@/assets/cv/skills.png')"
            :rowId="item.id"
            :title="item.name"
            :sub="isLevel(item.level)"
            :index="index"
            @deleteItem="!isEdit ? deleteStoreItem(index) : deleteItem(item)"
            @editItem="editItem(item, index)"
          />
        </div>
      </div>

      <dataLoader
        :load="loading"
        :isContent="isContent"
        :title="$t('cv.profile.slills.index.dataLoader')"
      />
    </div>
  </div>
</template>
<script>
import card from "../components/card.vue";
import { skills } from "@/networking/urlmanager.js";
import tooltip from "@/components/tooltip.vue";
import addItem from "./components/add.vue";
import editItem from "./components/edit.vue";
import dataLoader from "../components/dataLoader.vue";

export default {
  name: "computer-skills-page",
  props: ["load", "isEdit"],
  components: {
    card,
    tooltip,
    addItem,
    editItem,
    dataLoader,
  },
  data() {
    return {
      loading: false,
      selected: "",
      index: "",
    };
  },
  methods: {
    isLevel(val) {
      if (val == 1) {
        return this.$t('cv.profile.slills.index.beginner');
      } else if (val == 2) {
        return this.$t('cv.profile.slills.index.intermediateLevel');
      } else if (val == 3) {
        return this.$t('cv.profile.slills.index.advancedLevel');
      } else {
        return this.$t('cv.profile.slills.index.unspecified');
      }
    },
    getAll() {
      this.loading = true;
      this.axios
        .get(skills.getAll + "?cvId=" + this.$route.params.id)
        .then((res) => {
          this.$store.commit("setSkils", res.data.data);
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(skills.delete, {
              rowId: item.id,
            })
            .then((res) => {
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    deleteStoreItem(index) {
      this.verifiedMessage().then((acc) => {
        if (acc.isConfirmed) {
          this.$store.commit("deleteSkill", index);
        }
      });
    },
    editItem(val, index) {
      this.$refs.editItem.show();
      this.selected = val;
      this.index = index;
    },
  },
  computed: {
    isContent() {
      if (Array.isArray(this.$store.state.extractDetail.skills))
        if (this.$store.state.extractDetail.skills.length > 0) return true;
        else return false;
      else return false;
    },
  },
};
</script>
    