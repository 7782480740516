<template>
  <div class="mb-5 cursor-pointer">
    <div
      class="w-full h-24 bg-indigo-100 rounded-2xl border-2 border-dashed border-[#182451]"
      v-if="
        ($store.state.userData.isExtract == 1 ||
          $store.state.userData.isExtract == 3) &&
        load == false
      "
    >
      <label
        for="fileInput"
        class="px-20 max-sm:px-10 cursor-pointer flex items-center h-full justify-center"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <input
          type="file"
          multiple
          name="file"
          id="fileInput"
          class="hidden"
          @change="onChange"
          ref="file"
          accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.jpeg"
        />

        <div class="w-full flex justify-center cursor-pointer">
          <div class="text-[#182451]" v-if="isDragging">
            {{ $t("cv.components.uploadPanel.dropFiles") }}
          </div>
          <div class="text-[#182451]" v-if="!isDragging">
            {{ $t("cv.components.uploadPanel.title") }}
          </div>
        </div>
      </label>
    </div>

    <div v-else-if="$store.state.userData.isExtract == 2 || load == true"
      class="w-full h-24 bg-indigo-100 rounded-2xl border-2 border-dashed border-[#182451]">
      <div class="px-20 max-sm:px-10 flex items-center h-full justify-center">
        <label for="fileInput" class="w-full text-center p-2">
          <div
            class="w-8 h-8 rounded-full border-[#182451] animate-spin inline-block border-2 border-solid border-t-transparent shadow-md">
          </div>
          <p class="block w-full text-xs text-[#182451]">
            {{
              LoadFilter()
            }}
          </p>
        </label>
      </div>
    </div>

    <!-- <button
    filter kullanılacak
      @click="$refs.modal.toggleSidebar()"
      v-else
      class="pointer block w-full h-24 bg-[#182451] rounded-xl"
    >
      <div
        class="px-20 max-sm:px-10 flex items-center h-full justify-center cursor-pointer animate-pulse"
      >
        <label for="fileInput" class="w-full text-center p-2 cursor-pointer">
          <i class="fas fa-check text-4xl text-white"></i>
          <label
            class="block w-full mt-2 text-sm text-white text-opacity-60 cursor-pointer font-bold hover:text-opacity-100"
          >
            Yüklenen Özgeçmişleri Görüntüle
          </label>
        </label>
      </div>
    </button> -->

    <!-- resume list sidebar -->
  </div>
</template>
<script>
import { cv } from "@/networking/urlmanager.js";
import axios from "axios";
export default {
  name: "uploadPanel",
  data() {
    return {
      isDragging: false,
      files: [],
      load: false,
      invalidFiles: [],
      percentage: "",
      verify: false,
    };
  },

  methods: {
    async uploadFiles() {
      try {
        this.percentage = "";
        const res = await this.axios.post(cv.addExtractor);
        this.save(res.data.data);
      } catch (err) {
        this.load = false;
        this.authController(err);
      }
    },
    async save(rowId) {
      this.load = true;
      const files = this.files;
      const batchSize = 10;
      const token = this.$store.state.userData.token;
      let invalidFiles = [];
      this.$store.commit("setInvalidFiles", invalidFiles);

      try {
        const totalFiles = files.length;

        for (let start = 0; start < totalFiles; start += batchSize) {
          const end = Math.min(start + batchSize, totalFiles);
          const batch = files.slice(start, end);

          const formData = new FormData();
          batch.forEach((file) => {
            formData.append("file", file);
            this.$store.commit("setCvFile", file);
          });
          formData.append("extractId", rowId);

          const res = await axios.post(cv.extractor, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + token,
            },
          });

          if (res?.data?.data?.invalidFiles?.length > 0) {
            res.data.data.invalidFiles.map((r) => invalidFiles.push(r));
          }
          if (res?.data?.data?.addFiles?.length > 0) {
            this.$store.commit("setExtractState", 3);
          }
          this.percentage = (end / totalFiles) * 100;
        }
        if (invalidFiles?.length > 0) {
          this.$store.commit("setInvalidFiles", invalidFiles);
          this.successUpload();
        } else {
          this.successUpload();
        }
      } catch (err) {
        this.load = false;
        this.authController(err);
      }
    },
    successUpload() {
      setTimeout(() => {
        this.load = false;
        this.successMessage();
      }, 100);
    },
    onChange() {
      const selectedFiles = this.$refs.file.files;
      const allFilesAreValid = Array.from(selectedFiles).every((file) => {
        //const fileExtension = file.name.split(".").pop().toLowerCase();
        return true;
        //return ["pdf", "docx", "doc"].includes(fileExtension);
      });

      if (allFilesAreValid) {
        this.files = [...selectedFiles];
        this.uploadFiles();
      } else {
        this.warningMesage(this.$t("cv.components.uploadPanel.warningMessage"));
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    LoadFilter() {
      return this.load ? this.$t("cv.components.uploadPanel.loading") +
        (this.percentage ? Math.floor(this.percentage) : 0) +
        "%"
        : this.$t("cv.components.uploadPanel.parsing")
    }
  },
};
</script>
<style scoped>
.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100vh;
  justify-content: center;
  text-align: center;
}

.dropzone-container {
  padding: 4rem;
  background: #f7fafc;
  border: 1px solid #e2e8f0;
}
