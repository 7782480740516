<template>
  <modal name="edit-school" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '40%'" :scrollable="true">
    <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white">
      <h1 class="text-lg">
        {{ $t("cv.profile.schoolAndEducation.components.edit.edit") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form @submit.prevent="isEdit ? add() : save()" class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20">
      <div class="space-y-4 pb-3">
        <multiSelectVue :placeholder="$t('cv.profile.schoolAndEducation.components.add.scoolName')
          " :label="$t('cv.profile.schoolAndEducation.components.add.scoolName')
            " ref="SchoolNameRef" v-model="SchoolName" :required="true" :getOptions="getSchools" />

        <multiSelectVue :placeholder="$t('cv.profile.schoolAndEducation.components.add.departmentPlacholder')
          " :label="$t('cv.profile.schoolAndEducation.components.add.departmentPlacholder')
            " ref="departmentRef" v-model="department" :required="true" :getOptions="getDepartments" />

        <customInput v-model="typeName" type="select" :selectList="typeList" :required="true" :is-error="true"
          ref="typeName" :placeholder="$t('cv.profile.schoolAndEducation.components.edit.typePlacholder')
            " :title="$t('cv.profile.schoolAndEducation.components.edit.type')" />

        <div class="grid grid-cols-2 gap-2">
          <div>
            <label class="textcol font-semibold flex gap-1 items-center">{{
              $t("cv.profile.schoolAndEducation.components.edit.startDate")
            }}
              <p class="text-[10px]">
                <i class="fa-sharp fa-solid fa-star-of-life text-[7px]"></i>
              </p>
            </label>
            <datepicker v-model="periotStart" :format="DatePickerFormat" minimum-view="year" name="datepicker"
              id="input-id" :input-class="'w-full border bordercol rounded py-3 outline-none px-3 text-xs'">
            </datepicker>
          </div>
          <div>
            <label class="textcol font-semibold flex gap-1 items-center">{{
              $t("cv.profile.schoolAndEducation.components.edit.finshDate")
              }}</label>
            <datepicker v-model="periotEnd" :format="DatePickerFormat" minimum-view="year" name="datepicker"
              id="input-id" :input-class="'w-full border bordercol rounded py-3 outline-none px-3 text-xs'">
            </datepicker>
          </div>
        </div>

        <customInput v-model="doesItContinue" type="select" :selectList="[
          {
            value: 1,
            name: $t(
              'cv.profile.schoolAndEducation.components.edit.continues'
            ),
          },
          {
            value: 2,
            name: $t(
              'cv.profile.schoolAndEducation.components.edit.completed'
            ),
          },
        ]" :placeholder="$t(
          'cv.profile.schoolAndEducation.components.edit.situationPlacholder'
        )
          " :title="$t('cv.profile.schoolAndEducation.components.edit.situation')" />

        <requiredField />

        <div class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0">
          <div class="w-full">
            <buttonItem :load="load" :name="$t('cv.profile.schoolAndEducation.components.edit.save')"
              buttonClass="w-full" />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import moment from "moment";
import "moment/locale/tr";

import datepicker from "vuejs-datepicker";

import { educationLife, utility } from "@/networking/urlmanager.js";
export default {
  name: "addProject",
  props: ["isEdit", "Detail", "index"],
  components: {
    customInput,
    buttonItem,
    datepicker,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      rowId: "",
      SchoolName: null,
      department: null,
      periotStart: "",
      periotEnd: "",
      typeName: 3,
      doesItContinue: 0,

      DatePickerFormat: "yyyy",

      typeList: [
        {
          value: 0,
          name: this.$t(
            "cv.profile.schoolAndEducation.components.edit.unspecified"
          ),
        },
        {
          value: 1,
          name: this.$t(
            "cv.profile.schoolAndEducation.components.edit.highSchool"
          ),
        },
        {
          value: 2,
          name: this.$t(
            "cv.profile.schoolAndEducation.components.edit.associateDegree"
          ),
        },
        {
          value: 3,
          name: this.$t(
            "cv.profile.schoolAndEducation.components.edit.licence"
          ),
        },
        {
          value: 4,
          name: this.$t("cv.profile.schoolAndEducation.components.edit.degree"),
        },
        {
          value: 5,
          name: this.$t(
            "cv.profile.schoolAndEducation.components.edit.doctorate"
          ),
        },
      ],
    };
  },
  methods: {
    show() {
      setTimeout(() => {
        this.SchoolName = this.Detail.name;
        this.department = this.Detail.department;
        if (this.Detail.formatDate) {
          const data = this.Detail.formatDate.split("-");
          this.periotStart = data[0] || "";
          this.periotEnd = data[1] || "";
        }
        this.typeName = this.Detail.status;
        this.doesItContinue = this.Detail.doesItContinue;
        this.rowId = this.Detail.id;
      }, 50);
      this.$modal.show("edit-school");
    },
    hide() {
      this.$modal.hide("edit-school");
    },
    save() {
      this.load = true;

      this.checkErrors();

      this.$emit("loadChance", true);
      this.$store.commit("editSchool", {
        index: this.index,
        list: {
          name: this.SchoolName,
          department: this.department,
          duration: this.periotStart + "-" + this.periotEnd,
          formatDate: this.periotStart + "-" + this.periotEnd,
          status: this.typeName,
          doesItContinue: this.doesItContinue,
        },
      });

      this.SchoolName = null;
      this.department = null;
      this.periotStart = "";
      this.periotEnd = "";
      this.typeName = "";
      this.doesItContinue = 0;

      if (!this.isEdit) {
        this.successMessage();
      }

      this.load = false;
      this.$emit("loadChance", true);
      if (this.isEdit) {
        this.$emit("refresh", true);
      }
      this.hide();

    },
    add() {
      this.load = true;

      this.checkErrors();

      let list = [
        {
          name: this.SchoolName,
          department: this.department,
          duration: this.periotStart + "-" + this.periotEnd,
          formatDate: this.periotStart + "-" + this.periotEnd,
          status: this.typeName,
          doesItContinue: this.doesItContinue,
        },
      ];

      let schoolAndEducation = this.$store.state.extractDetail.schoolAndEducation;

      this.axios
        .post(educationLife.edit, {
          rowId: this.rowId,
          cvId: this.$route.params.id,
          list: JSON.stringify(list),
          educationList: schoolAndEducation,
        })
        .then((res) => {
          this.successMessage(res.data.message);
          this.save();
          this.load = false;
          this.hide();
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
          this.load = false;
        });
    },
    educationCalculator(val) {
      if (val.length > 0) {
        let list = val
          .filter((r) => r.id != this.rowId)
          .sort(function (a, b) {
            return b.status - a.status;
          });
        if (list.length > 0) {
          return this.typeName > list[0].status
            ? this.typeName
            : list[0].status;
        } else {
          return this.typeName;
        }
      } else {
        return this.typeName;
      }
    },
    async getSchools() {
      const response = await this.axios.get(utility.getInstitutionAll);
      return response.data.data;
    },
    async getDepartments() {
      const response = await this.axios.get(utility.getDepartmentAll);
      return response.data.data;
    },
    checkErrors() {
      this.$refs.SchoolNameRef.validate();
      this.$refs.departmentRef.validate();
      this.$refs.typeName.validateInput();
    },
  },
  watch: {
    periotStart(newYear) {
      const dateObject = moment(newYear);
      this.periotStart = dateObject.format("YYYY");
    },
    periotEnd(newYear) {
      const dateObject = moment(newYear);
      this.periotEnd = dateObject.format("YYYY");
    },
  },
};
</script>
<style>
.vdp-datepicker__calendar {
  width: 100% !important;
  margin-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding: 10px 10px;
}

.vdp-datepicker__calendar .cell {
  height: 35px !important;
  line-height: 35px !important;
  font-size: 13px;
}

.vdp-datepicker__calendar header span {
  font-size: 14px;
}
</style>
