var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full mt-10"},[_c('editItem',{ref:"editItem",attrs:{"isEdit":_vm.isEdit,"Detail":_vm.selected},on:{"refresh":function($event){return _vm.getAll()},"loadChance":function($event){_vm.loading = !_vm.loading}}}),_c('addItem',{ref:"addItem",attrs:{"isEdit":_vm.isEdit},on:{"refresh":function($event){return _vm.getAll()},"loadChance":function($event){_vm.loading = !_vm.loading}}}),_c('topBar',{attrs:{"title":_vm.$t('cv.profile.personalInformation.index.topBarTitle'),"addText":_vm.$t('cv.profile.personalInformation.index.topBarText')},on:{"add":function($event){return _vm.$refs.addItem.show()}}}),(_vm.isContent && !_vm.loading)?_c('div',{staticClass:"w-full grid grid-cols-2 gap-x-10"},[(_vm.$store.state.extractDetail.dateBirth)?_c('div',{staticClass:"w-full border-b border-b-[#344ba045] py-2 mb-3 border-dotted avoid-page-break"},[_c('card',{attrs:{"title":_vm.$t('cv.profile.personalInformation.index.dateOfBirth'),"sub":_vm.$store.state.extractDetail.dateBirth,"img":require('@/assets/cv/carbon_user.svg')},on:{"deleteItem":function($event){_vm.isEdit ? _vm.deleteItem(1) : _vm.deleteStoreItem(1)},"editItem":function($event){_vm.editItem({
            id: 1,
            item: _vm.$store.state.extractDetail.dateBirth,
            inputType: 'date',
            title: _vm.$t(
              'cv.profile.personalInformation.index.dateOfBirthTitle'
            ),
            formatDate: _vm.$store.state.extractDetail.formatDateBirth,
          })}}})],1):_vm._e(),(
      _vm.$store.state.extractDetail.country &&
      _vm.$store.state.extractDetail.country != 'Unknown'
    )?_c('div',{staticClass:"w-full border-b border-b-[#344ba045] py-2 mb-3 border-dotted avoid-page-break"},[_c('card',{attrs:{"img":require('@/assets/cv/carbon_user.svg'),"title":_vm.$t('cv.profile.personalInformation.index.country'),"sub":_vm.$store.state.extractDetail.country},on:{"deleteItem":function($event){_vm.isEdit ? _vm.deleteItem(2) : _vm.deleteStoreItem(2)},"editItem":function($event){_vm.editItem({
            id: 2,
            item: _vm.$store.state.extractDetail.country,
            type: 'multiSelect',
            getOptions: _vm.getAllCountry,
            title: _vm.$t('cv.profile.personalInformation.index.countryText'),
          })}}})],1):_vm._e(),(
      _vm.$store.state.extractDetail.city &&
      _vm.$store.state.extractDetail.city != 'Unknown'
    )?_c('div',{staticClass:"w-full border-b border-b-[#344ba045] py-2 mb-3 border-dotted avoid-page-break"},[_c('card',{attrs:{"img":require('@/assets/cv/carbon_user.svg'),"title":_vm.$t('cv.profile.personalInformation.index.city'),"sub":_vm.$store.state.extractDetail.city},on:{"deleteItem":function($event){_vm.isEdit ? _vm.deleteItem(3) : _vm.deleteStoreItem(3)},"editItem":function($event){_vm.editItem({
            id: 3,
            item: _vm.$store.state.extractDetail.city,
            type: 'multiSelect',
            getOptions: _vm.getAllCity,
              title: _vm.$t('cv.profile.personalInformation.index.cityText'),
          })}}})],1):_vm._e(),(_vm.$store.state.extractDetail.driverLicense)?_c('div',{staticClass:"w-full border-b border-b-[#344ba045] py-2 mb-3 border-dotted avoid-page-break"},[_c('card',{attrs:{"title":_vm.$t('cv.profile.personalInformation.index.drivingLicence'),"sub":_vm.$store.state.extractDetail.driverLicense
          ? _vm.$store.state.extractDetail.driverLicense == 1
            ? _vm.$t('cv.profile.personalInformation.index.drivingLicenceYes')
            : _vm.$store.state.extractDetail.driverLicense == 2
              ? _vm.$t('cv.profile.personalInformation.index.drivingLicenceNo')
              : ' '
          : '',"img":require('@/assets/cv/carbon_user.svg')},on:{"deleteItem":function($event){_vm.isEdit ? _vm.deleteItem(6) : _vm.deleteStoreItem(6)},"editItem":function($event){_vm.editItem({
            id: 6,
            item: _vm.$store.state.extractDetail.driverLicense,
            inputType: 'select',
            title: _vm.$t('cv.profile.personalInformation.index.drivingLicence'),
            selectList: [
              {
                name: _vm.$t(
                  'cv.profile.personalInformation.index.drivingLicenceYes'
                ),
                value: 1,
              },
              {
                name: _vm.$t(
                  'cv.profile.personalInformation.index.drivingLicenceNo'
                ),
                value: 2,
              },
            ],
            commitName: 'setEditPlaceBirth',
          })}}})],1):_vm._e(),(
      _vm.$store.state.extractDetail.placeBirth &&
      _vm.$store.state.extractDetail.placeBirth != 'Unknown'
    )?_c('div',{staticClass:"w-full border-b border-b-[#344ba045] py-2 mb-3 border-dotted avoid-page-break"},[_c('card',{attrs:{"title":_vm.$t('cv.profile.personalInformation.index.placeOfBirth'),"sub":_vm.$store.state.extractDetail.placeBirth,"img":require('@/assets/cv/carbon_user.svg')},on:{"deleteItem":function($event){_vm.isEdit ? _vm.deleteItem(4) : _vm.deleteStoreItem(4)},"editItem":function($event){_vm.editItem({
            id: 4,
            item: _vm.$store.state.extractDetail.placeBirth,
            inputType: 'text',
            title: _vm.$t(
              'cv.profile.personalInformation.index.placeOfBirthText'
            ),
          })}}})],1):_vm._e()]):_vm._e(),_c('dataLoader',{attrs:{"load":_vm.loading,"isContent":_vm.isContent,"title":_vm.$t('cv.profile.personalInformation.index.dataLoader')}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }