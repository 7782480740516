var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('navbar',{attrs:{"load":_vm.getLoad,"buttonLoad":_vm.load,"title":_vm.$t('settings.configration.index.title'),"description":_vm.$t('settings.configration.index.text')}}),(!_vm.getLoad)?_c('div',{staticClass:"mt-3 mb-5 w-full max-md:mt-0 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4"},[_c('div',{staticClass:"flex gap-5 py-8 border-b border-gray-200 max-md:flex-wrap"},[_c('div',{staticClass:"w-6/12 max-md:w-full"},[_c('h1',{staticClass:"text-base text-gray-700 font-semibold"},[_vm._v(" "+_vm._s(_vm.$t("settings.configration.index.emailTitle"))+" ")]),_c('p',{staticClass:"text-xs text-gray-700 mt-2"},[_vm._v(" "+_vm._s(_vm.$t("settings.configration.index.emailText"))+" ")])]),_c('div',{staticClass:"w-6/12 h-full grid grid-cols-1 gap-4 max-md:w-full"},[_c('Inputs',{attrs:{"title":_vm.$t('settings.configration.index.sunucuAdress'),"required":false,"type":"text","min":"2","max":"100"},model:{value:(_vm.form.mailServer),callback:function ($$v) {_vm.$set(_vm.form, "mailServer", $$v)},expression:"form.mailServer"}}),_c('Inputs',{attrs:{"title":_vm.$t('settings.configration.index.username'),"required":false,"type":"text","min":"2","max":"100"},model:{value:(_vm.form.mailName),callback:function ($$v) {_vm.$set(_vm.form, "mailName", $$v)},expression:"form.mailName"}}),_c('Inputs',{attrs:{"title":_vm.$t('settings.configration.index.password'),"required":false,"type":"text","min":"2","max":"100"},model:{value:(_vm.form.mailPassword),callback:function ($$v) {_vm.$set(_vm.form, "mailPassword", $$v)},expression:"form.mailPassword"}}),_c('Inputs',{attrs:{"title":_vm.$t('settings.configration.index.port'),"required":false,"type":"select","selectList":[
              {
                value: '587',
                name: '587',
              },
              {
                value: '465',
                name: '465',
              },
            ]},model:{value:(_vm.form.mailPort),callback:function ($$v) {_vm.$set(_vm.form, "mailPort", $$v)},expression:"form.mailPort"}})],1)])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }