<template>
  <div class="border h-10 bordercol w-full flex text-sm rounded">
    <button
      v-for="(item, index) in list"
      @click="toggleButton(index)"
      :key="index"
      class="py-2 w-full relative z-10"
      :class="{
        'bgcol text-white': active == index,
        'border-r bordercol': index + 1 != list.length,
      }"
    >
      <h1>
        {{ item.name }}
      </h1>
    </button>
  </div>
</template>
<script>
export default {
  name: "navbar-button",
  props: ["load", "list"],
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    toggleButton(index) {
      if (!this.load) {
        this.active = index;
        this.$emit("change", index);
      }
    },
  },
};
</script>