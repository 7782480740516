<template>
  <div class="w-full relative">
    <multiselect
      v-model="selectedSkills"
      :options="List"
      :multiple="true"
      :hide-selected="true"
      :close-on-select="false"
      :clear-on-select="false"
      :placeholder="$t('cv.components.skillsSelected.placeholder')"
      label="name"
      track-by="name"
      :selectLabel="$t('cv.components.skillsSelected.selectLabel')"
      :deselectLabel="$t('cv.components.skillsSelected.deselectLabel')"
      :custom-getters="customGetters"
      :open-direction="'top'"
      @open="onDropdownOpen"
      @close="onDropdownClose"
    ></multiselect>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import { utility } from "@/networking/urlmanager";
export default {
  props: ["value"],
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedSkills: [],
      List: [],
      isDropdownOpen: false,
    };
  },
  async created() {
    try {
      const response = await this.axios.get(utility.getSkillAll);
      this.List = response.data.data;
    } catch (error) {
      console.error( error);
    }
  },
  computed: {
    customGetters() {
      return {
        isOpen: this.isDropdownOpen,
      };
    },
  },
  methods: {
    onDropdownOpen() {
      this.isDropdownOpen = true;
    },
    onDropdownClose() {
      this.isDropdownOpen = false;
    },
  },
  watch: {
    selectedSkills(val) {
      this.$emit("change", val);
    },
    value(val) {
      this.selectedSkills = val;
    },
  },
};
</script>
