<template>
  <modal
    name="generate-question"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
    class="scroltype"
  >
    <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white">
      <h1 class="text-xl">
        {{ $t("project.projectUser.components.interview.components.generateQuestion.questionSuggestions") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20">
      <generate
        v-if="isContent == 1"
        :Detail="Detail"
        @generateQuestion="(r) => generateQuestion(r)"
      />
      <tableQuestion
        v-if="isContent == 2"
        :List="List"
        :Detail="Detail"
        @addQuestion="addQuestion"
      />
    </div>
  </modal>
</template>

<script>
import generate from "./components/generate.vue";
import tableQuestion from "./components/tableQuestion.vue";

export default {
  name: "content-generate-question",
  props: ["Detail"],
  components: {
    generate,
    tableQuestion,
  },
  data() {
    return {
      isContent: 1,
      List: [],
    };
  },
  methods: {
    show() {
      this.isContent = 1;
      this.List = [];
      this.explanation = "";
      this.$modal.show("generate-question");
    },
    hide() {
      this.$modal.hide("generate-question");
    },
    generateQuestion(val) {
      this.List = val;
      this.isContent = 2;
    },
    addQuestion() {
      this.isContent = 1;
      this.List = [];
      this.$emit("refresh", true);
      this.hide();
    },
  },
};
</script>
