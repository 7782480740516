<template>
  <div class="pb-6 pt-10 pr-12 max-md:pr-0 max-md:pt-0 navbarHeight">
    <div
      class="w-full bgcol p-6 h-full rounded-xl max-md:rounded-none relative"
    >
      <p class="text-gray-300 text-sm">#{{ $route.params.id }}</p>
      <h1 class="text-white font-semibold text-sm pt-2">
        {{ $t("support.detail.components.bar.subject") }}
        <span class="font-normal text-gray-300 text-sm"> {{ subject }}</span>
      </h1>

      <h1 class="text-white text-sm font-semibold pt-4">
        {{ $t("support.detail.components.bar.category") }} <span class="font-light">{{ categoryName }}</span>
      </h1>
      <h1 class="text-white text-sm font-semibold pt-4">
        {{ $t("support.detail.components.bar.date") }}  <span class="font-light">{{ date | getDate }}</span>
      </h1>
      <div
        class="px-7 h-11 w-full py-2 bg-white text-[15px] rounded max-md:text-sm flex items-center justify-center mt-5"
      >
        <div
          :class="{
            'text-red-400': stateId == 1,
            'text-green-500': stateId == 2,
            'text-black-500': stateId == 3,
          }"
        >
          <p v-if="!load">
            {{
              stateId == 1
                ? $t('support.detail.components.bar.replyWaiting')
                : stateId == 2
                ? $t('support.detail.components.bar.answered')
                : $t('support.detail.components.bar.close')
            }}
          </p>
          <p v-if="load" class="gap-1 flex items-center">
            <i class="fas fa-spinner fa-spin"></i> {{ $t("support.detail.components.bar.loadText") }}
          </p>
        </div>
      </div>
      <div>
        <button
          @click="close()"
          :disabled="stateId == 3"
          class="px-7 h-11 absolute max-sm:static max-sm:w-full bottom-7 left-7 right-7 py-2 bg-red-700 hover:opacity-90 text-sm rounded max-md:text-sm flex items-center justify-center mt-5"
        >
          <p class="text-white font-bold"> {{ $t("support.detail.components.bar.supportRequestClose") }}</p>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { support } from "@/networking/urlmanager";
import moment from "moment";
import "moment/locale/tr";
export default {
  name: "navbar-detail",
  props: ["subject", "date", "stateId", "categoryName", "load"],
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return this.$t("support.detail.components.bar.noDate");
    },
  },
  methods: {
    close() {
      if (this.stateId != 3) {
        this.verifiedMessage(
          this.$t("support.detail.components.bar.warningMessage")
        ).then((result) => {
          if (result.isConfirmed) {
            this.axios
              .post(support.close, {
                rowId: this.$route.query.id,
              })
              .then((res) => {
                this.successMessage(res.data.message);
                this.$emit("refresh", true);
              })
              .catch((err) => {
                console.log(err);
                this.authController(err);
              });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.navbarHeight {
  height: calc(100vh - 96px);
}

@media only screen and (max-width: 767px) {
  .navbarHeight {
    height: auto;
  }
}
</style>