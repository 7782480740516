<template>
  <div class="rounded w-full">
    <label>{{ title }}</label>
    <div class="relative">
      <input
        class="w-full rounded-lg bg-gray-200 outline-none text-black text-sm mt-5 h-[46px] px-5 placeholder:text-base placeholder:text-gray-800"
        :type="type"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        :placeholder="placeholder"
        v-model="selected"
        :min="min"
        :max="max"
        :minlength="min"
        :maxlength="max"
        :autocomplete="autocomplete"
        @change="(r) => type == 'file' && $emit('change', r)"
      />
      <div
        v-if="placeholder == 'Password'"
        class="absolute mt-[30px] top-0 right-3"
      >
        <button @click="hiddenPassword" type="button">
          <i
            data-v-step="3"
            class="fas w-5"
            :class="!hidenState ? 'fa-eye-slash' : 'fa-eye'"
          >
          </i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "input-costum",
  props: [
    "title",
    "type",
    "value",
    "required",
    "disabled",
    "min",
    "max",
    "readonly",
    "placeholder",
    "autocomplete",
  ],
  components: {
    // tinymce,
  },
  data() {
    return {
      selected: this.value,
      hidenState: false,
    };
  },
  model: {
    event: "change",
    prop: "value",
  },
  methods: {
    hiddenPassword() {
      this.$emit("hidenState", !this.hidenState);
      this.hidenState = !this.hidenState;
    },
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
    selected(val) {
      this.$emit("change", val);
    },
  },
};
</script>
