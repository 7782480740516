<template>
    <div class="bgcol h-14 px-6  flex w-full">
        <div class="py-3">
            <button class="h-full" @click="meetingBar"><img src="@/assets/meeting/logo.svg" alt="" class="h-full"></button>
        </div>
        <div class="flex justify-center w-full items-center gap-5">
            <button class="px-2  h-full">
                <img src="@/assets/meeting/appearance.svg" alt="" class="w-5">
            </button>
            <button class="px-2 border-b border-gray-300 h-full">
                <img src="@/assets/meeting/appearanceTwo.svg" alt="" class="w-5">
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'navbarMeeting',
    methods: {
        meetingBar() {
            this.$store.state.isMeetingBar = !this.$store.state.isMeetingBar;
            this.$store.commit("setMeetingBar", this.$store.state.isMeetingBar);
        }
    },
}
</script>