<template>
  <div v-if="isLoading || length == 0"  :class="containerClass">
    <div v-if="isLoading">
      <img
      :src="require('@/assets/img/documentLoading.gif')"
      alt="loading"
      :class="imageClass"
    />
    <p v-if="text">{{ text }}</p>
    </div>
    <div v-if="!isLoading && length == 0">
      {{ $t("globalComponents.tableLoader.tableLoaderTitle") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
  props: {
    isLoading: {
      type: Boolean,
      required: false,
    },
    containerClass: {
      type: String,
      default: "w-full text-center justify-center items-center flex h-72",
    },
    imageClass: {
      type: String,
      default: "w-32 h-32  p-4",
    },
    text: {
      type: String,
      default: "",
    },
    length:{
      type: Number,
      default: 1,
    }
  },
};
</script>
