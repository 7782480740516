<template>
  <div class="bgcol h-screen w-full flex items-center justify-center">
    <div
      v-if="refreshLoad"
      class="w-5/12 bg-white rounded p-10 max-lg:w-6/12 max-md:w-8/12 max-sm:w-11/12 max-sm:p-5"
    >
      <form @submit.prevent="save">
        <img
          class="mx-auto w-72 max-sm:w-52"
          src="@/assets/global/fullLogo.png"
          alt=""
        />
        <p class="text-center mt-10 text-sm">
          {{ $t("auth.confirmPassword.message") }}
        </p>
        <customInput
          v-model="password"
          type="password"
          min="4"
          max="30"
          :required="true"
          :placeholder="$t('auth.confirmPassword.password')"
        />
        <customInput
          v-model="newPassword"
          type="password"
          min="4"
          max="30"
          :required="true"
          :placeholder="$t('auth.confirmPassword.passwordAgain')"
        />

        <div class="mt-12">
          <asyncButton
            :text="$t('auth.confirmPassword.passwordRefresh')"
            :load="load"
            buttonClass=" !w-full"
            :loadText="$t('auth.confirmPassword.loadText')"
          />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import customInput from "@/components/diffInput.vue";
import asyncButton from "@/components/button.vue";

import { auth } from "@/networking/urlmanager";
import axios from "axios";

export default {
  name: "reset-password",
  components: {
    customInput,
    asyncButton,
  },
  data() {
    return {
      password: "",
      newPassword: "",
      token: "",
      load: false,
      refreshLoad: false,
    };
  },
  methods: {
    save() {
      if (this.password == this.newPassword) {
        this.load = true;
        axios
          .post(auth.confirmPassword, {
            token: this.$route.query.token,
            newPassword: this.newPassword,
          })
          .then((res) => {
            this.successMessage();
            this.load = false;
            this.email = "";
            this.$router.push("/");
          })
          .catch((err) => {
            this.load = false;
            this.authController(
              err.response.data.message ? err.response.data.message : err
            );
          });
      } else {
        this.warningMesage(this.$t("auth.confirmPassword.warningMessage"));
        this.load = false;
      }
    },
    async verifyToken() {
      try {
        this.refreshLoad = false;
        await axios.post(auth.verifyResetToken, {
          token: this.$route.query.token,
        });
        this.refreshLoad = true;
      } catch (error) {
        this.refreshLoad = true;
        this.$router.push("/");
      }
    },
  },
  async created() {
    this.verifyToken();
  },
};
</script>
