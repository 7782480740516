<template>
  <div
    v-if="pageCount > 0"
    class="xl:flex items-center justify-between border-white-custom md:px-6 xl:px-3 py-3 w-full text-black text-sm xl:text-base"
    :class="paginationClass"
  >
    <div v-if="!hiddenTotal">{{ totalRowCount }} {{ $t("paginations.text") }}</div>

    <paginate
      :page-count="pageCount"
      :value="currentPage + 1"
      :page-range="isMobile ? 2 : 6"
      :click-handler="(r) => $emit('change', r - 1)"
      active-class="w-full  bg-[#878da5] rounded text-white"
      :prev-text="$t('globalComponents.pagination.prevText')"
      :next-text="$t('globalComponents.pagination.nextText')"
      container-class="flex items-center gap-2 text-sm xl:text-base mt-4 xl:mt-0  rounded"
      page-link-class="px-3.5 py-1.5 text-white"
      page-class="bg-[#182451] rounded py-1"
      prev-link-class="bgcol rounded text-white px-3 py-1.5"
      next-link-class="bgcol rounded text-white px-3 py-1.5"
    >
    </paginate>
  </div>
</template>
 

<script>
import Paginate from "vuejs-paginate";
export default {
  props: [
    "pageCount",
    "currentPage",
    "totalRowCount",
    "hiddenTotal",
    "paginationClass",
  ],
  name: "pagination-components",
  model: {
    prop: "currentPage",
    event: "change",
  },
  components: {
    Paginate,
  },
  data() {
    return {
      current: 0,
    };
  },
  watch: {
    current(val) {
      if (val != this.currentPage) {
        this.$emit("change", val);
      }
    },
    currentPage(val) {
      this.current = val;
    },
  },
};
</script>