<template>
  <div
    class="bg-[#071238] h-screen transition-all ease-in-out"
    @mousedown="handleMouseDown"
  >
    <div class="bg-[#182451] h-14 py-3 px-6">
      <button @click="meetingBar" class="h-full">
        <img src="@/assets/meeting/logo.svg" alt="" class="h-full" />
      </button>
    </div>
    <div class="pt-5">
      <carousel-3d
        :controls-visible="false"
        :loop="true"
        :height="150"
        :autoplay="true"
        :autoplayTimeout="2500"
        :controls-width="30"
        :controls-height="50"
        :clickable="false"
      >
        <slide v-for="(slide, i) in slides" :key="i" :index="i">
          <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
            <figure
              :data-index="index"
              :class="{
                current: isCurrent,
                onLeft: leftIndex >= 0,
                onRight: rightIndex >= 0,
              }"
            >
              <img :src="slide.img" />
              <div class="overlay" v-if="!isCurrent"></div>
            </figure>
          </template>
        </slide>
      </carousel-3d>
    </div>
    <div class="w-6/12 mx-auto pt-5">
      <h1 class="text-center text-white text-4xl font-semibold">
        Premium görüntülü toplantılar. Artık herkes için ücretsiz.
      </h1>
      <p class="text-center mt-5 text-gray-200">
        Güvenli iş toplantıları için geliştirilen Google Meet yeniden
        tasarlandı. Hizmete artık herkes ücretsiz olarak ulaşabilir.
      </p>
      <div class="flex mt-14 px-10 gap-5">
        <router-link
          to="/meeting/live"
          class="py-2.5 bgcol rounded text-white w-3/12 flex justify-center gap-1.5"
          ><img src="@/assets/meeting/connect.svg" alt="" />Yeni
          Toplantı</router-link
        >
        <input
          type="text"
          class="bg-transparent border-2 border-[#182451] outline-none text-white pl-3 rounded w-9/12"
          placeholder="Toplantı kodunuzu girin."
        />
      </div>
    </div>
    <div class="mt-14">
      <div
        class="w-10/12 mx-auto h-[1px]"
        style="
          background: rgb(7, 18, 56);
          background: linear-gradient(
            90deg,
            rgba(7, 18, 56, 1) 0%,
            rgba(224, 224, 224, 1) 50%,
            rgba(7, 18, 56, 1) 100%
          );
        "
      ></div>
      <h1 class="text-ceter text-white text-center py-5 text-sm">
        <b>Yeni Toplantı</b>'yı tıklayarak toplantı yapmak istediğiniz kişilere
        gönderebileceğiniz bir <b>CV US Meeting</b> bağlantı edinin
      </h1>
    </div>
  </div>
</template>
<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  name: "meetingConnect-page",
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      activeIndex: 0,
      slides: [
        {
          id: 0,
          img: require("@/assets/meeting/slider1.png"),
        },
        {
          id: 1,
          img: require("@/assets/meeting/slider2.png"),
        },
        {
          id: 2,
          img: require("@/assets/meeting/slider3.png"),
        },
        {
          id: 3,
          img: require("@/assets/meeting/slider4.png"),
        },
        {
          id: 4,
          img: require("@/assets/meeting/slider5.png"),
        },
      ],
    };
  },
  methods: {
    handleMouseDown() {
      if (this.$store.state.isMeetingBar) {
        this.$store.state.isMeetingBar = !this.$store.state.isMeetingBar;
        this.$store.commit("setMeetingBar", this.$store.state.isMeetingBar);
      }
    },
    meetingBar() {
      this.$store.state.isMeetingBar = !this.$store.state.isMeetingBar;
      this.$store.commit("setMeetingBar", this.$store.state.isMeetingBar);
    },
  },
};
</script>
<style>
.carousel-3d-slide {
  background-color: white;
  border-radius: 21px;
}

.carousel-3d .slide-wrapper .overlay {
  display: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  /* Overlay elemanlarının sol ve sağdaki slaytların üzerine gelmesini sağlar */
}
</style>
