<template>
  <div
    class="w-full mt-5 rounded text-center overflow-y-auto flex justify-between border border-gray-300"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <input
      type="file"
      name="fileName"
      id="fileConsentStatement"
      class="hidden"
      @change="onChange"
      ref="consentStatementFile"
      accept=".pdf"
    />
    <div
      class="w-full flex rounded gap-5 h-full justify-center text-left pl-4 text-sm items-center"
    >
      <label for="fileConsentStatement" class="w-8/12 cursor-pointer">
        <div v-if="img && !files">
          {{ $t("settings.general.components.uploadConsentStatement.consentStatementTitle") }}
          <p class="text-xs">
            {{ $t("settings.general.components.uploadConsentStatement.consentStatementFileText") }}
          </p>
        </div>
        <div v-else-if="isDragging">
          {{ $t("settings.general.components.uploadConsentStatement.dropTheFileHere") }}
        </div>
        <div v-else-if="files || img" class="truncate overflow-x-auto">
          {{ truncatedFileName }}
        </div>
        <div v-else>
          {{
            $t("settings.general.components.uploadConsentStatement.dropTheFileHereTextOne")
          }}
          <u>
            {{
              $t("settings.general.components.uploadConsentStatement.dropTheFileHereTwo")
            }} </u
          >.
        </div>
      </label>
      <div class="w-4/12 p-5 h-full flex space-x-2 justify-end">
        <a
          v-if="img || files"
          :href="img"
          target="_blank"
          class="px-5 text-white bgcol py-3 rounded"
        >
          {{ $t("settings.general.components.uploadConsentStatement.consentStatementView") }}
        </a>
        <a
          v-else
          class="px-5 text-white bgcol py-3 rounded cursor-pointer"
          @click="$refs.consentStatementFile.click()"
        >
          <i class="fa-solid fa-upload"></i>
        </a>
        <div
          class="my-2 mx-1 px-2 flex items-center rounded-full cursor-pointer bg-red-500 text-white hover:bg-red-500/60 hover:text-gray-700"
          v-if="files || img"
          @click="remove"
        >
          <i class="fa-solid fa-trash"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["img"],
  data() {
    return {
      isDragging: false,
      files: null,
    };
  },
  computed: {
    truncatedFileName() {
      if (this.files) {
        const fullFileName = this.files.name;
        const fileName = fullFileName.substring(
          0,
          fullFileName.lastIndexOf(".")
        );
        const fileExtension = fullFileName.substring(
          fullFileName.lastIndexOf(".")
        );
        return fileName.length > 20
          ? fileName.substring(0, 20) + "..." + fileExtension
          : fullFileName;
      } else {
        return "İsim Bulunamadı";
      }
    },
  },
  methods: {
    onChange() {
      const selectedFile = this.$refs.consentStatementFile.files[0];
      if (selectedFile) {
        const allowedExtensions = ["pdf"];
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          this.warningMesage(
            this.$t("settings.general.components.uploadConsentStatement.warningMessage")
          );
          return;
        }

        const maxSize = 3 * 1024 * 1024;
        if (selectedFile.size > maxSize) {
          this.warningMesage(
            this.$t(
              "settings.general.components.uploadConsentStatement.fileTooLargeMessage"
            ),
            5000
          );
          return;
        }

        this.files = selectedFile;
        this.$emit("fileSelected", this.files);
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.consentStatementFile.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove() {
      this.files = null;
      this.$refs.consentStatementFile.value = "";
      this.$refs.consentStatementFile.type = "text";
      this.$refs.consentStatementFile.type = "file";
      this.$emit("fileSelected", 2);
    },
    generateURL(file) {
      if (file) {
        let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
          URL.revokeObjectURL(fileSrc);
        }, 1000);
        return fileSrc;
      }
      return "";
    },
  },
};
</script>
