<template>
  <modal
    name="addSupport"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '48%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">{{ $t("support.components.add.newAdd") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form @submit.prevent="save()" novalidate class="p-5">
      <div class="grid grid-col-1 gap-y-3">
        <categorySelect
          v-model="categoryId"
          :required="true"
          :isError="true"
          ref="categoryId"
          :title="$t('support.components.add.category')"
        />
        <customInput
          v-model="subject"
          type="text"
          min="3"
          max="75"
          :required="true"
          :isError="true"
          ref="subject"
          :title="$t('support.components.add.subject')"
        />
        <customInput
          type="textarea"
          :title="$t('support.components.add.message')"
          textareaClass="h-32"
          :required="true"
          :isError="true"
          ref="message"
          v-model="message"
          cols="30"
          rows="10"
        />
        <uploadFile @fileUpload="fileUpload" />

        <requiredField />
        <buttonItem :load="load" buttonClass="w-full" />
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import uploadFile from "./uploadFile.vue";
import categorySelect from "./categorySelect.vue";
import requiredField from "@/components/requiredField.vue";

import axios from "axios";
import { support } from "@/networking/urlmanager";

export default {
  name: "add-support",
  components: {
    customInput,
    buttonItem,
    uploadFile,
    categorySelect,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,

      fullname:
        this.$store.state.userData.firstName +
        " " +
        this.$store.state.userData.lastName,
      email: this.$store.state.userData.email,
      subject: "",

      authority: 2,
      files: [],
      message: "",
      load: false,
      categoryId: "",
      category: [
        {
          value: 1,
          name: this.$t("support.components.add.suggestion"),
        },
        {
          value: 2,
          name: this.$t("support.components.add.errorNotification"),
        },
        {
          value: 3,
          name: this.$t("support.components.add.other"),
        },
      ],
    };
  },
  methods: {
    show() {
      this.files = [];
      this.message = "";
      this.subject = "";
      this.categoryId = 0;
      this.$modal.show("addSupport");
    },
    hide() {
      this.$modal.hide("addSupport");
    },
    save() {
      this.checkErrors();

      if (
        this.subject &&
        this.categoryId !== 0 &&
        (this.message || this.files.length > 0)
      ) {
        this.load = true;
        const formData = new FormData();
        this.files.forEach((r) => {
          formData.append("file", r);
        });
        formData.append(
          "fullname",
          this.$store.state.userData.firstName +
            " " +
            this.$store.state.userData.lastName
        );
        formData.append("email", this.$store.state.userData.email);
        formData.append("subject", this.subject);
        formData.append("categoryId", this.categoryId);
        formData.append("message", this.message);
        axios
          .post(support.add, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.files = [];
            this.message = "";
            this.categoryId = 0;
            this.subject = "";
            this.load = false;
            this.$emit("refresh", true);
            this.hide();
          })
          .catch((err) => {
            this.load = false;
            console.log(err);
            this.authController(err);
          });
      }
    },
    fileUpload(val) {
      this.files = val;
    },
    checkErrors() {
      this.$refs.subject.validateInput();
      this.$refs.message.validateInput();
      this.$refs.categoryId.validateInput();
    },
  },
  mount() {
    this.show();
  },
};
</script>

<style></style>
