<template>
  <div class="my-10 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 relative" ref="content">
    <div class="flex flex-col space-y-2 md:space-y-0 md:flex-row justify-between items-center w-full">
      <exportPanel ref="exportPanel" />
      <cvListModal ref="cvListModal" />

      <div class="flex flex-col md:flex-row justify-center md:items-center space-y-4 md:space-y-0 md:space-x-4 w-full">
        <button @click="$router.go(-1)" class="text-left w-max flex items-center space-x-3">
          <i class="fa-sharp fa-solid fa-arrow-left"></i>
          <h2 class="text-xl textcol font-semibold w-max">
            {{ $t("cv.profile.index.cvTitle") }}
          </h2>
        </button>

        <div class="flex items-center space-x-4 w-full">
          <customButton :disabled="load" type="button" @click="$refs.exportPanel.show()"
            :text="$t('cv.profile.index.export')" buttonClass="w-full "
            :imgSrc="require('@/assets/cv/directboxsend.svg')"
            containerBackground="bg-gray-100 !text-black !shadow md:!w-[165px] justify-center gap-3 !w-full"
            container-class="w-full md:w-auto" contain imgClass="w-5" :isTooltip="true"
            :toolTipTitle="$t('cv.profile.index.toolTip')" :tooltipTop="true" toolTipClass="!w-[180px] " />
          <customButton :load="viewLoad" :disabled="load" type="button" @click="showOldCV()"
            :text="$t('cv.profile.index.resume')" :loadText="$t('cv.profile.index.noView')" buttonClass="w-full"
            :imgSrc="require('@/assets/cv/directboxsend.svg')"
            containerBackground="bg-gray-100 !text-black !shadow md:!w-[165px] justify-center gap-3 !w-full"
            imgClass="w-5" container-class="w-full md:w-auto" :isTooltip="true"
            :toolTipTitle="$t('cv.profile.index.toolTipFile')" :tooltipTop="true" toolTipClass="!w-[180px] "
            spinnerClass="!border-t-transparent !border-black !text-black" />
        </div>
      </div>

      <div class="w-full flex" data-v-step="4">
        <cvSave :isEdit="isEdit" />
      </div>
    </div>

    <div v-if="load"
      class="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex justify-center items-center z-[999]">
      <i class="fas fa-spinner fa-spin fa-4x text-white"></i>
    </div>

    <div class="relative flex gap-4 mt-10 max-md:flex-wrap">
      <div class="w-2/12 pr-2 max-sm:w-6/12 max-md:4/12">
        <div class="gods relative ml-[15px]">
          <img class="w-full object-contain" src="@/assets/cv/profile-image.svg" alt="" />
        </div>
      </div>

      <div class="w-10/12 max-md:w-full">
        <div class="flex justify-between items-center">
          <h1 class="text-3xl flex items-center textcol font-bold leading-9">
            <span v-if="!loading">{{
              formatText(
                $store.state.extractDetail.fullname
                  ? $store.state.extractDetail.fullname
                  : $t("cv.profile.index.viewing")
              )
            }}</span>
            <span v-else class="text-base font-extralight flex items-center gap-2">
              <i class="fas fa-spinner fa-spin"></i>
              {{ $t("cv.profile.index.loadText") }}
            </span>
          </h1>
          <div class="relative group flex justify-center">
            <button @click="$refs.editInformation.show()" class="w-22 py-2 text-base rounded text-black">
              <i class="fas fa-plus mr-1"></i>
              {{ $t("cv.profile.index.edit") }}
            </button>
            <tooltip :title="$t('cv.profile.index.toolTipUser')" tipClass=" bottom-8 text-center max-sm:ml-2" />
          </div>
        </div>

        <editInformation ref="editInformation" :isEdit="isEdit" @loadChance="loadChance" />
        <div class="flex">
          <h3 v-if="!loading" class="text-lg textcol font-base leading-9">
            {{
              $store.state.extractDetail.position
                ? $store.state.extractDetail.position
                : $t("cv.profile.index.viewing")
            }}
          </h3>
          <span v-else class="text-base font-extralight flex items-center gap-2 mt-1">
            <i class="fas fa-spinner fa-spin"></i>
            {{ $t("cv.profile.index.loadText") }}
          </span>
        </div>

        <!-- Biografi -->
        <div class="w-full relative mt-3">
          <div class="flex">
            <h2 class="text-xl font-semibold text-gray-600">
              {{ $t("cv.profile.index.biography") }}
            </h2>
          </div>
          <div v-if="!loading">
            <p v-if="$store.state.extractDetail.biography" class="mt-1.5 text-[13px] font-light">
              {{ $store.state.extractDetail.biography }}
            </p>

            <p v-else>{{ $t("cv.profile.index.biographyText") }}</p>
          </div>

          <span v-else class="text-base font-extralight flex items-center gap-2 mt-2">
            <i class="fas fa-spinner fa-spin"></i>
            {{ $t("cv.profile.index.loadText") }}
          </span>
        </div>
        <!-- End-Biografi -->
      </div>
    </div>

    <div class="w-full max-sm:w-full flex mt-6 gap-4 flex-wrap">
      <buttonItem v-for="(item, index) in buttonList" :key="index" :isPage="isPage" :page="item.id" :img="item.img"
        :title="item.title" @isPage="(val) => (isPage = val)" />
    </div>

    <section class="mt-5">
      <component :ref="activePage.component" :is="activePage.component" :load="load" :isEdit="isEdit"></component>
    </section>

    <tour :steps="steps" :moduleId="7" v-if="!$store.state.userData.tourModuleId.includes(7)" />
  </div>
</template>
<script>
import workExperience from "./information/workExperience/index.vue";
import contact from "./information/contact/index.vue";
import schoolAndEducation from "./information/schoolAndEducation/index.vue";
import seminarAndCourse from "./information/seminarAndCourse/index.vue";
import languages from "./information/languages/index.vue";
import personalInformation from "./information/personalInformation/index.vue";
import computerSkills from "./information/computerSkills/index.vue";
import projectsPublications from "./information/projectsPublications/index.vue";
import skills from "./information/skills/index.vue";
import cvSave from "../components/cvSave.vue";
import cvListModal from "../components/cvListSide/index.vue";
import exportPanel from "./components/ressumeExport.vue";
import { cv } from "@/networking/urlmanager.js";
import { profileTour } from "@/tourmodel/tour.js";
import tour from "@/components/tour.vue";
import tooltip from "@/components/tooltip.vue";

import editInformation from "./components/edit.vue";
import buttonItem from "./components/button.vue";
import customButton from "@/components/button.vue";

export default {
  name: "cv-profile-page",
  components: {
    workExperience,
    contact,
    schoolAndEducation,
    seminarAndCourse,
    languages,
    personalInformation,
    computerSkills,
    projectsPublications,
    skills,
    cvSave,
    cvListModal,
    tour,
    tooltip,
    exportPanel,
    editInformation,
    buttonItem,
    customButton,
  },
  data() {
    return {
      isPage: 1,
      load: false,
      cvId: "",
      oldResumeUrl: "",
      viewLoad: false,
      loading: false,

      projectList: [],
      steps: profileTour,
    };
  },
  computed: {
    activePage() {
      return this.buttonList.find((r) => r.id === this.isPage);
    },
    isEdit() {
      return this.$route.params.id != undefined;
    },
    buttonList() {
      return [
        {
          id: 1,
          img: require("@/assets/cv/carbon_user.svg"),
          title: this.$t("cv.profile.index.userToolTip"),
          component: personalInformation,
        },
        {
          id: 2,
          img: require("@/assets/cv/education-information.svg"),
          title: this.$t("cv.profile.index.toolTipScool"),
          component: schoolAndEducation,
        },
        {
          id: 3,
          img: require("@/assets/cv/carbon_inventory-management.svg"),
          title: this.$t("cv.profile.index.toolTipWork"),
          component: workExperience,
        },
        {
          id: 4,
          img: require("@/assets/cv/language.png"),
          title: this.$t("cv.profile.index.languageSkill"),
          component: languages,
        },
        {
          id: 5,
          img: require("@/assets/cv/computer-skills.svg"),
          title: this.$t("cv.profile.index.toolTipComputer"),
          component: computerSkills,
        },
        {
          id: 10,
          img: require("@/assets/cv/skills-icon.svg"),
          title: this.$t("cv.profile.index.toolTipAbilities"),
          component: skills,
        },
        {
          id: 6,
          img: require("@/assets/cv/certificate.svg"),
          title: this.$t("cv.profile.index.toolTipSertificate"),
          component: seminarAndCourse,
        },
        {
          id: 7,
          img: require("@/assets/cv/projects.svg"),
          title: this.$t("cv.profile.index.toolTipProject"),
          component: projectsPublications,
        },
        {
          id: 8,
          img: require("@/assets/cv/carbon_email.svg"),
          title: this.$t("cv.profile.index.toolTipCommunication"),
          component: contact,
        },
      ];
    },
  },
  methods: {
    getCv() {
      if (this.$route.params.id) {
        this.$store.commit("setExtractDetail", {});
        this.$store.commit("setProjectList", []);
        this.$store.commit("setCvOriginalFile", "");
        this.load = true;
        this.axios.get(cv.getDetail + "?rowId=" + this.$route.params.id)
          .then((res) => {
            this.$store.commit(
              "setCvOriginalFile",
              res.data.data.personelInformations?.originalFile
            );
            this.$store.commit("setProjectList", res.data.data.projectConnects);
            this.$store.commit("setExtractDetail", {
              ...res.data.data.personelInformations,
              workexperience: res.data.data.workexperiences,
              skills: res.data.data.skills,
              seminarAndCourse: res.data.data.seminarAndCourses,
              references: [],
              projectsPublications: res.data.data.projectsPublications,
              personalInformation: res.data.data.personelInformations,
              languages: res.data.data.languages,
              schoolAndEducation: res.data.data.educationlifes,
              computerSkills: res.data.data.computerSkills,
              contactInformation: res.data.data.contacts,
            });
          })
          .catch((err) => {
            console.log(err);
            this.authController(err);
          })
          .finally(() => {
            this.load = false;
          });
      }
    },
    loadChance() {
      this.loading = !this.loading;
    },
    async showOldCV() {
      this.viewLoad = true;
      this.axios.post(cv.oldResume, {
        fileUrl: this.$store.state?.originalFile,
      }
      ).then((res) => {
        window.open(res.data.data);
      }).catch((err) => {
          this.authController(err);
      }).finally(() => {
          this.viewLoad = false;
      });
    },
  },

  created() {
    this.nameValue = this.$store.state.extractDetail.fullname;
    this.oldResumeUrl = this.$store.state?.originalFile;
    this.biographyValue = this.$store.state.extractDetail.biography;
    this.getCv();
    this.$router.afterEach(this.onRouteChange);
  },
};
</script>
<style>
.gods::before {
  content: " ";
  background-color: #344ca0;
  position: absolute;
  left: -15px;
  top: -15px;
  z-index: -1;
}
</style>
