<template>
  <div class="w-full">
    <label class="textcol font-semibold flex items-center gap-1"
      >{{ title }}
      <p class="text-[10px]" v-if="required">
        <i
          class="fa-sharp fa-solid fa-star-of-life text-[7px] text-red-500"
        ></i></p
    ></label>
    <select
      class="w-full border bordercol rounded py-3 outline-none pl-3 text-xs"
      :class="{ '!border-red-500 shake': error }"
      name=""
      id=""
      v-model="selectedList"
      :required="required"
    >
      <option value="">
        {{ $t("support.components.categorySelect.notSelected") }}
      </option>
      <option v-for="(item, index) in List" :key="index" :value="item.id">
        {{ item.name }}
      </option>
    </select>
    <span
      class="text-[10px] text-red-500 block"
      v-if="typeof error !== 'string' && error"
    >
      {{ $t("globalComponents.customInput.errorMessage") }}</span
    >
  </div>
</template>

<script>
import { support } from "@/networking/urlmanager";
export default {
  props: ["value", "title", "required"],
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedList: "",
      List: [],
      error: false,
    };
  },
  methods: {
    validateInput() {
      if (this.required && !this.selectedList && this.selectedList !== 0) {
        this.error = true;
      } else {
        this.error = false;
      }
    },
  },
  async created() {
    try {
      const response = await this.axios.get(support.getCategoryAll);
      this.List = response.data.data;
    } catch (error) {
      console.error(error);
    }
  },
  watch: {
    selectedList(val) {
      this.$emit("change", val);
      this.validateInput();
    },
    value(val) {
      this.selectedList = val;
    },
  },
};
</script>

<style>
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-4px);
  }
  40% {
    transform: translateX(4px);
  }
  60% {
    transform: translateX(-4px);
  }
  80% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
