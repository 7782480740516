<template>
  <div>
    <button @click="toggleSidebar" class="py-2 px-8 flex items-center gap-2 bg-blue-900 hover:text-white hover:shadow-xl rounded-t text-white text-sm font-medium cursor-pointer shadow-xl fixed bottom-0 z-50 h-10 right-5 transition-all ease-out md:transform md:-rotate-90 md:origin-bottom-right md:top-[90px] md:bottom-auto md:right-0">
      <span class="animate-pulse">
        <i class="fas fa-list cursor-pointer" aria-hidden="true"></i>
        {{ $t("cv.components.cvListModal.resumeList") }}
      </span>
    </button>

    <sideModal
      ref="cvListModal"
      modalClass="w-4/12 max-xl:w-10/12 max-sm:w-full"
      :isHeader="true"
      :headerTitle="$t('cv.components.cvListModal.resumeListTitle')"
      @close="closeSide()"
      :isRefresh="true"
      @refresh="getAll()"
      :hidenOverflowY="true"
      >
    >
      <template v-slot:content>
        <navbar
          ref="navbar"
          :list="buttonList"
          @change="(r) => chancePage(r)"
          :load="load"
        />

        <loadItems :load="load" :lenghtItem="List?.length" />
        
        <section v-if="buttonList[activePage]" :class="(!load && List.length > 0) && 'h-full overflow'">
          <component
            :is="buttonList[activePage].component"
            :key="buttonList[activePage].id"
            :ref="buttonList[activePage].referance"
            @load="(r) => (load = r)"
            @chanceList="(r) => (List = r)"
            @refresh="$emit('refresh',true)"
          ></component>
        </section>

        <div class="absolute bottom-0 left-0 flex w-full items-center justify-center h-12 bg-[#182451] text-white">
          <p>
            {{ $t("cv.components.cvListModal.total") }} {{ List?.length || 0 }}
            {{ $t("cv.components.cvListModal.resumesListed") }}
          </p>
        </div>
      </template>
    </sideModal>
  </div>
</template>
<script>
import navbar from "./components/navbar.vue";
import loadingCv from "./page/loadingCv.vue";
import conflictsCv from "./page/conflictsCv.vue";
import errorCv from "./page/errorCv.vue";
import loadItems from "./components/load.vue";

export default {
  name: "cv-list-side",
  components: { navbar,conflictsCv, loadItems,errorCv },
  data() {
    return {
      load: false,
      List: [],
      intervalId: null,
      buttonList: [
        {
          id: 1,
          name: this.$t("cv.components.cvListModal.tab.loading"),
          component: loadingCv,
          referance: "loadingCv",
        },
        {
          id: 2,
          name: this.$t("cv.components.cvListModal.tab.hasRegistered"),
          component: conflictsCv,
          referance: "conflictsCv",
        },
        {
          id: 3,
          name: this.$t("cv.components.cvListModal.tab.error"),
          component: errorCv,
          referance: "errorCv",
        },
      ],
      activePage: 0,
    };
  },
  methods: {
    toggleSidebar() {
      this.$refs.cvListModal.show();
      this.activePage ? this.activePage : this.activePage = 0;
      this.getAll();
    },
    chancePage(item) {
      this.activePage = item;
      this.getAll();
    },
    closeSide(){
      clearInterval(this.intervalId);
    },
    getAll() {
      this.$nextTick(() => {
        const referance = this.buttonList[this.activePage].referance;
        if (this.$refs[referance] && this.$refs[referance].getAll) {
          this.$refs[referance].getAll();
          this.intervalId = setInterval(() => {
            try {
              if (this.List.length > 0) {
                this.$refs[referance].getAll(false);
              } else {
                clearInterval(this.intervalId);
              }
            } catch (err) {
              clearInterval(this.intervalId);
            }
          }, 10000);
        }
      });
    },
  },
};
</script>
