<template>
  <div class="bgcol h-16 w-full flex justify-between px-6 items-center">
    <span class="text-white">34 : 55 : 02</span>
    <div class="flex gap-3 items-center h-full py-3">
      <div class="w-32 h-full">
        <soundBar />
      </div>
      <button
        class="border border-gray-300 px-3 rounded-lg bg-[#8f89bf] h-full"
      >
        <img src="@/assets/meeting/sound.svg" alt="" class="h-[20px]" />
      </button>
      <button
        class="border border-gray-300 px-2.5 rounded-lg bg-[#8f89bf] h-full"
      >
        <img src="@/assets/meeting/live.svg" alt="" class="mt-1.5 w-7" />
      </button>
      <button
        class="border border-gray-300 px-2.5 h-full rounded-lg bg-[#8f89bf]"
      >
        <img src="@/assets/meeting/meetingView.svg" alt="" class="w-[21px]" />
      </button>
      <button
        class="border border-gray-300 px-2.5 rounded-lg bg-red-500 h-full"
      >
        <img src="@/assets/meeting/record.svg" alt="" class="mt-1.5 w-[26px]" />
      </button>
    </div>
    <router-link
      to="/meeting/connect"
      class="px-5 py-2 bg-red-500 text-white rounded-md text-sm border border-gray-400"
    >
      Görüşmeyi Bitir
    </router-link>
  </div>
</template>
<script>
import soundBar from "./soundBar";

export default {
  name: "footerMeeting",
  components: {
    soundBar,
  },
};
</script>
