<template>
  <form @submit.prevent="generate()">
    <div class="space-y-4 pb-3">
      <customInput
        v-model="questionCount"
        type="number"
        min="1"
        max="20"
        :required="true"
        :placeholder="$t('project.components.interview.components.generate.questionCount')"
        :title="$t('project.components.interview.components.generate.questionCount')"
      />

      <requiredField />

      <div class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0">
        <div class="w-full">
          <buttonItem
            :load="load"
            :loadText="$t('project.components.interview.components.generate.questionsAreBeingPrepared')"
            :text="$t('project.components.interview.components.generate.suggestQuestion')"
            buttonClass="w-full"
          />
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import { projectInterview } from "@/networking/urlmanager";
import requiredField from "@/components/requiredField.vue";

export default {
  name: "generate-question",
  props: ["Detail"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,
      explanation: "",
      questionCount: 0,
    };
  },
  methods: {
    generate() {
      this.load = true;
      this.axios.post(projectInterview.generateQuestion, {
          projectId: this.$route.query.id,
          questionCount: this.questionCount,
          explanation: this.explanation,
          projectName: this.$route.query.projectName,
        })
        .then((res) => {
          this.$emit("generateQuestion", res.data.data);
        })
        .catch((err) => {
          this.load = false;
          this.authController(err);
        });
    },
  },
};
</script>
