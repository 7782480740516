<template>
  <div
    class="flex max-md:flex-col-reverse"
    @dragover.prevent="dragover"
    @dragleave.prevent="dragleave"
    @drop.prevent="drop"
  >
    <div class="mt-10 w-[70%] max-md:w-full">
      <div class="h-7 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4">
        <router-link to="/support">
          <h2 class="text-xl textcol font-semibold">
            <i class="fa-sharp fa-solid fa-arrow-left"></i>{{ $t("support.detail.index.title") }}
          </h2>
        </router-link>
      </div>

      <div
        class="mt-5 overflow-y-auto px-5 mx-7 scroltype max-lg:px-3 max-lg:mx-5 max-md:px-2 max-md:mx-3 max-sm:px-2 max-sm:mx-2"
        style="height: calc(100vh - 285px)"
      >
        <div v-if="!load && messageList.length > 0">
          <div v-for="(item, index) in messageList" :key="index">
            <div v-if="item.writerType == 2" class="flex justify-start mb-5">
              <img
                src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
                class="object-cover h-8 w-8 rounded-full"
                alt=""
              />
              <div>
                <div
                  class="ml-2 py-1.5 px-5 inline-block bgcol rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white float-left"
                >
                  <h1 class="border-b border-gray-400 py-1 text-sm">
                    {{ item.fullname }}
                  </h1>
                  <p v-if="item.message" class="text-sm font-light py-1 pt-2">
                    {{ item.message }}
                  </p>
                </div>
                <div v-if="item.files" class="w-full mt-3 flex pl-2">
                  <div class="grid grid-cols-5 gap-3">
                    <div
                      v-for="(img, index) in item.files"
                      :key="index"
                      class="w-full"
                    >
                      <div class="flex">
                        <a :href="img" v-if="img" download class="mt-3">
                          <img :src="img" class="" />
                        </a>
                      </div>
                      <button
                        @click="downloadFile(img)"
                        v-if="img"
                        class="w-full"
                      >
                        <img
                          src="@/assets/img/downloading.png"
                          alt=""
                          class="w-4 mx-auto my-3"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <p class="text-xs text-right mt-1 pr-2">
                  {{ item.addDate | getTime }}
                </p>
              </div>
            </div>

            <div v-if="item.writerType == 1" class="flex justify-end mb-5">
              <div>
                <div
                  class="mr-2 py-1.5 px-5 inline-block bg-[#878DA5] rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white float-right"
                >
                  <h1 class="border-b border-gray-400 py-1 text-sm">
                    {{ item.fullname }}
                  </h1>
                  <p v-if="item.message" class="text-sm font-light py-1 pt-2">
                    {{ item.message }}
                  </p>
                </div>
                <div
                  v-if="item.files"
                  class="w-full mt-3 flex justify-end pr-2"
                >
                  <div class="grid grid-cols-5 gap-3" style="direction: rtl">
                    <div
                      v-for="(img, index) in item.files"
                      :key="index"
                      class="w-full"
                    >
                      <div class="flex justify-end">
                        <a :href="img" v-if="img" download class="mt-3">
                          <img :src="img" class="" />
                        </a>
                      </div>
                      <button
                        @click="downloadFile(img)"
                        v-if="img"
                        class="w-full"
                      >
                        <img
                          src="@/assets/img/downloading.png"
                          alt=""
                          class="w-4 mx-auto my-3"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <p class="text-xs text-right mt-1 pr-2">
                  {{ item.addDate | getTime }}
                </p>
              </div>
              <img
                src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
                class="object-cover h-8 w-8 rounded-full"
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          v-if="load"
          class="flex h-full w-full items-center justify-center gap-2"
        >
          <i class="fas fa-spinner fa-spin"></i>
          <p>{{ $t("support.detail.index.loadText") }}</p>
        </div>
        <div
          v-if="!load && messageList.length == 0"
          class="flex h-full w-full items-center justify-center gap-2"
        >
          <p>{{ $t("support.detail.index.dataNotFound") }}</p>
        </div>
      </div>

      <div
        style="height: 100px"
        class="flex items-center w-full px-12 py-4 pb-5 max-lg:px-8 max-md:px-5 max-sm:px-4"
      >
        <div class="w-full h-full border-t border-gray-200 pt-4">
          <form
            @submit.prevent="save()"
            class="w-full h-full flex border bordercol rounded-xl items-center"
          >
            <label
              for="fileInput"
              class="w-14 cursor-pointer flex px-3 border-r border-gray-500"
            >
              <img
                src="@/assets/img/folderBlue.png"
                data-v-step="0"
                alt=""
                class="h-6"
              />
              <sup class="text-xs textcol">{{
                files.length > 0 ? files.length : " "
              }}</sup>
            </label>
            <input
              data-v-step="1"
              v-model="message"
              :disabled="List.stateId == disabled"
              :class="{}"
              class="w-full h-full block px-3 rounded-xl bg-transparent placeholder:text-[#1824515a] textcol outline-none text-sm"
              type="text"
              :placeholder="
                List.stateId == disabled
                  ?  $t('support.detail.index.supportRequestClosed')
                  :  $t('support.detail.index.enterMessageOrPhoto')
              "
            />
            <button class="px-3" :disabled="List.stateId == disabled">
              <img
                v-if="!load"
                src="@/assets/img/send-messageBlue.png"
                alt=""
                class="h-5"
              />
              <i v-if="load" class="fas fa-spinner fa-spin text-lg"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="w-[30%] max-md:w-full">
      <supportBar
        :subject="List.subject"
        :date="List.addDate"
        :stateId="List.stateId"
        :categoryName="List.categoryName"
        :load="load"
        @refresh="getAll()"
      />

      <input
        type="file"
        multiple
        name="file"
        id="fileInput"
        class="hidden"
        @change="onChange"
        ref="file"
        :disabled="List.stateId == disabled"
        accept=".jpg,.jpeg,.png"
      />
    </div>
    <tour
      :steps="steps"
      :moduleId="10"
      v-if="!$store.state.userData.tourModuleId.includes(10)"
    />
  </div>
</template>

<script>
import supportBar from "./components/bar.vue";

import { support } from "@/networking/urlmanager";

import moment from "moment";
import axios from "axios";
import "moment/locale/tr";
import tour from "@/components/tour.vue";
export default {
  name: "support-detail",
  components: {
    supportBar,
    tour,
  },
  data() {
    return {
      isDragging: false,
      files: [],
      List: [],
      message: "",
      messageList: [],
      load: false,
      saveLoad: false,
      disabled: 3,
      steps: [
        {
          target: '[data-v-step="0"]',
          header: {
            title: this.$t('support.detail.index.file'),
          },
          content:
          this.$t('support.detail.index.fileMessage'),
          params: {
            placement: "bottom",
          },
        },
        {
          target: '[data-v-step="1"]',
          header: {
            title:   this.$t('support.detail.index.message'),
          },
          content: this.$t('support.detail.index.mesajPlacholder'),
          params: {
            placement: "bottom",
          },
        },
      ],
    };
  },
  methods: {
    getAll() {
      this.load = true;
      this.List = [];
      this.axios
        .get(support.getDetail + "?rowId=" + this.$route.query.id)
        .then((res) => {
          this.messageList = res.data.data[1];
          this.List = res.data.data[0];
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    save() {
      if (
        this.message ||
        (this.files.length > 0 && this.List.stateId != this.disabled)
      ) {
        this.saveLoad = true;
        const formData = new FormData();
        this.files.forEach((r) => {
          formData.append("file", r);
        });
        formData.append("rowId", this.$route.query.id);
        formData.append("message", this.message);
        formData.append(
          "fullname",
          this.$store.state.userData.firstName +
            " " +
            this.$store.state.userData.lastName
        );
        formData.append("email", this.$store.state.userData.email);
        axios
          .post(support.sendMessage, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          })
          .then((res) => {
            this.files = [];
            this.message = "";
            this.saveLoad = false;
            this.getAll();
          })
          .catch((err) => {
            this.saveLoad = false;
            console.log(err);
            this.authController(err);
          });
      }
    },
    async downloadFile(val) {
      try {
        const response = await axios.get(val, {
          responseType: "blob",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");

        const filename = val.split("/").pop().split("?")[0];
        const extension = filename.split(".").pop();

        link.href = url;
        link.setAttribute("download", `${filename}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      }
    },
    onChange() {
      if (this.disabled != this.List.stateId) {
        const selectedFiles = this.$refs.file.files;
        const allFilesAreValid = Array.from(selectedFiles).every((file) => {
          const fileExtension = file.name.split(".").pop().toLowerCase();
          return ["jpg", "jpeg", "png", "gif"].includes(fileExtension);
        });

        if (allFilesAreValid) {
          this.files = [...selectedFiles];
          this.uploadFiles();
        } else {
          this.warningMesage(this.$t("support.detail.index.warningMesage"))
        }
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
  },
  filters: {
    getTime(val) {
      if (val) return moment(val).format("HH:mm");
      else return this.$t("support.detail.index.noDate");
    },
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return  this.$t("support.detail.index.noDate");
    },
  },
  created() {
    this.getAll();
  },
};
</script>
